@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');

:root {
  --bodyFonts: "Roboto Flex", sans-serif;
  --mainColor: #0a405d;
  --redColor: #EC272F;
  --titleColor: #131841;
  --bodyColor: #555555;
  --whiteColor: #ffffff;
  --fontSize: 16px;
  --transition: 0.5s;
}

body {
  padding: 0;
  margin: 0;
  line-height: 1.6;
  color: var(--bodyColor);
  font-weight: 400;
  font-size: var(--fontSize);
  font-family: var(--bodyFonts);
}

html {
  scroll-behavior: smooth;
}

p {
  color: var(--bodyColor);
  margin-bottom: 10px;
}

p:last-child {
  margin-bottom: 0;
}

a {
  display: inline-block;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-decoration: none;
  color: var(--mainColor);
}

a:hover,
a:focus {
  text-decoration: none;
}

button {
  outline: 0;
  border: none;
}

button:focus {
  outline: 0;
  border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  color: var(--titleColor);
  line-height: 1.4;
  font-family: var(--headerFonts);
}

h3 {
  font-size: 22px;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  display: table-cell;
  vertical-align: middle;
}

img {
  max-width: 100%;
}

.pt-150 {
  padding-top: 150px;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.ptb-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-20 {
  padding-top: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.pb-10 {
  padding-bottom: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-45 {
  margin-bottom: 45px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.border-radius-5 {
  border-radius: 5px !important;
}

.border-radius-50 {
  border-radius: 50px !important;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.border-radius-0 img {
  border-radius: 0 !important;
}

.box-shadow {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
}

.default-btn {
  padding: 11px 30px;
  text-align: center;
  color: var(--whiteColor);
  font-size: var(--fontSize);
  font-weight: 400;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  background: var(--mainColor);
  -webkit-box-shadow: none;
  box-shadow: none;
  overflow: hidden;
  white-space: nowrap;
}
.default-btn1 {
  padding: 11px 30px;
  text-align: center;
  color:black;
  font-size: var(--fontSize);
  font-weight: 400;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  background: rgb(240, 236, 236);
  -webkit-box-shadow: none;
  border-radius: 10px;
  box-shadow: none;
  overflow: hidden;
  white-space: nowrap;
}

.default-btn i {
  position: relative;
  top: 3px;
  margin-left: 10px;
}

.default-btn:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 550px;
  height: 550px;
  margin: auto;
  background: var(--titleColor);
  border-radius: 50%;
  z-index: -1;
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: translateX(-50%) translateY(-5%) scale(0.4);
  transform: translateX(-50%) translateY(-5%) scale(0.4);
  -webkit-transition: -webkit-transform .9s;
  transition: -webkit-transform .9s;
  transition: transform .9s;
  transition: transform .9s, -webkit-transform .9s;
}

.default-btn.two {
  background: var(--mainColor);
  color: var(--whiteColor);
}

.default-btn.two::before {
  background-color: var(--mainColor);
}

.default-btn:hover {
  color: var(--whiteColor) !important;
}

.default-btn:hover:before {
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
  -webkit-transform: translateX(-50%) translateY(0) scale(1);
  transform: translateX(-50%) translateY(0) scale(1);
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
}

.section-title {
  position: relative;
  z-index: 1;
}

.section-title span {
  color: var(--mainColor);
  display: block;
  margin-bottom: 7px;
}

.section-title h2 {
  font-size: 35px;
  margin-top: 0;
  line-height: 1.2;
  margin-bottom: 0;
}

.section-title h2 b {
  color: var(--mainColor);
  font-weight: 700;
}

.section-title p {
  padding-top: 15px;
  margin-bottom: 0;
}

.section-title.text-center h2 {
  margin: 0 auto;
}

.section-title.text-center p {
  margin: 0 auto;
  max-width: 640px;
}

.section-bg {
  background-color: #F5F5F5;
}

.top-header {
  padding: 15px 0;
  position: relative;
  z-index: 5;
  background-color: var(--titleColor);
}

.header-left {
  float: left;
}

.header-left ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.header-left ul li {
  display: inline-block;
  padding-left: 30px;
  position: relative;
  color: var(--whiteColor);
  margin-right: 35px;
  font-size: 15px;
}

.header-left ul li:last-child {
  margin-right: 0;
}

.header-left ul li i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  font-size: 20px;
  color: var(--whiteColor);
}

.header-left ul li a {
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.header-left ul li a:hover {
  color: var(--mainColor);
}

.header-right {
  float: right;
}

.header-right .header-language {
  display: inline-block;
  margin-right: 20px;
  position: relative;
  top: -3px;
}

.header-right .header-language .dropdown-toggle {
  background-color: transparent;
  color: var(--whiteColor);
  font-size: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  outline: 0;
  border: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.header-right .header-language .dropdown-toggle:after {
  content: "\ea4e";
  font-family: 'remixicon' !important;
  border: 0;
  margin-left: 8px;
  font-size: 20px;
  color: var(--yellowColor);
}

.header-right .header-language .dropdown-menu {
  margin: 0;
  padding: 0;
  -webkit-box-shadow: 0px 9px 54px 0px rgba(32, 32, 32, 0.1);
  box-shadow: 0px 9px 54px 0px rgba(32, 32, 32, 0.1);
  left: auto;
  right: 0;
  border: 0;
  border-radius: 0;
  -webkit-transform: translateY(100px) !important;
  transform: translateY(100px) !important;
  display: block;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.header-right .header-language .dropdown-menu.show {
  -webkit-transform: translateY(40px) !important;
  transform: translateY(40px) !important;
  opacity: 1;
  pointer-events: all;
}

.header-right .header-language .dropdown-menu a {
  padding: 5px 25px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  font-size: 15px;
}

.header-right .header-language .dropdown-menu a:active {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.header-right .header-language .dropdown-menu a:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.header-right .header-language .dropdown-menu a img {
  width: 20px;
  margin-right: 10px;
}

.header-right .header-language .dropdown-menu[style] {
  right: 0 !important;
  left: auto !important;
}

.header-right .social-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.header-right .social-list li {
  display: inline-block;
  margin-right: 10px;
}

.header-right .social-list li:last-child {
  margin-right: 0;
}

.header-right .social-list li a {
  color: var(--whiteColor);
  font-size: var(--fontSize);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.header-right .social-list li a:hover {
  color: var(--mainColor);
}


.desktop-nav {
  padding-top: 0;
  padding-bottom: 0;
}

.desktop-nav .navbar {
  background-color: var(--whiteColor);
  padding-right: 0;
  padding-top: 10px;
  padding-left: 0;
  padding-bottom: 10px;
}

.desktop-nav .navbar .navbar-brand .logo-two {
  display: none;
}

.desktop-nav .navbar ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.desktop-nav .navbar .navbar-nav {
  margin-left: auto;
  margin-right: 40px;
}

.desktop-nav .navbar .navbar-nav .nav-item {
  position: relative;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-right: 0;
  padding-left: 0;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-toggle {
  padding-right: 17px;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-toggle::before {
  position: absolute;
  content: "\ea4e";
  line-height: 1;
  right: -2px;
  top: 6px;
  font-family: 'remixicon' !important;
  font-size: 17px;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-toggle::after {
  display: none;
}

.navbar-nav .nav-item {
  font-size: 16px !important;
  color: var(--whiteColor);
  position: relative;
  z-index: 1;
  font-weight: 500;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 15px;
  margin-right: 10px;
}

.navbar-area.ledu-area.is-sticky .desktop-nav .navbar .navbar-nav .nav-item a {
  color: var(--titleColor);
}

.navbar-area.ledu-area.is-sticky .desktop-nav .navbar .navbar-nav .nav-item a:hover,
.navbar-area.ledu-area.is-sticky .desktop-nav .navbar .navbar-nav .nav-item a:focus,
.navbar-area.ledu-area.is-sticky .desktop-nav .navbar .navbar-nav .nav-item a.active {
  color: var(--mainColor);
}

.desktop-nav .navbar .navbar-nav .nav-item:last-child a {
  margin-right: 0;
}

.navbar-area.ledu-area.is-sticky .navbar-brand img {
  height: 80px;
}

.desktop-nav .navbar .navbar-nav .nav-item:first-child a {
  margin-left: 0;
}

.desktop-nav .navbar .navbar-nav .nav-item:hover a,
.desktop-nav .navbar .navbar-nav .nav-item:focus a,
.desktop-nav .navbar .navbar-nav .nav-item.active a {
  color: var(--mainColor);

}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: var(--whiteColor);
  border: none;
  position: absolute;
  visibility: hidden;
  -webkit-transform-origin: top;
  transform-origin: top;
  border-radius: 0;
  display: block;
  width: 250px;
  z-index: 99;
  opacity: 0;
  top: 80px;
  left: 0;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  padding-top: 2px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 0;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li {
  padding: 0;
  border-bottom: 1px solid #f0eeee;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li:last-child {
  border: none;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  color: var(--titleColor);
  padding: 12px 20px 9px;
  margin: 0;
  font-size: 15px;
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: var(--mainColor);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.dropdown-toggle {
  padding-right: 17px;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.dropdown-toggle::before {
  position: absolute;
  content: "\ea4e";
  line-height: 1;
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: 'remixicon' !important;
  font-size: 17px;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.dropdown-toggle::after {
  display: none;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  left: -245px;
  top: 0;
  opacity: 0;
  visibility: hidden;
  margin-top: 11px;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: var(--titleColor);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: var(--mainColor);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -245px;
  right: auto;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--titleColor);
  font-size: 15px;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--mainColor);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--titleColor);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--mainColor);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--titleColor);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--mainColor);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--titleColor);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--mainColor);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--titleColor);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--mainColor);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--mainColor);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--mainColor);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--mainColor);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  top: -15px;
  visibility: visible;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--mainColor);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  top: -15px;
  visibility: visible;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: var(--mainColor);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: var(--mainColor);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}

.desktop-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
  top: 100%;
  opacity: 1;
  margin-top: 0;
  visibility: visible;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}



.ledu-area .navbar {
  background-color: transparent;
}

.navbar-area.is-sticky {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  position: fixed;
  background-color: var(--whiteColor) !important;
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
}

.nav-widget-form {
  margin-left: 10px;
}

.nav-widget-form .search-form {
  position: relative;
  margin: 0 auto;
  border-radius: 0;
  border: 1px solid #F5F5F5;
  width: 260px;
}

.nav-widget-form .search-form .form-control {
  background-color: #f5f5f5;
  border-radius: 0;
  border: none;
  height: 50px;
  line-height: 50px;
  font-size: 17px;
  margin: 0;
  border: none;
  padding: 0 25px;
  color: var(--bodyColor);
}

.nav-widget-form .search-form .form-control::-webkit-input-placeholder,
.nav-widget-form .search-form .form-control:-ms-input-placeholder,
.nav-widget-form .search-form .form-control::-ms-input-placeholder,
.nav-widget-form .search-form .form-control::-webkit-input-placeholder {
  color: var(--bodyColor);
}

.nav-widget-form .search-form .form-control::-webkit-input-placeholder,
.nav-widget-form .search-form .form-control:-ms-input-placeholder,
.nav-widget-form .search-form .form-control::-ms-input-placeholder,
.nav-widget-form .search-form .form-control::placeholder {
  color: var(--bodyColor);
}

.nav-widget-form .search-form .form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.nav-widget-form .search-form button {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  color: var(--bodyColor);
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  padding: 0 25px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 0 40px 40px 0px;
}

.nav-widget-form .search-form button:hover {
  background: var(--mainColor);
  color: var(--whiteColor);
}

.nav-widget-form .search-form-bg {
  border: none;
}

.nav-widget-form .search-form-bg .form-control {
  background-color: #F5F5F5;
  border-radius: 50px;
}

.nav-widget-form .search-form-bg button {
  border-radius: 0 50px 50px 0;
}

.nav-widget-form .search-form-bg2 {
  border: none;
}

.nav-widget-form .search-form-bg2 .form-control {
  background-color: #F5F5F5;
  border-radius: 5px;
}

.nav-widget-form .search-form-bg2 button {
  border-radius: 0 5px 5px 0;
}

.navbar-category {
  margin-left: 15px;
}

.navbar-category .category-list-dropdown {
  display: inline-block;
}

.navbar-category .category-list-dropdown .btn {
  font-weight: 500;
  font-size: 17px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: var(--bodyColor);
}

.navbar-category .category-list-dropdown .btn i {
  font-size: 19px;
  position: relative;
  top: 4px;
  display: inline-block;
  margin-right: 10px;
}

.navbar-category .category-list-dropdown .btn:hover,
.navbar-category .category-list-dropdown .btn:focus,
.navbar-category .category-list-dropdown .btn.active {
  color: var(--mainColor);
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.navbar-category .category-list-dropdown .dropdown-menu {
  border: none;
  top: 170%;
  left: 0;
  z-index: 99;
  opacity: 0;
  width: 260px;
  display: block;
  border-radius: 0;
  padding: 0;
  margin-top: 0;
  position: absolute;
  visibility: hidden;
  background: var(--whiteColor);
  -webkit-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.navbar-category .category-list-dropdown .dropdown-menu a {
  padding: 9px 20px 14px;
  display: block;
  color: var(--titleColor);
  font-size: 14px;
  font-weight: 600;
  background-color: transparent;
  border-bottom: 1px solid #e1e1e1;
  position: relative;
  border-radius: 0;
  -webkit-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}

.navbar-category .category-list-dropdown .dropdown-menu a i {
  font-size: 18px;
  margin-right: 10px;
  position: relative;
  top: 5px;
}

.navbar-category .category-list-dropdown .dropdown-menu a:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.navbar-category .category-list-dropdown .dropdown-menu a:last-child {
  border-bottom: 0;
}

.navbar-category .category-list-dropdown .dropdown-menu.show {
  opacity: 1;
  visibility: visible;
  top: 120%;
}

.navbar-category .category-list-dropdown .dropdown-toggle::after {
  display: none;
}

.mobile-responsive-nav {
  display: none;
}

.side-nav-responsive {
  display: none;
}

.side-nav-responsive .dot-menu {
  padding: 0 10px;
  height: 30px;
  cursor: pointer;
  z-index: 999;
  position: absolute;
  right: 60px;
  top: -40px;
}

.side-nav-responsive .dot-menu .circle-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 30px;
}

.side-nav-responsive .dot-menu .circle-inner .circle {
  height: 5px;
  width: 5px;
  border-radius: 100%;
  margin: 0 2px;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  background-color: var(--mainColor);
}

.side-nav-responsive .dot-menu:hover .circle-inner .circle {
  background-color: var(--redColor);
}

.side-nav-responsive .container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.side-nav-responsive .container .container {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 300px;
  margin-left: auto;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  z-index: 2;
  padding-left: 15px;
  padding-right: 15px;
}

.side-nav-responsive .container .container.active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.side-nav-responsive .side-nav-inner {
  padding: 10px;
  -webkit-box-shadow: 0 15px 40px rgba(0, 0, 0, 0.09);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.09);
  background-color: var(--whiteColor);
}

.side-nav-responsive .side-nav-inner .side-nav {
  background-color: var(--mainColor);
  padding: 20px 15px 10px;
}

.side-nav-responsive .side-nav-inner .side-nav .side-item {
  position: relative;
  display: block;
  margin-bottom: 10px;
  text-align: center;
}

.side-nav-responsive .side-nav-inner .side-nav .side-item:last-child {
  margin-bottom: 10px;
}

.side-nav-responsive .side-nav-inner .side-nav .side-item .default-btn {
  text-align: center;
}

.side-nav-responsive .side-nav-inner .side-nav .side-item .search-form {
  position: relative;
  margin: 0 auto;
  border-radius: 0;
  border: 1px solid #F5F5F5;
  width: 230px;
}

.side-nav-responsive .side-nav-inner .side-nav .side-item .search-form .form-control {
  background-color: var(--whiteColor);
  border-radius: 0;
  border: none;
  height: 45px;
  line-height: 45px;
  font-size: 15px;
  margin: 0;
  border: none;
  padding: 0 50px 0 15px;
  color: var(--bodyColor);
}

.side-nav-responsive .side-nav-inner .side-nav .side-item .search-form .form-control::-webkit-input-placeholder,
.side-nav-responsive .side-nav-inner .side-nav .side-item .search-form .form-control:-ms-input-placeholder,
.side-nav-responsive .side-nav-inner .side-nav .side-item .search-form .form-control::-ms-input-placeholder,
.side-nav-responsive .side-nav-inner .side-nav .side-item .search-form .form-control::-webkit-input-placeholder {
  color: var(--bodyColor);
}

.side-nav-responsive .side-nav-inner .side-nav .side-item .search-form .form-control::-webkit-input-placeholder,
.side-nav-responsive .side-nav-inner .side-nav .side-item .search-form .form-control:-ms-input-placeholder,
.side-nav-responsive .side-nav-inner .side-nav .side-item .search-form .form-control::-ms-input-placeholder,
.side-nav-responsive .side-nav-inner .side-nav .side-item .search-form .form-control::placeholder {
  color: var(--bodyColor);
}

.side-nav-responsive .side-nav-inner .side-nav .side-item .search-form .form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.side-nav-responsive .side-nav-inner .side-nav .side-item .search-form button {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  border-radius: 0;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  height: 45px;
  line-height: 45px;
  font-size: 20px;
  padding: 0 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.side-nav-responsive .side-nav-inner .side-nav .side-item .search-form button:hover {
  background: var(--redColor);
  color: var(--whiteColor);
}


.banner-area {
  background-color: #F5F5F5;
  z-index: 1;
  position: relative;
}

.banner-area-ptb {
  padding-top: 140px;
  padding-bottom: 190px;
}

.banner-content span {
  font-size: 16px;
  color: var(--mainColor);
  display: block;
  margin-bottom: 15px;
}

.banner-content h1 {
  font-size: 60px;
  color: var(--titleColor);
  margin-bottom: 20px;
  line-height: 1.2;
}

.banner-content p {
  margin-bottom: 30px;
  max-width: 700px;
}

.banner-content .banner-form-area {
  margin-bottom: 30px;
}

.banner-content .banner-form-area .banner-form {
  position: relative;
  width: 650px;
}

.banner-content .banner-form-area .banner-form .form-control {
  background: var(--whiteColor);
  height: 55px;
  line-height: 55px;
  margin: 0;
  border-radius: 0;
  border: none;
  padding: 0 25px;
  max-width: 100%;
  color: var(--bodyColor);
  width: 600px;
}

.banner-content .banner-form-area .banner-form .form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.banner-content .banner-form-area .banner-form .default-btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 55px;
  border: 0;
}

.banner-content .banner-form-area .banner-form .default-btn i {
  position: relative;
  top: 2px;
  margin-right: 10px;
}

.banner-content .banner-popular-tag {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.banner-content .banner-popular-tag li {
  display: inline-block;
}

.banner-content .banner-popular-tag li.title {
  color: var(--titleColor);
  font-weight: 600;
}

.banner-content .banner-popular-tag li a {
  color: var(--bodyColor);
  text-decoration: underline;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.banner-content .banner-popular-tag li a:hover {
  color: var(--mainColor);
  letter-spacing: 0.25px;
}

.banner-content .banner-counter h3 {
  color: var(--titleColor);
  font-size: 25px;
  margin-bottom: 0;
  font-weight: 600;
  display: inline-block;
}

.banner-content .banner-counter h3 .odometer {
  font-family: var(--bodyFonts);
  font-weight: 500;
}

.banner-content .banner-counter p {
  margin-bottom: 0;
  color: var(--bodyColor);
}

.banner-img {
  margin-left: 70px;
  position: relative;
  z-index: 1;
}

.banner-img .bg-shape {
  position: absolute;
  z-index: -1;
  top: 160px;
  left: -120px;
  right: 0;
  text-align: center;
}

.banner-img .bg-shape img {
  max-width: 550px;
}

.banner-img .top-content {
  position: absolute;
  top: 140px;
  left: -20px;
  padding: 15px;
  background-color: var(--whiteColor);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.banner-img .top-content i {
  width: 45px;
  height: 45px;
  line-height: 50px;
  font-size: 20px;
  color: var(--whiteColor);
  background-color: var(--titleColor);
  margin-right: 12px;
  text-align: center;
}

.banner-img .top-content .content h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
}

.banner-img .top-content .content p {
  font-size: 14px;
  margin-bottom: 0;
}

.banner-img .right-content {
  position: absolute;
  top: 60px;
  right: 0;
  padding: 15px;
  background-color: var(--whiteColor);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.banner-img .right-content i {
  width: 45px;
  height: 45px;
  line-height: 50px;
  font-size: 20px;
  color: var(--whiteColor);
  background-color: var(--titleColor);
  margin-right: 12px;
  text-align: center;
}

.banner-img .right-content .content h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
}

.banner-img .right-content .content p {
  font-size: 14px;
  margin-bottom: 0;
}

.banner-img .left-content {
  position: absolute;
  bottom: 40px;
  left: -20px;
  padding: 15px 15px 15px 65px;
  background-color: var(--whiteColor);
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.banner-img .left-content img {
  position: absolute;
  top: 15px;
  left: 15px;
  border-radius: 50%;
}

.banner-img .left-content .content {
  margin-bottom: 7px;
}

.banner-img .left-content .content h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
}

.banner-img .left-content .content p {
  font-size: 14px;
  margin-bottom: 0;
}

.banner-img .left-content .join-btn {
  padding: 7px 40px;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  font-size: 13px;
  -webkit-transition: 0.9s;
  transition: 0.9s;
}

.banner-img .left-content .join-btn:hover {
  background-color: var(--titleColor);
}

.banner-img .banner-img-shape .shape1 {
  position: absolute;
  top: 30px;
  left: -100px;
  z-index: -1;
  -webkit-animation: moveleftbounce 9s linear infinite;
  animation: moveleftbounce 9s linear infinite;
}

.banner-img .banner-img-shape .shape2 {
  position: absolute;
  top: 30px;
  right: 0;
  z-index: -1;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.banner-img-two {
  position: relative;
  z-index: 1;
  float: right;
  margin-right: 70px;
}

.banner-img-two img {
  max-width: 550px;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.banner-img-two .bg-shape {
  position: absolute;
  z-index: -1;
  top: 25px;
  left: 0;
  right: 0;
  text-align: center;
}

.banner-img-two .bg-shape img {
  max-width: 620px;
}

.banner-shape .banner-shape1 {
  position: absolute;
  top: 30px;
  left: 0;
  z-index: -1;
  -webkit-animation: moveleftbounce 9s linear infinite;
  animation: moveleftbounce 9s linear infinite;
}

.hero-slider-area {
  padding: 70px 0;
  position: relative;
  overflow: hidden;
  height: 560px;
}

.hero-slider-area .hero-shape .shape1 {
  position: absolute;
  top: 70px;
  right: 20px;
  -webkit-animation: rotated360 9s linear infinite;
  animation: rotated360 9s linear infinite;
}

.hero-slider-area .hero-shape .shape2 {
  position: absolute;
  bottom: 70px;
  left: 40%;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.hero-slider {
  position: relative;
  overflow: hidden;
}

.hero-slider .owl-nav {
  margin-top: 0;
  overflow: hidden;
}

.hero-slider .owl-nav .owl-prev {
  position: absolute;
  z-index: 5;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -100px;
  opacity: 0;
  font-size: 18px !important;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  border-radius: 50px !important;
  background-color: transparent !important;
  color: var(--titleColor) !important;
  border: 1px solid var(--titleColor) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.hero-slider .owl-nav .owl-prev i {
  position: relative;
  top: 3px;
}

.hero-slider .owl-nav .owl-prev:hover {
  color: var(--mainColor) !important;
  border-color: var(--mainColor) !important;
}

.hero-slider .owl-nav .owl-next {
  position: absolute;
  z-index: 5;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -100px;
  opacity: 0;
  font-size: 18px !important;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  border-radius: 50px !important;
  background-color: transparent !important;
  color: var(--titleColor) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  border: 1px solid var(--titleColor) !important;
}

.hero-slider .owl-nav .owl-next i {
  position: relative;
  top: 3px;
}

.hero-slider .owl-nav .owl-next:hover {
  color: var(--mainColor) !important;
  border-color: var(--mainColor) !important;
}

.hero-slider:hover .owl-nav .owl-prev {
  left: 0;
  opacity: 1;
}

.hero-slider:hover .owl-nav .owl-next {
  right: 0;
  opacity: 1;
}

.hero-content {
  max-width: 100%;
  padding: 60px 0;
}

.hero-content .top-title {
  font-size: 16px;
  color: var(--whiteColor);
  display: block;
  margin-bottom: 15px;
}

.hero-content h1 {
  font-size: 48px;
  color: var(--whiteColor);
  margin-bottom: 20px;
  line-height: 1.2;
}

.hero-content p {
  margin-bottom: 30px;
  max-width: 100%;
  font-size: 20px;
  color: var(--whiteColor);
}

.hero-content .banner-btn {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
}

.hero-content .banner-btn .default-btn {
  margin-right: 20px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.hero-content .banner-btn .play-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--whiteColor);
}

.hero-content .banner-btn .play-btn i {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: var(--whiteColor);
  color: var(--mainColor);
  font-size: 18px;
  border-radius: 50px;
  margin: 0 auto;
  padding-left: 5px;
  padding-top: 3px;
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-align: center;
}

.hero-content .banner-btn .play-btn i::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 1px solid var(--whiteColor);
  border-radius: 50px;
  -webkit-animation: ripple 2s infinite ease-in-out;
  animation: ripple 2s infinite ease-in-out;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.hero-content .banner-btn .play-btn .title-text {
  color: var(--bodyColor);
  margin-left: 15px;
  margin-bottom: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-bottom: 1px solid var(--bodyColor);
}

.hero-content .banner-btn .play-btn:hover i {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.hero-content .banner-btn .play-btn:hover i::after {
  border-color: var(--mainColor);
}

.hero-content .banner-btn .play-btn:hover .title-text {
  color: var(--mainColor);
  border-color: var(--mainColor);
}

.hero-img {
  position: relative;
  z-index: 1;
}

.hero-img img {
  max-width: 550px;
}

.hero-img .hero-bg-shape {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.hero-img .hero-bg-shape img {
  max-width: 582px;
}

.hero-img .hero-bg-shape .bg-img-shape2 {
  display: none !important;
}

.hero-img .hero-img-content {
  padding: 15px;
  background-color: var(--whiteColor);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 10px;
}

.hero-img .hero-img-content i {
  width: 45px;
  height: 45px;
  line-height: 50px;
  font-size: 20px;
  color: var(--whiteColor);
  background-color: var(--titleColor);
  margin-right: 12px;
  text-align: center;
  border-radius: 50px;
}

.hero-img .hero-img-content .content h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
}

.hero-img .hero-img-content .content p {
  font-size: 14px;
  margin-bottom: 0;
}

.hero-img .top-content {
  position: absolute;
  top: 140px;
  left: -20px;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.hero-img .right-content {
  position: absolute;
  bottom: 200px;
  right: 0;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.owl-item.active .hero-content .top-title {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.owl-item.active .hero-content h1 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.owl-item.active .hero-content p {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.owl-item.active .hero-content .banner-btn {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.owl-item.active .hero-img .hero {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.owl-item.active .hero-img .hero-bg-shape {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.owl-item.active .hero-img .top-content .hero-img-content {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.owl-item.active .hero-img .right-content .hero-img-content {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}


.inner-banner {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  height: 200px;
}

.inner-banner::before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #004766;
  opacity: 0.9;
}

.inner-banner .inner-title {
  padding-top: 30px;
  padding-bottom: 40px;
}

.p-3 {
  padding: 1rem !important;
}

.inner-banner .inner-title h3 {
  font-size: 36px;
  color: var(--whiteColor);
  line-height: 1.2;
  margin-bottom: 15px;
  max-width: 630px;
}

.inner-banner .inner-title.text-center h3 {
  margin-left: auto;
  margin-right: auto;
}

.inner-banner .inner-title .rating {
  color: var(--whiteColor);
  margin-bottom: 20px;
}

.inner-banner .inner-title .rating i {
  color: #FFC107;
  margin-right: 5px;
  line-height: 1;
  font-size: 18px;
  position: relative;
  top: 3px;
}

.inner-banner .inner-title .inner-banner-content {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 500px;
  margin-bottom: 20px;
}

.inner-banner .inner-title .inner-banner-content .user-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.inner-banner .inner-title .inner-banner-content .user-area img {
  border-radius: 50%;
  margin-right: 10px;
}

.inner-banner .inner-title .inner-banner-content .user-area h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 0;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  color: var(--whiteColor);
}

.inner-banner .inner-title .inner-banner-content .user-area:hover h3 {
  color: var(--mainColor);
}

.inner-banner .inner-title .inner-banner-content .course-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.inner-banner .inner-title .inner-banner-content .course-list li {
  display: inline-block;
  margin-right: 20px;
  color: var(--whiteColor);
  font-size: 16px;
}

.inner-banner .inner-title .inner-banner-content .course-list li::before {
  display: none;
}

.inner-banner .inner-title .inner-banner-content .course-list li:last-child {
  margin-right: 0;
}

.inner-banner .inner-title .inner-banner-content .course-list li i {
  color: var(--whiteColor);
  position: relative;
  top: 3px;
  margin-right: 5px;
}

.inner-banner .inner-title ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.inner-banner .inner-title ul li {
  font-size: 18px;
  color: var(--whiteColor);
  display: inline-block;
  position: relative;
  margin-right: 20px;
  font-weight: 400;
}

.inner-banner .inner-title ul li:last-child {
  margin-right: 0;
}

.inner-banner .inner-title ul li:last-child::before {
  display: none;
}

.inner-banner .inner-title ul li::before {
  content: '';
  position: absolute;
  top: 5px;
  right: -14px;
  -webkit-transform: rotate(25deg);
  transform: rotate(25deg);
  width: 1.5px;
  height: 20px;
  background-color: var(--whiteColor);
}

.inner-banner .inner-title ul li a {
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.inner-banner .inner-title ul li a:hover {
  color: var(--mainColor);
}

.inner-banner-bg {
  background-image: url(../images/inner-banner/inner-banner1.jpg);
}

.inner-banner-bg2 {
  background-image: url(../images/inner-banner/inner-banner2.jpg);
}

.inner-banner-bg3 {
  background-image: url(../images/inner-banner/inner-banner3.jpg);
}

.inner-banner-bg4 {
  background-image: url(../images/inner-banner/inner-banner4.jpg);
}

.inner-banner-bg5 {
  background-image: url(../images/inner-banner/inner-banner5.jpg);
}

.inner-banner-bg6 {
  background-image: url(../images/inner-banner/inner-banner6.jpg);
}

.inner-banner-bg7 {
  background-image: url(../images/inner-banner/inner-banner7.jpg);
}

.inner-banner-bg8 {
  background-image: url(../images/inner-banner/inner-banner8.jpg);
}

.inner-banner-bg9 {
  background-image: url(../images/inner-banner/inner-banner9.jpg);
}

.inner-banner-bg10 {
  background-image: url(../images/inner-banner/inner-banner10.jpg);
}

.inner-banner-bg11 {
  background-image: url(../images/inner-banner/inner-banner11.jpg);
}

.inner-banner-bg12 {
  background-image: url(../images/inner-banner/inner-banner12.jpg);
}


.counter-area {
  background-color: var(--titleColor);
}

.counter-content {
  padding-left: 90px;
  position: relative;
  margin-bottom: 30px;
}

.counter-content i {
  position: absolute;
  z-index: 1;
  top: 5px;
  left: 0;
  font-size: 55px;
  line-height: 1;
  color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.counter-content i::after {
  content: '';
  position: absolute;
  z-index: -1;
  bottom: -5px;
  right: -15px;
  width: 43px;
  height: 40px;
  border-radius: 30px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background: var(--mainColor);
  opacity: 0.7;
}

.counter-content h3 {
  color: var(--mainColor);
  font-size: 35px;
  margin-bottom: 0;
  font-weight: 700;
}

.counter-content h3 .odometer {
  font-family: var(--bodyFonts);
  font-weight: 700;
}

.counter-content p {
  margin-bottom: 0;
  color: var(--bodyColor);
}

.counter-content:hover i {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.counter-content:hover i::after {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.counter-area-two {
  position: relative;
  z-index: 1;
}

.counter-area-two::before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 50%;
  background-color: #F5F5F5;
}

.counter-max {
  background-color: var(--mainColor);
  padding: 100px 60px 100px 100px;
  border-radius: 10px;
}

.counter-max .counter-content i::after {
  background-color: var(--whiteColor);
  opacity: 0.5;
}

.counter-card {
  padding: 30px 30px 30px 120px;
  position: relative;
  background-color: var(--whiteColor);
  margin-bottom: 30px;
}

.counter-card i {
  position: absolute;
  z-index: 1;
  top: 30px;
  left: 30px;
  font-size: 55px;
  line-height: 1;
  color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.counter-card i::after {
  content: '';
  position: absolute;
  z-index: -1;
  bottom: -5px;
  right: -15px;
  width: 37px;
  height: 35px;
  border-radius: 30px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background: var(--mainColor);
  opacity: 0.5;
}

.counter-card h3 {
  font-size: 35px;
  margin-bottom: 0;
  font-weight: 500;
}

.counter-card h3 .odometer {
  font-family: var(--bodyFonts);
  font-weight: 500;
}

.counter-card p {
  margin-bottom: 0;
}

.counter-card:hover i {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.counter-card:hover i::after {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}


.featured-area-mt {
  position: relative;
  margin-top: -50px;
  z-index: 2;
}

.featured-card {
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  padding: 40px 30px;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

.featured-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 0;
  height: 100%;
  background-color: var(--mainColor);
  opacity: 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.featured-card a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.featured-card a i {
  width: 60px;
  height: 60px;
  line-height: 65px;
  border-radius: 50%;
  text-align: center;
  font-size: 22px;
  color: var(--mainColor);
  background: rgba(8, 169, 230, 0.1);
  margin-right: 15px;
  -webkit-transition: 0.9s;
  transition: 0.9s;
}

.featured-card a h3 {
  margin-bottom: 0;
  -webkit-transition: 0.9s;
  transition: 0.9s;
}

.featured-card:hover::before {
  opacity: 1;
  width: 100%;
}

.featured-card:hover a i {
  background-color: var(--whiteColor);
  color: var(--mainColor);
}

.featured-card:hover a h3 {
  color: var(--whiteColor);
}

.featured-item {
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  padding: 35px 35px 35px 110px;
  position: relative;
  border-radius: 5px;
  margin-bottom: 30px;
}

.featured-item i {
  position: absolute;
  top: 35px;
  left: 35px;
  width: 55px;
  height: 55px;
  line-height: 60px;
  color: var(--mainColor);
  background: rgba(8, 169, 230, 0.1);
  font-size: 22px;
  border: 1px dashed var(--mainColor);
  display: inline-block;
  text-align: center;
  border-radius: 50px;
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.featured-item h3 {
  margin-bottom: 10px;
}

.featured-item p {
  margin-bottom: 0;
}

.featured-item:hover i {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.featured-item-two {
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  padding: 40px 30px;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  border-radius: 10px;
}

.featured-item-two::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 0;
  height: 100%;
  background-color: var(--mainColor);
  opacity: 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  border-radius: 10px;
}

.featured-item-two a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.featured-item-two a i {
  padding-top: 10px;
  padding-left: 10px;
  position: relative;
  font-size: 55px;
  text-align: center;
  line-height: 1;
  color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: transparent;
  margin-right: 18px;
}

.featured-item-two a i::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: -5px;
  left: -5px;
  width: 47px;
  height: 47px;
  border-radius: 30px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background: var(--mainColor);
  opacity: 0.1;
}

.featured-item-two a h3 {
  margin-bottom: 0;
  -webkit-transition: 0.9s;
  transition: 0.9s;
}

.featured-item-two:hover::before {
  opacity: 1;
  width: 100%;
}

.featured-item-two:hover a i {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  color: var(--whiteColor);
}

.featured-item-two:hover a i::after {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  background: var(--whiteColor);
  opacity: 0.15;
}

.featured-item-two:hover a h3 {
  color: var(--whiteColor);
}

.categories-area {
  position: relative;
}

.categories-area .owl-nav {
  margin-top: 0;
  overflow: hidden;
}

.categories-area .owl-nav .owl-prev {
  position: absolute;
  z-index: 5;
  top: -90px;
  right: 50px;
  font-size: 18px !important;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  border-radius: 50px !important;
  background-color: var(--whiteColor) !important;
  color: var(--mainColor) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  -webkit-box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
  box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
}

.categories-area .owl-nav .owl-prev i {
  position: relative;
  top: 3px;
}

.categories-area .owl-nav .owl-prev:hover {
  color: var(--whiteColor) !important;
  background-color: var(--mainColor) !important;
}

.categories-area .owl-nav .owl-next {
  position: absolute;
  z-index: 5;
  top: -90px;
  right: 0;
  font-size: 18px !important;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  border-radius: 50px !important;
  background-color: var(--whiteColor) !important;
  color: var(--mainColor) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  -webkit-box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
  box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
}

.categories-area .owl-nav .owl-next i {
  position: relative;
  top: 3px;
}

.categories-area .owl-nav .owl-next:hover {
  color: var(--whiteColor) !important;
  background-color: var(--mainColor) !important;
}

.categories-item {
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  border-radius: 10px;
}

.categories-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 0;
  background-color: var(--mainColor);
  opacity: 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  border-radius: 10px;
}

.categories-item a {
  display: block;
}

.categories-item a img {
  border-radius: 10px 10px 0 0;
}

.categories-item .content {
  padding: 30px 20px;
}

.categories-item .content a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.categories-item .content a i {
  width: 60px;
  height: 60px;
  line-height: 65px;
  border-radius: 50%;
  text-align: center;
  font-size: 22px;
  color: var(--mainColor);
  background: rgba(8, 169, 230, 0.1);
  margin-right: 20px;
  -webkit-transition: 0.9s;
  transition: 0.9s;
}

.categories-item .content a h3 {
  margin-bottom: 0;
  -webkit-transition: 0.9s;
  transition: 0.9s;
}

.categories-item:hover::before {
  opacity: 1;
  height: 100%;
}

.categories-item:hover .content a i {
  background-color: var(--whiteColor);
  color: var(--mainColor);
}

.categories-item:hover .content a h3 {
  color: var(--whiteColor);
}


.courses-area {
  position: relative;
 
}

.courses-area .owl-nav {
  margin-top: 0;
  overflow: hidden;
}

.courses-area .owl-nav .owl-prev {
  position: absolute;
  z-index: 5;
  top: -90px;
  right: 50px;
  font-size: 18px !important;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  border-radius: 50px !important;
  background-color: var(--whiteColor) !important;
  color: var(--mainColor) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  -webkit-box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
  box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
}

.courses-area .owl-nav .owl-prev i {
  position: relative;
  top: 3px;
}

.courses-area .owl-nav .owl-prev:hover {
  color: var(--whiteColor) !important;
  background-color: var(--mainColor) !important;
}

.courses-area .owl-nav .owl-next {
  position: absolute;
  z-index: 5;
  top: -90px;
  right: 0;
  font-size: 18px !important;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  border-radius: 50px !important;
  background-color: var(--whiteColor) !important;
  color: var(--mainColor) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  -webkit-box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
  box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
}

.courses-area .owl-nav .owl-next i {
  position: relative;
  top: 3px;
}

.courses-area .owl-nav .owl-next:hover {
  color: var(--whiteColor) !important;
  background-color: var(--mainColor) !important;
}

.courses-area-two {
  position: relative;
  overflow: hidden;
}

.courses-area-two .owl-nav {
  margin-top: 0;
  overflow: hidden;
}

.courses-area-two .owl-nav .owl-prev {
  position: absolute;
  z-index: 5;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -100px;
  opacity: 0;
  font-size: 18px !important;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  border-radius: 50px !important;
  background-color: var(--whiteColor) !important;
  color: var(--mainColor) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  -webkit-box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
  box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
}

.courses-area-two .owl-nav .owl-prev i {
  position: relative;
  top: 3px;
}

.courses-area-two .owl-nav .owl-prev:hover {
  color: var(--whiteColor) !important;
  background-color: var(--mainColor) !important;
}

.courses-area-two .owl-nav .owl-next {
  position: absolute;
  z-index: 5;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -100px;
  opacity: 0;
  font-size: 18px !important;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  border-radius: 50px !important;
  background-color: var(--whiteColor) !important;
  color: var(--mainColor) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  -webkit-box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
  box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
}

.courses-area-two .owl-nav .owl-next i {
  position: relative;
  top: 3px;
}

.courses-area-two .owl-nav .owl-next:hover {
  color: var(--whiteColor) !important;
  background-color: var(--mainColor) !important;
}

.courses-area-two:hover .owl-nav .owl-prev {
  left: 0;
  opacity: 1;
}

.courses-area-two:hover .owl-nav .owl-next {
  right: 0;
  opacity: 1;
}

.courses-item {
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  -webkit-box-shadow: rgba(0, 0, 0, 0.06) 0 0 15px;
  box-shadow: rgba(0, 0, 0, 0.06) 0 0 15px;
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.courses-item a {
  display: block;
}

.courses-item .content {
  padding: 20px;
  position: relative;
}

.courses-item .content .course-instructors {
  position: absolute;
  right: 30px;
  top: -50px;
}

.courses-item .content .course-instructors img {
  border-radius: 50%;
  border: 2px solid var(--whiteColor);
}

.courses-item .content .tag-btn {
  background-color: rgba(8, 169, 230, 0.1);
  padding: 5px 20px;
  color: var(--mainColor);
  display: inline-block;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-weight: 500;
}

.courses-item .content .tag-btn:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.courses-item .content .price-text {
  position: absolute;
  top: 23px;
  right: 30px;
  color: var(--mainColor);
  font-size: 18px;
  font-weight: 600;
}

.courses-item .content h3 {
  margin-top: 11px;
  margin-bottom: 11px;
  font-size: 18px;
  height: 46px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.courses-item .content h3 a {
  color: var(--titleColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.courses-item .content .course-list {
  margin: 0 0 15px;
  padding: 0 0 15px;
  list-style-type: none;
  border-bottom: 1px solid #E7E5E5;
}

.courses-item .content .course-list li {
  display: inline-block;
  margin-right: 30px;
  color: var(--bodyColor);
}

.courses-item .content .course-list li:last-child {
  margin-right: 0;
}

.courses-item .content .course-list li i {
  color: var(--mainColor);
  position: relative;
  top: 3px;
  margin-right: 5px;
}

.courses-item .content .bottom-content {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.courses-item .content .bottom-content .user-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.courses-item .content .bottom-content .user-area img {
  border-radius: 50%;
  margin-right: 10px;
}

.courses-item .content .bottom-content .user-area h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 0;
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.courses-item .content .bottom-content .user-area:hover h3 {
  color: var(--mainColor);
}

.courses-item .content .bottom-content .rating {
  color: var(--bodyColor);
}

.courses-item .content .bottom-content .rating i {
  color: #FFC107;
  margin-right: 5px;
  line-height: 1;
  font-size: 18px;
  position: relative;
  top: 3px;
}

.courses-item .content .bottom-content .rating2 {
  color: var(--bodyColor);
}

.courses-item .content .bottom-content .rating2 i {
  color: #FFC107;
  margin-right: 5px;
  line-height: 1;
  font-size: 18px;
  position: relative;
  top: 3px;
}

.courses-item .content .bottom-content .bottom-price {
  color: var(--mainColor);
  font-size: 22px;
  font-weight: 600;
}

.courses-item:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.courses-item:hover .content h3 a {
  color: var(--mainColor);
}

.courses-page-side-bar-widget {
  margin-bottom: 30px;
  padding: 25px;
  border: 1px solid #F5F5F5;
}

.courses-page-side-bar-widget .title {
  font-size: 22px;
  margin-bottom: 20px;
}

.courses-page-side-bar-widget .courses-page-content {
  list-style-type: none;
  position: relative;
  margin: 0;
  padding: 0;
}

.courses-page-side-bar-widget .courses-page-content li {
  display: block;
  margin-bottom: 10px;
}

.courses-page-side-bar-widget .courses-page-content li:last-child {
  margin-bottom: 0;
}

.courses-page-side-bar-widget .courses-page-content li label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 28px;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 20px;
  color: var(--bodyColor);
  width: 100%;
}

.courses-page-side-bar-widget .courses-page-content li label span {
  text-align: center;
  display: inline-block;
  width: 24px;
  line-height: 24px;
  height: 24px;
  background-color: #DFF3EF;
  color: var(--mainColor);
  font-weight: 600;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 14px;
}

.courses-page-side-bar-widget .courses-page-content li label span.fl {
  float: right;
}

.courses-page-side-bar-widget .courses-page-content li label span:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.courses-page-side-bar-widget .courses-page-content li label::before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 2px;
  background-color: #fff;
  border: 1px solid #ECEDF2;
  border-radius: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  font-family: 'remixicon' !important;
  color: #fff;
  font-size: 9px;
  font-weight: 900;
  text-align: center;
  line-height: 17px;
}

.courses-page-side-bar-widget .courses-page-content li input[type="checkbox"] {
  display: none;
}

.courses-page-side-bar-widget .courses-page-content li input[type="checkbox"]:checked+label:before {
  content: "\eb7b";
  background-color: var(--mainColor);
  border: 1px solid var(--mainColor);
  font-family: 'remixicon' !important;
}

.courses-page-side-bar-widget .courses-page-content li:hover span {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.courses-details-tab .tabs {
  margin: 0 0 30px;
  padding: 0;
  list-style: none;
}

.courses-details-tab .tabs li {
  display: inline-block;
  margin-right: 20px;
  color: var(--titleColor);
  padding: 12px 25px;
  background-color: #F5F5F5;
  cursor: pointer;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.courses-details-tab .tabs li:focus {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.courses-details-tab .tabs li.active {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.courses-details-tab .tabs li.current {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.courses-details-tab .tabs li:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.tab .tabs_item {
  display: none;
}

.tab .tabs_item:first-child {
  display: block;
}

.courses-details-tab-content .event-details-mt-30 {
  margin-top: 30px;
}

.courses-details-tab-content h1,
.courses-details-tab-content h2,
.courses-details-tab-content h3,
.courses-details-tab-content h4,
.courses-details-tab-content h5,
.courses-details-tab-content h6 {
  margin-bottom: 15px;
}

.courses-details-tab-content h1 {
  font-size: 32px;
}

.courses-details-tab-content h2 {
  font-size: 29px;
}

.courses-details-tab-content h3 {
  font-size: 26px;
}

.courses-details-tab-content h4 {
  font-size: 23px;
}

.courses-details-tab-content h5 {
  font-size: 20px;
}

.courses-details-tab-content h6 {
  font-size: 17px;
}

.courses-details-tab-content a {
  color: var(--mainColor);
}

.courses-details-tab-content a:hover {
  color: var(--titleColor);
  border-bottom: 1px solid var(--mainColor);
}

.courses-details-tab-content ul,
.courses-details-tab-content ol {
  margin-bottom: 30px;
}

.courses-details-tab-content ul li,
.courses-details-tab-content ol li {
  margin-bottom: 10px;
}

.courses-details-tab-content ul li:last-child,
.courses-details-tab-content ol li:last-child {
  margin-bottom: 0;
}

.courses-details-tab-content p {
  margin-bottom: 15px;
}

.courses-details-tab-content .courses-details-into {
  margin-bottom: 30px;
}

.courses-details-tab-content .courses-details-into .courses-details-list {
  margin: 0 0 15px;
  list-style-type: none;
  padding: 0;
}

.courses-details-tab-content .courses-details-into .courses-details-list li {
  display: block;
  position: relative;
  z-index: 1;
  margin-bottom: 5px;
  padding-left: 25px;
}

.courses-details-tab-content .courses-details-into .courses-details-list li:last-child {
  margin-bottom: 0;
}

.courses-details-tab-content .courses-details-into .courses-details-list li::before {
  content: '';
  position: absolute;
  top: 7px;
  left: 0;
  width: 10px;
  height: 10px;
  background-color: var(--mainColor);
}

.courses-details-tab-content .courses-details-accordion {
  margin-bottom: 30px;
}

.courses-details-tab-content .courses-details-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  border: none;
}

.courses-details-tab-content .courses-details-accordion .accordion .accordion-item {
  display: block;
  margin-bottom: 15px;
  border: 1px solid #e7e7ec;
  background-color: var(--whiteColor);
}

.courses-details-tab-content .courses-details-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.courses-details-tab-content .courses-details-accordion .accordion .accordion-title {
  color: var(--titleColor);
  text-decoration: none;
  position: relative;
  display: block;
  font-size: 18px;
  font-weight: 600;
  font-family: var(--headerFonts);
  padding: 20px;
}

.courses-details-tab-content .courses-details-accordion .accordion .accordion-title i {
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 30px;
  line-height: 30px;
  color: var(--titleColor);
  text-align: center;
  background-color: transparent;
}

.courses-details-tab-content .courses-details-accordion .accordion .accordion-title.active {
  color: var(--mainColor);
}

.courses-details-tab-content .courses-details-accordion .accordion .accordion-title.active i {
  color: var(--mainColor);
}

.courses-details-tab-content .courses-details-accordion .accordion .accordion-title.active i::before {
  content: "\f1af";
}

.courses-details-tab-content .courses-details-accordion .accordion .accordion-content {
  display: none;
  position: relative;
}

.courses-details-tab-content .courses-details-accordion .accordion .accordion-content .accordion-content-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid #e7e7ec;
  padding: 20px;
}

.courses-details-tab-content .courses-details-accordion .accordion .accordion-content .accordion-content-list .accordion-content-left i {
  position: relative;
  top: 3px;
  margin-right: 10px;
}

.courses-details-tab-content .courses-details-accordion .accordion .accordion-content .accordion-content-list .accordion-content-right span {
  margin-right: 10px;
  color: #adadad;
}

.courses-details-tab-content .courses-details-accordion .accordion .accordion-content .accordion-content-list .accordion-content-right .tag {
  display: inline-block;
  padding: 7px 15px;
  color: var(--mainColor);
  font-size: 14px;
  background-color: rgba(8, 169, 230, 0.1);
  margin-right: 10px;
}

.courses-details-tab-content .courses-details-accordion .accordion .accordion-content .accordion-content-list .accordion-content-right .tag2 {
  display: inline-block;
  padding: 7px 15px;
  color: var(--redColor);
  font-size: 14px;
  background-color: rgba(236, 39, 47, 0.1);
  margin-right: 10px;
}

.courses-details-tab-content .courses-details-accordion .accordion .accordion-content .accordion-content-list .accordion-content-right i {
  color: var(--bodyColor);
  font-size: 18px;
  position: relative;
  top: 5px;
}

.courses-details-tab-content .courses-details-accordion .accordion .accordion-content.show {
  display: block;
}

.courses-details-tab-content .courses-details-instructor {
  margin-bottom: 30px;
}

.courses-details-tab-content .courses-details-instructor h3 {
  font-size: 20px;
  margin-bottom: 20px;
}

.courses-details-tab-content .courses-details-instructor .details-instructor {
  padding: 10px 10px 10px 90px;
  position: relative;
  margin-bottom: 20px;
}

.courses-details-tab-content .courses-details-instructor .details-instructor img {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
}

.courses-details-tab-content .courses-details-instructor .details-instructor h3 {
  font-size: 18px;
  margin-bottom: 5px;
}

.courses-details-tab-content .courses-details-instructor .details-instructor span {
  margin-bottom: 0;
}

.courses-details-tab-content .courses-details-instructor .course-list {
  margin: 0 0 20px;
  padding: 0;
  list-style-type: none;
  border-bottom: 1px solid #E7E5E5;
}

.courses-details-tab-content .courses-details-instructor .course-list li {
  display: inline-block;
  margin-right: 30px;
  color: var(--bodyColor);
}

.courses-details-tab-content .courses-details-instructor .course-list li:last-child {
  margin-right: 0;
}

.courses-details-tab-content .courses-details-instructor .course-list li i {
  color: var(--mainColor);
  position: relative;
  top: 3px;
  margin-right: 5px;
}

.courses-details-tab-content .courses-details-review-form .review-title {
  position: relative;
}

.courses-details-tab-content .courses-details-review-form .review-title .rating {
  display: inline-block;
}

.courses-details-tab-content .courses-details-review-form .review-title .rating i {
  color: #ffc107;
  font-size: 14px;
}

.courses-details-tab-content .courses-details-review-form .review-title .rating p {
  margin-bottom: 0;
  display: inline-block;
  padding-left: 5px;
  line-height: initial;
}

.courses-details-tab-content .courses-details-review-form .review-title .btn-right {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 14px;
  color: var(--whiteColor);
  background-color: var(--mainColor);
  padding: 7px 15px;
  border-radius: 5px;
}

.courses-details-tab-content .courses-details-review-form .review-title .btn-right:hover {
  background-color: var(--titleColor);
  color: var(--whiteColor);
}

.courses-details-tab-content .courses-details-review-form .review-comments {
  margin-top: 35px;
}

.courses-details-tab-content .courses-details-review-form .review-comments .review-item {
  margin-top: 30px;
  position: relative;
  padding-right: 200px;
  border-top: 1px dashed #eeeeee;
  padding-top: 30px;
}

.courses-details-tab-content .courses-details-review-form .review-comments .review-item .content {
  position: relative;
  z-index: 1;
  padding: 3px 0 3px 80px;
  margin-bottom: 15px;
}

.courses-details-tab-content .courses-details-review-form .review-comments .review-item .content img {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  max-width: 60px;
}

.courses-details-tab-content .courses-details-review-form .review-comments .review-item .content .content-dtls h4 {
  font-size: 18px;
  margin-bottom: 5px;
  margin-top: 0;
}

.courses-details-tab-content .courses-details-review-form .review-comments .review-item .content .content-dtls span {
  color: var(--mainColor);
  font-size: 14px;
}

.courses-details-tab-content .courses-details-review-form .review-comments .review-item .rating i {
  color: #ffc107;
  font-size: 14px;
}

.courses-details-tab-content .courses-details-review-form .review-comments .review-item h3 {
  font-size: 21px;
  margin-top: 15px;
  margin-bottom: 15px;
  color: var(--titleColor);
}

.courses-details-tab-content .courses-details-review-form .review-comments .review-item p {
  margin-bottom: 0;
}

.courses-details-tab-content .courses-details-review-form .review-comments .review-item .review-report-link {
  position: absolute;
  right: 0;
  color: rgba(218, 29, 37, 0.53);
  top: 40px;
  text-decoration: underline;
  font-weight: 600;
  font-size: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.courses-details-tab-content .courses-details-review-form .review-comments .review-item .review-report-link:hover {
  color: var(--mainColor);
}

.courses-details-tab-content .courses-details-review-form .review-form {
  margin-top: 25px;
}

.courses-details-tab-content .courses-details-review-form .review-form .contact-form {
  max-width: 100%;
  margin: 0 auto 30px;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
}

.courses-details-tab-content .courses-details-review-form .review-form .contact-form h4 {
  margin-bottom: 25px;
}

.courses-details-tab-content .courses-details-review-form .review-form .contact-form .form-group .form-control {
  background-color: #F5F5F5;
}

.courses-details-sidebar {
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  position: relative;
  z-index: 2;
  margin-top: -220px !important;
  margin-bottom: 30px;
}

.courses-details-sidebar .content {
  padding: 30px;
}

.courses-details-sidebar .content h3 {
  margin-bottom: 10px;
}

.courses-details-sidebar .content span {
  color: var(--titleColor);
  margin-bottom: 10px;
  font-weight: 600;
}

.courses-details-sidebar .content .courses-details-list {
  list-style-type: none;
  margin: 15px 0;
  padding: 0;
}

.courses-details-sidebar .content .courses-details-list li {
  display: block;
  margin-bottom: 5px;
  color: var(--bodyColor);
}

.courses-details-sidebar .content .courses-details-list li:last-child {
  margin-bottom: 0;
}

.courses-details-sidebar .content .default-btn {
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
}

.courses-details-sidebar .social-link {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.courses-details-sidebar .social-link li {
  display: inline-block;
  margin-right: 10px;
}

.courses-details-sidebar .social-link li.social-title {
  font-size: 18px;
  color: var(--titleColor);
}

.courses-details-sidebar .social-link li:last-child {
  margin-right: 0;
}

.courses-details-sidebar .social-link li a {
  display: inline-block;
}

.courses-details-sidebar .social-link li a i {
  font-size: 15px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 50px;
  color: var(--mainColor);
  background-color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: inline-block;
  border: 1px solid var(--mainColor);
}

.courses-details-sidebar .social-link li a:hover i {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.product-topper .product-title h3 {
  font-family: var(--bodyFonts);
  font-size: 16px;
  color: var(--bodyColor);
  margin-bottom: 0;
  font-weight: 500;
}

.product-topper .product-title h3 span {
  color: var(--mainColor);
}

.product-topper .product-list {
  vertical-align: middle;
  position: relative;
  background-color: transparent;
  border: 1px solid #EEEEEE;
}

.product-topper .product-list i {
  display: inline-block;
  position: absolute;
  top: 8px;
  right: 15px;
  color: var(--bodyColor);
  font-size: 20px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.product-topper .product-list .form-select {
  color: var(--bodyColor);
  background-color: transparent;
  border: 0;
  background-image: unset;
  padding: 12px 40px 12px 20px;
}

.product-topper .product-list .form-select:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.product-topper .product-list .form-select:focus i {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.product-topper .product-list .form-select option {
  color: var(--bodyColor);
  padding-top: 5px;
  padding-bottom: 5px;
}

.product-topper .product-list:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.product-topper .product-list:hover i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}


.cart-wraps-area .cart-wraps {
  margin-bottom: 30px;
}

.cart-wraps-area .cart-table table {
  margin-bottom: 0;
}

.cart-wraps-area .cart-table table thead tr th {
  border-bottom-width: 0px;
  vertical-align: middle;
  padding: 15px 0;
  border: none;
  font-weight: 700;
  font-size: 18px;
}

.cart-wraps-area .cart-table table tbody tr td {
  vertical-align: middle;
  color: var(--titleColor);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 0;
  padding-right: 0;
  font-size: 15px;
  border-color: #eeeeee;
  border-left: none;
  border-right: none;
}

.cart-wraps-area .cart-table table tbody tr td.courses-thumbnail a {
  display: block;
}

.cart-wraps-area .cart-table table tbody tr td.courses-thumbnail a img {
  width: 100px;
  height: 100px;
  background-color: #f2f6fe;
  padding: 3px;
}

.cart-wraps-area .cart-table table tbody tr td.courses-name a {
  color: var(--titleColor);
  font-weight: 600;
  display: inline-block;
  font-size: 16px;
}

.cart-wraps-area .cart-table table tbody tr td.courses-name a:hover {
  color: var(--mainColor) !important;
}

.cart-wraps-area .cart-table table tbody tr td.courses-subtotal .remove {
  color: var(--bodyColor);
  float: right;
  position: relative;
  top: 1px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.cart-wraps-area .cart-table table tbody tr td.courses-subtotal .remove i {
  font-size: 24px;
}

.cart-wraps-area .cart-table table tbody tr td.courses-subtotal .remove:hover {
  color: var(--greenColor);
}

.cart-wraps-area .cart-table table tbody tr td.courses-subtotal span {
  font-weight: 600;
}

.cart-wraps-area .cart-table .table> :not(:first-child) {
  border-top: none;
}

.cart-wraps-area .cart-buttons {
  margin-top: 30px;
}

.cart-totals {
  border-radius: 0;
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
  padding: 40px;
  margin-left: 30px;
  margin-bottom: 30px;
}

.cart-totals h3 {
  font-size: 24px;
  margin-bottom: 15px;
}

.cart-totals ul {
  padding: 0;
  margin: 0 0 25px;
  list-style-type: none;
}

.cart-totals ul li {
  border-bottom: 1px solid #eeeeee;
  padding: 10px 15px;
  color: var(--bodyColor);
  overflow: hidden;
  font-weight: 500;
}

.cart-totals ul li span {
  float: right;
  color: var(--titleColor);
  font-weight: normal;
}


.checkout-user {
  color: var(--titleColor);
  font-size: 16px;
  margin-bottom: 30px;
  border-left: 2px solid var(--mainColor);
  padding: 15px 25px;
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 5px 28px rgba(0, 0, 0, 0.07);
  box-shadow: 0 5px 28px rgba(0, 0, 0, 0.07);
}

.checkout-user span {
  color: var(--bodyColor);
  font-size: 18px;
}

.checkout-user span a {
  color: var(--mainColor);
}

.checkout-user span a:hover {
  color: var(--titleColor);
}

.billing-details {
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  padding: 50px 30px 25px 30px;
  -webkit-box-shadow: 0 5px 28px rgba(0, 0, 0, 0.07);
  box-shadow: 0 5px 28px rgba(0, 0, 0, 0.07);
}

.billing-details h3 {
  font-size: 24px;
  color: var(--titleColor);
  margin-bottom: 25px;
  font-weight: 600;
}

.billing-details .form-group {
  margin-bottom: 25px;
}

.billing-details .form-group label {
  color: var(--titleColor);
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
}

.billing-details .form-group label span {
  color: var(--mainColor);
}

.billing-details .form-group .form-control {
  height: 50px;
  color: var(--bodyColor);
  border: none;
  background-color: #f9f8f8;
  border-radius: 0;
  padding: 10px 20px;
  width: 100%;
}

.billing-details .form-group .form-control:focus,
.billing-details .form-group .form-control :hover {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.billing-details .form-group .form-message {
  font-size: 16px;
  border: none;
  background-color: #f9f8f8;
  padding: 18px 18px;
  font-weight: 400;
  width: 100%;
}

.billing-details .form-group .form-message:focus,
.billing-details .form-group .form-message :hover {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.billing-details .form-check {
  margin-bottom: 15px;
}

.billing-details .form-check .form-check-input {
  width: 15px;
  height: 15px;
}

.billing-details .form-check .form-check-label {
  color: var(--titleColor);
  margin-left: 5px;
  font-weight: 500;
}

.billing-totals {
  border-radius: 0;
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
  padding: 35px 30px 40px;
  margin-bottom: 30px;
}

.billing-totals h3 {
  font-size: 22px;
  margin-bottom: 15px;
}

.billing-totals ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.billing-totals ul li {
  border-bottom: 1px solid #eeeeee;
  padding: 10px 15px;
  color: var(--bodyColor);
  overflow: hidden;
  font-weight: 500;
}

.billing-totals ul li span {
  float: right;
  color: var(--titleColor);
  font-weight: normal;
}

.payment-box {
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 5px 28px rgba(0, 0, 0, 0.07);
  box-shadow: 0 5px 28px rgba(0, 0, 0, 0.07);
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 40px 30px;
}

.payment-box .payment-method h3 {
  font-size: 22px;
  margin-bottom: 20px;
}

.payment-box .payment-method p [type="radio"]:checked {
  display: none;
}

.payment-box .payment-method p [type="radio"]:checked+label {
  padding-left: 27px;
  cursor: pointer;
  display: block;
  font-weight: 600;
  color: var(--titleColor);
  position: relative;
  margin-bottom: 15px;
}

.payment-box .payment-method p [type="radio"]:checked+label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 3px;
  width: 18px;
  height: 18px;
  border: 1px solid #dddddd;
  border-radius: 50%;
  background: #ffffff;
}

.payment-box .payment-method p [type="radio"]:checked+label::after {
  content: '';
  width: 12px;
  height: 12px;
  background: var(--mainColor);
  position: absolute;
  top: 6px;
  left: 3px;
  border-radius: 50%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.payment-box .payment-method p [type="radio"]:not(:checked) {
  display: none;
}

.payment-box .payment-method p [type="radio"]:not(:checked)+label {
  padding-left: 27px;
  cursor: pointer;
  display: block;
  font-weight: 600;
  color: #172541;
  position: relative;
  margin-bottom: 15px;
}

.payment-box .payment-method p [type="radio"]:not(:checked)+label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 3px;
  width: 18px;
  height: 18px;
  border: 1px solid #dddddd;
  border-radius: 50%;
  background: #ffffff;
}

.payment-box .payment-method p [type="radio"]:not(:checked)+label::after {
  content: '';
  width: 12px;
  height: 12px;
  background: var(--mainColor);
  position: absolute;
  top: 6px;
  left: 3px;
  border-radius: 50%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.payment-box .default-btn {
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

.pricing-card {
  margin-bottom: 30px;
  background-color: #F5F5F5;
  padding: 40px;
}

.pricing-card .top-content {
  padding-bottom: 15px;
  border-bottom: 1px solid #DDDDDD;
  margin-bottom: 25px;
}

.pricing-card .top-content h3 {
  font-size: 30px;
  margin-bottom: 10px;
}

.pricing-card .top-content h2 {
  font-size: 50px;
  color: var(--mainColor);
  margin-bottom: 0;
  font-weight: 500;
}

.pricing-card .top-content h2 sub {
  font-size: 20px;
  font-weight: 400;
}

.pricing-card ul {
  margin: 0 0 25px;
  padding: 0;
  list-style-type: none;
}

.pricing-card ul li {
  display: block;
  margin-bottom: 10px;
  color: var(--bodyColor);
}

.pricing-card ul li i {
  color: var(--mainColor);
  position: relative;
  top: 2px;
  margin-right: 10px;
  font-size: 18px;
}


.play-area {
  position: relative;
  z-index: 1;
}

.play-area .title h2 {
  font-size: 22px;
}

.play-area .title h2 span {
  color: var(--mainColor);
}

.play-area::before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 70%;
  background-color: #F5F5F5;
}

.brand-item {
  text-align: center;
}

.brand-item img {
  width: unset !important;
  margin: 0 auto;
}

.brand-item .brand-item-logo2 {
  display: none !important;
}

.brand-item:hover img {
  opacity: 1;
}

.play-btn-area {
  background-image: url(../images/video-bg.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  max-width: 1100px;
  margin: 0 auto;
  padding: 200px 0;
  text-align: center;
  border-radius: 20px;
}

.play-btn-area .play-btn {
  display: inline-block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
}

.play-btn-area .play-btn i {
  display: inline-block;
  width: 90px;
  height: 90px;
  line-height: 95px;
  background-color: var(--whiteColor);
  color: var(--mainColor);
  font-size: 30px;
  border-radius: 50px;
  margin: 0 auto;
  padding-left: 5px;
  padding-top: 3px;
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-align: center;
  z-index: 1;
}

.play-btn-area .play-btn i::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  -webkit-animation: ripple 2s infinite ease-in-out;
  animation: ripple 2s infinite ease-in-out;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.play-btn-area .play-btn:hover i {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.play-btn-area .play-btn:hover i::after {
  background-color: var(--mainColor);
  opacity: 0.3;
}


.faq-img {
  position: relative;
  margin-bottom: 30px;
}

.faq-img img {
  -webkit-animation: border-transform 10s linear infinite alternate forwards;
  animation: border-transform 10s linear infinite alternate forwards;
}

.faq-img .shape {
  position: absolute;
  bottom: 100px;
  right: -10px;
}

.faq-img .shape img {
  -webkit-animation: none;
  animation: none;
}

.faq-accordion {
  margin-bottom: 30px;
}

.faq-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  border: none;
}

.faq-accordion .accordion .accordion-item {
  display: block;
  margin-bottom: 20px;
  border: none;
}

.faq-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion-title {
  color: var(--titleColor);
  text-decoration: none;
  position: relative;
  display: block;
  font-size: 18px;
  font-weight: 600;
  font-family: var(--headerFonts);
  padding: 8px 0 8px 55px;
}

.faq-accordion .accordion .accordion-title i {
  position: absolute;
  left: 0;
  top: 0;
  width: 45px;
  height: 45px;
  line-height: 45px;
  color: var(--titleColor);
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  border-radius: 50px;
  text-align: center;
}

.faq-accordion .accordion .accordion-title.active {
  color: var(--mainColor);
}

.faq-accordion .accordion .accordion-title.active i {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.faq-accordion .accordion .accordion-title.active i::before {
  content: "\f1af";
}

.faq-accordion .accordion .accordion-content {
  display: none;
  position: relative;
  margin-top: 15px;
  padding: 0;
}

.faq-accordion .accordion .accordion-content p {
  line-height: 1.8;
  font-size: 15px;
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion-content p:last-child {
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion-content.show {
  display: block;
}


.instructors-area {
  position: relative;
}

.instructors-area .owl-nav {
  margin-top: 0;
  overflow: hidden;
}

.instructors-area .owl-nav .owl-prev {
  position: absolute;
  z-index: 5;
  top: -90px;
  right: 50px;
  font-size: 18px !important;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  border-radius: 50px !important;
  background-color: var(--whiteColor) !important;
  color: var(--mainColor) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  -webkit-box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
  box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
}

.instructors-area .owl-nav .owl-prev i {
  position: relative;
  top: 3px;
}

.instructors-area .owl-nav .owl-prev:hover {
  color: var(--whiteColor) !important;
  background-color: var(--mainColor) !important;
}

.instructors-area .owl-nav .owl-next {
  position: absolute;
  z-index: 5;
  top: -90px;
  right: 0;
  font-size: 18px !important;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  border-radius: 50px !important;
  background-color: var(--whiteColor) !important;
  color: var(--mainColor) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  -webkit-box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
  box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
}

.instructors-area .owl-nav .owl-next i {
  position: relative;
  top: 3px;
}

.instructors-area .owl-nav .owl-next:hover {
  color: var(--whiteColor) !important;
  background-color: var(--mainColor) !important;
}

.instructors-item {
  margin-bottom: 30px;
}

.instructors-item .instructors-img {
  position: relative;
}

.instructors-item .instructors-img a {
  display: block;
  text-align: center;
  margin: 0 auto;
}

.instructors-item .instructors-img a img {
  margin: 0 auto;
}

.instructors-item .instructors-img .instructors-social {
  padding-left: 0;
  list-style-type: none;
  position: absolute;
  z-index: 1;
  left: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  margin: 0 auto;
  margin-bottom: 0;
}

.instructors-item .instructors-img .instructors-social li {
  display: block;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  opacity: 0;
  visibility: hidden;
  margin-bottom: 10px;
}

.instructors-item .instructors-img .instructors-social li:last-child {
  margin-bottom: 0;
}

.instructors-item .instructors-img .instructors-social li a {
  display: inline-block;
}

.instructors-item .instructors-img .instructors-social li a i {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 16px;
  color: var(--mainColor);
  background-color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: inline-block;
  border-radius: 50px;
}

.instructors-item .instructors-img .instructors-social li a:hover i {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.instructors-item .instructors-img .instructors-social li:nth-child(1) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.instructors-item .instructors-img .instructors-social li:nth-child(2) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.instructors-item .instructors-img .instructors-social li:nth-child(3) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.instructors-item .instructors-img .instructors-social li:nth-child(4) {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.instructors-item .content {
  padding-top: 20px;
  text-align: center;
}

.instructors-item .content h3 {
  margin-bottom: 5px;
}

.instructors-item .content h3 a {
  color: var(--titleColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.instructors-item .content span {
  color: var(--bodyColor);
}

.instructors-item:hover .instructors-img .instructors-social li {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.instructors-item:hover .content h3 a {
  color: var(--mainColor);
}

.instructors-card {
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
}

.instructors-card a {
  display: block;
  margin: 0 auto;
}

.instructors-card a img {
  margin: 0 auto;
  border-radius: 10px;
}

.instructors-card .content {
  padding: 30px;
  position: relative;
}

.instructors-card .content .instructors-social {
  margin: 0;
  list-style-type: none;
  padding: 0;
  position: absolute;
  right: 85px;
  bottom: 40px;
}

.instructors-card .content .instructors-social li {
  display: inline-block;
  position: absolute;
  bottom: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.instructors-card .content .instructors-social li.share-btn i {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border-radius: 50px;
  background-color: rgba(8, 169, 230, 0.1);
  color: var(--mainColor);
  display: inline-block;
  margin: 0 8px;
  font-size: 24px;
  -webkit-transition: 0.9s;
  transition: 0.9s;
}

.instructors-card .content .instructors-social li.share-btn i:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.instructors-card .content .instructors-social li:nth-child(1) {
  z-index: 1;
}

.instructors-card .content .instructors-social li:nth-child(2),
.instructors-card .content .instructors-social li:nth-child(3),
.instructors-card .content .instructors-social li:nth-child(4) .instructors-card .content .instructors-social li:nth-child(5) {
  bottom: 0;
}

.instructors-card .content .instructors-social li a {
  color: var(--whiteColor);
}

.instructors-card .content .instructors-social li a i {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 45px;
  background-color: var(--whiteColor);
  color: var(--mainColor);
  text-align: center;
  font-size: 18px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 50px;
  margin: 0 10px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  opacity: 0;
}

.instructors-card .content .instructors-social li a:hover i {
  background-color: var(--mainColor);
  color: var(--whiteColor);
  opacity: 1;
}

.instructors-card .content .instructors-social:hover li a i {
  opacity: 1;
}

.instructors-card .content .instructors-social:hover li:nth-child(1) {
  z-index: 1;
}

.instructors-card .content .instructors-social:hover li:nth-child(2) {
  bottom: 65px;
}

.instructors-card .content .instructors-social:hover li:nth-child(3) {
  bottom: 122px;
}

.instructors-card .content .instructors-social:hover li:nth-child(4) {
  bottom: 178px;
}

.instructors-card .content .instructors-social:hover li:nth-child(5) {
  bottom: 235px;
}

.instructors-card .content h3 {
  margin-bottom: 5px;
}

.instructors-card .content h3 a {
  color: var(--titleColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.instructors-card .content span {
  color: var(--bodyColor);
}

.instructors-item-bg {
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
}

.instructors-item-bg .instructors-img .instructors-social li a i {
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
}

.instructors-item-bg .content {
  padding: 20px;
}

.instructors-item-bg .content h3 {
  font-size: 20px;
}

.instructors-details-img {
  margin-bottom: 30px;
}

.instructors-details-img img {
  margin-bottom: 25px;
  height: 125px;
  width: 120px;
  object-fit: cover;
  border-radius: 5px;
}
.sticky-header .mentersvies0121 div img {
  width: 100px !important;
  height: 111px;
  object-fit: cover;
}
.instructors-details-img .social-link {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.instructors-details-img .social-link li {
  display: inline-block;
  margin-right: 10px;
}

.instructors-details-img .social-link li.social-title {
  font-size: 18px;
  color: var(--titleColor);
}

.instructors-details-img .social-link li:last-child {
  margin-right: 0;
}

.instructors-details-img .social-link li a {
  display: inline-block;
}

.instructors-details-img .social-link li a i {
  font-size: 15px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 50px;
  color: var(--mainColor);
  background-color: transparent;
  border: 1px solid var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: inline-block;
}

.instructors-details-img .social-link li a:hover i {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.instructors-details-content {
  margin-bottom: 30px;
}

.instructors-details-content h3 {
  margin-bottom: 5px;
}

.instructors-details-content .sub-title {
  margin-bottom: 25px;
  display: block;
}

.instructors-details-content ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.instructors-details-content ul li {
  display: block;
  margin-bottom: 10px;
  color: var(--titleColor);
}

.instructors-details-content ul li span {
  color: var(--bodyColor);
  margin-left: 10px;
}

.instructors-details-content ul li span i {
  position: relative;
  top: 2px;
  color: #FFC107;
  margin-right: 3px;
}

.instructors-details-content ul li span a {
  color: var(--bodyColor);
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.instructors-details-content ul li span a:hover {
  color: var(--mainColor);
}


.testimonials-area {
  position: relative;
  overflow: hidden;
}

.testimonials-area .owl-dots {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.testimonials-area .owl-dots .owl-dot span {
  background-color: rgba(33, 34, 95, 0.2) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  margin: 0 7px;
  position: relative;
  z-index: 1;
  border-radius: 5px;
}

.testimonials-area .owl-dots .owl-dot.active span {
  background-color: var(--mainColor) !important;
}

.testimonials-area .owl-dots .owl-dot:hover span {
  background-color: var(--mainColor) !important;
}

.testimonials-area .owl-nav {
  margin-top: 0;
  overflow: hidden;
}

.testimonials-area .owl-nav .owl-prev {
  position: absolute;
  z-index: 5;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -100px;
  opacity: 0;
  font-size: 18px !important;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  border-radius: 50px !important;
  background-color: var(--whiteColor) !important;
  color: var(--mainColor) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  -webkit-box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
  box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
}

.testimonials-area .owl-nav .owl-prev i {
  position: relative;
  top: 3px;
}

.testimonials-area .owl-nav .owl-prev:hover {
  color: var(--whiteColor) !important;
  background-color: var(--mainColor) !important;
}

.testimonials-area .owl-nav .owl-next {
  position: absolute;
  z-index: 5;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -100px;
  opacity: 0;
  font-size: 18px !important;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  border-radius: 50px !important;
  background-color: var(--whiteColor) !important;
  color: var(--mainColor) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  -webkit-box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
  box-shadow: 0 0 15px rgba(46, 46, 46, 0.1);
}

.testimonials-area .owl-nav .owl-next i {
  position: relative;
  top: 3px;
}

.testimonials-area .owl-nav .owl-next:hover {
  color: var(--whiteColor) !important;
  background-color: var(--mainColor) !important;
}

.testimonials-area:hover .owl-nav .owl-prev {
  left: 0;
  opacity: 1;
}

.testimonials-area:hover .owl-nav .owl-next {
  right: 0;
  opacity: 1;
}
.testimonials-card-two {
  margin: 0 15px; /* Adjust this value to set the gap between the cards */
}


.testimonials-area .testimonials-card-two {
  margin-top: 45px;
  margin-left: 10px;
  margin-right: 10px;
}

.testimonials-item {
  position: relative;
  z-index: 1;
  margin-top: 45px;
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  border-radius: 10px;
  padding: 30px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
}

.testimonials-item img {
  position: relative;
  margin-top: -75px;
  width: 80px !important;
  border-radius: 50%;
  margin-bottom: 15px;
}

.testimonials-item .rating {
  color: #FFC107;
  display: block;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  margin-bottom: 10px;
}

.testimonials-item .rating i {
  display: inline-block;
}

.testimonials-item p {
  margin-bottom: 15px;
  display: block;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.testimonials-item h3 {
  margin-bottom: 5px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.testimonials-item span {
  margin-bottom: 0;
  display: block;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.testimonials-item .quote {
  position: absolute;
  bottom: 10px;
  right: 30px;
  font-size: 40px;
  color: var(--mainColor);
  opacity: 0.5;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.testimonials-card {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  border-radius: 10px;
  padding: 30px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
}

.testimonials-card .content {
  padding-left: 100px;
  position: relative;
  margin-bottom: 30px;
}

.testimonials-card .content img {
  position: absolute;
  top: 0;
  left: 0;
  width: 70px !important;
  border-radius: 50%;
}

.testimonials-card .content h3 {
  margin-bottom: 5px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.testimonials-card .content span {
  margin-bottom: 0;
  display: block;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.testimonials-card p {
  margin-bottom: 15px;
  display: block;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.testimonials-card .rating {
  color: #FFC107;
  display: block;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  margin-bottom: 0;
}

.testimonials-card .rating i {
  display: inline-block;
}

.testimonials-card .quote {
  position: absolute;
  top: 35px;
  right: 30px;
  font-size: 45px;
  color: var(--mainColor);
  opacity: 0.5;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.testimonials-card-two {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  border-radius: 10px;
  padding: 30px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
}

.testimonials-card-two p {
  margin-bottom: 15px;
  display: block;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.testimonials-card-two .rating {
  color: #FFC107;
  display: block;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  margin-bottom: 0;
}

.testimonials-card-two .rating i {
  display: inline-block;
}

.testimonials-card-two .content {
  padding-left: 100px;
  position: relative;
  margin-bottom: 0;
}

.testimonials-card-two .content img {
  position: absolute;
  top: 0;
  left: 0;
  width: 70px !important;
  border-radius: 50%;
}

.testimonials-card-two .content h3 {
  margin-bottom: 5px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.testimonials-card-two .content span {
  margin-bottom: 0;
  display: block;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.testimonials-card-two .quote {
  position: absolute;
  bottom: 20px;
  right: 30px;
  font-size: 45px;
  color: var(--mainColor);
  opacity: 0.5;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.enrolled-area {
  background-color: #F5F5F5;
}

.enrolled-content .section-title {
  margin-bottom: 20px;
}

.enrolled-content .enrolled-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.enrolled-content .enrolled-list li {
  display: block;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 55px;
  position: relative;
  font-size: 18px;
  color: var(--titleColor);
  margin-bottom: 20px;
  font-weight: 600;
}

.enrolled-content .enrolled-list li i {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  line-height: 47px;
  border-radius: 50px;
  text-align: center;
  background-color: rgba(8, 169, 230, 0.1);
  color: var(--mainColor);
}

.enrolled-img {
  margin-top: 50px;
  position: relative;
  z-index: 1;
  margin-left: 70px;
  position: relative;
  z-index: 1;
  text-align: center;
}

.enrolled-img .bg-shape {
  position: absolute;
  z-index: -1;
  top: 100px;
  left: -30px;
  right: 0;
  text-align: center;
}

.enrolled-img .enrolled-img-content {
  padding: 15px;
  background-color: var(--whiteColor);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.enrolled-img .enrolled-img-content i {
  width: 45px;
  height: 45px;
  line-height: 50px;
  font-size: 20px;
  color: var(--whiteColor);
  background-color: var(--titleColor);
  margin-right: 12px;
  text-align: center;
}

.enrolled-img .enrolled-img-content i.active {
  background-color: #08A9E6;
}

.enrolled-img .enrolled-img-content .content h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
}

.enrolled-img .enrolled-img-content .content p {
  font-size: 14px;
  margin-bottom: 0;
}

.enrolled-img .top-content {
  position: absolute;
  top: 140px;
  left: -20px;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.enrolled-img .right-content {
  position: absolute;
  bottom: 200px;
  right: 0;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.enrolled-img .left-content {
  position: absolute;
  bottom: 70px;
  left: -20px;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.enrolled-img .enrolled-img-shape .shape1 {
  position: absolute;
  top: 30px;
  right: 0;
  z-index: -1;
  -webkit-animation: moveleftbounce 9s linear infinite;
  animation: moveleftbounce 9s linear infinite;
}

.enrolled-img-two img {
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.enrolled-img-three {
  margin-bottom: 30px;
  position: relative;
}

.enrolled-img-three img {
  max-width: 100%;
  border-radius: 10px;
}

.enrolled-img-three .enrolled-img-content {
  position: absolute;
  bottom: 40px;
  right: 0;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
  padding: 15px;
  background-color: var(--whiteColor);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}

.enrolled-img-three .enrolled-img-content i {
  width: 45px;
  height: 45px;
  line-height: 50px;
  font-size: 20px;
  color: var(--whiteColor);
  background-color: var(--titleColor);
  margin-right: 12px;
  text-align: center;
  border-radius: 50px;
}

.enrolled-img-three .enrolled-img-content i.active {
  background-color: #08A9E6;
}

.enrolled-img-three .enrolled-img-content .content h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
}

.enrolled-img-three .enrolled-img-content .content p {
  font-size: 14px;
  margin-bottom: 0;
}


.event-area .owl-dots {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.event-area .owl-dots .owl-dot span {
  background-color: rgba(33, 34, 95, 0.2) !important;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  margin: 0 7px;
  position: relative;
  z-index: 1;
  border-radius: 5px;
}

.event-area .owl-dots .owl-dot.active span {
  background-color: var(--mainColor) !important;
}

.event-area .owl-dots .owl-dot:hover span {
  background-color: var(--mainColor) !important;
}

.event-item {
  padding: 30px 20px;
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.event-item .event-img {
  margin-right: 20px;
  max-width: 180px;
}

.event-item .event-img a {
  display: block;
}

.event-item .event-img a img {
  border-radius: 10px;
}

.event-item .event-content .event-list {
  margin: 0 0 12px;
  padding: 0;
  list-style-type: none;
}

.event-item .event-content .event-list li {
  display: inline-block;
  margin-right: 10px;
  color: var(--bodyColor);
}

.event-item .event-content .event-list li:last-child {
  margin-right: 0;
}

.event-item .event-content .event-list li i {
  color: var(--mainColor);
  position: relative;
  top: 3px;
  margin-right: 5px;
}

.event-item .event-content h3 {
  margin-bottom: 10px;
}

.event-item .event-content h3 a {
  color: var(--titleColor);
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.event-item .event-content p {
  margin-bottom: 0;
}

.event-item:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.event-item:hover .event-content h3 a {
  color: var(--mainColor);
}

.event-details-content .event-details-mt-30 {
  margin-top: 30px;
}

.event-details-content h1,
.event-details-content h2,
.event-details-content h3,
.event-details-content h4,
.event-details-content h5,
.event-details-content h6 {
  margin-bottom: 15px;
}

.event-details-content h1 {
  font-size: 32px;
}

.event-details-content h2 {
  font-size: 29px;
}

.event-details-content h3 {
  font-size: 26px;
}

.event-details-content h4 {
  font-size: 23px;
}

.event-details-content h5 {
  font-size: 20px;
}

.event-details-content h6 {
  font-size: 17px;
}

.event-details-content a {
  color: var(--goldenColor);
}

.event-details-content a:hover {
  color: var(--titleColor);
  border-bottom: 1px solid var(--goldenColor);
}

.event-details-content ul,
.event-details-content ol {
  margin-bottom: 30px;
}

.event-details-content ul li,
.event-details-content ol li {
  margin-bottom: 10px;
}

.event-details-content ul li:last-child,
.event-details-content ol li:last-child {
  margin-bottom: 0;
}

.event-details-content p {
  margin-bottom: 15px;
}

.event-details-content .event-preview-img {
  margin-bottom: 25px;
}

.event-sidebar {
  margin-bottom: 35px;
  background-color: #F5F5F5;
  padding: 35px 23px;
}

.event-sidebar .title {
  font-size: 22px;
  color: var(--titleColor);
  margin-bottom: 25px;
  padding-bottom: 15px;
  position: relative;
  z-index: 1;
}

.event-sidebar .title::before {
  content: '';
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100px;
  height: 3px;
  background-color: var(--mainColor);
}

.event-sidebar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.event-sidebar ul li {
  display: block;
  color: var(--mainColor);
  margin-bottom: 10px;
}

.event-sidebar ul li:last-child {
  margin-bottom: 0;
}

.event-sidebar ul li span {
  color: var(--bodyColor);
  float: right;
}

.event-popular-post {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.event-popular-post .item {
  overflow: hidden;
  margin-bottom: 12px;
}

.event-popular-post .item:last-child {
  margin-bottom: 0;
}

.event-popular-post .item .thumb {
  float: left;
  overflow: hidden;
  position: relative;
  margin-right: 15px;
}

.event-popular-post .item .thumb .full-image {
  width: 100px;
  height: 115px;
  display: inline-block;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
  position: relative;
  background-color: var(--whiteColor);
}

.event-popular-post .item .info {
  overflow: hidden;
  padding: 4px 0;
}

.event-popular-post .item .info p {
  color: var(--bodyColor);
  margin-bottom: 0;
}

.event-popular-post .item .info .title-text {
  margin-bottom: 7px;
  line-height: 1.2;
  font-size: 20px;
  font-weight: 600;
  max-width: 300px;
}

.event-popular-post .item .info .title-text a {
  display: inline-block;
  color: var(--titleColor);
}

.event-popular-post .item .info .title-text a:hover {
  color: var(--mainColor);
}


.contact-info-card {
  padding: 40px;
  background-color: #F5F5F5;
  position: relative;
  z-index: 1;
  border-radius: 5px;
  margin-bottom: 30px;
  text-align: center;
}

.contact-info-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 0;
  height: 100%;
  background-color: var(--mainColor);
  opacity: 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.contact-info-card i {
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 24px;
  color: var(--whiteColor);
  background-color: var(--mainColor);
  text-align: center;
  display: inline-block;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 50%;
  margin-bottom: 20px;
}

.contact-info-card h3 {
  margin-bottom: 5px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.contact-info-card p {
  margin-bottom: 0;
  color: var(--bodyColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.contact-info-card p a {
  color: var(--bodyColor);
  display: block;
  margin-bottom: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.contact-info-card p a:hover {
  color: var(--whiteColor);
}

.contact-info-card:hover::before {
  opacity: 1;
  width: 100%;
}

.contact-info-card:hover i {
  background-color: var(--whiteColor);
  color: var(--mainColor);
}

.contact-info-card:hover h3 {
  color: var(--whiteColor);
}

.contact-info-card:hover p {
  color: var(--whiteColor);
}

.contact-info-card:hover p a {
  color: var(--whiteColor);
}

.contact-form {
  margin-bottom: 30px;
  padding: 0;
}

.contact-form .form-group {
  margin-bottom: 20px;
  position: relative;
}

.contact-form .form-group.checkbox-option {
  position: relative;
}

.contact-form .form-group.checkbox-option #chb2 {
  position: absolute;
  top: 6px;
  left: 0;
}

.contact-form .form-group.checkbox-option p {
  padding-left: 25px;
  font-size: 15px;
  color: var(--titleColor);
}

.contact-form .form-group.checkbox-option p a {
  color: var(--mainColor);
}

.contact-form .form-group.checkbox-option p a:hover {
  color: var(--titleColor);
}

.contact-form .form-group .form-control {
  color: #131841;
  border: solid 1px #E7E7E7;
  background-color: var(--whiteColor);
  padding: 10px 15px;
  width: 100%;
  border-radius: 6px;
  -webkit-text-fill-color: #131841;
  font-size: 15px;
  height: 48px;
}

.contact-form .form-group .form-control::-webkit-input-placeholder,
.contact-form .form-group .form-control:-ms-input-placeholder,
.contact-form .form-group .form-control::-ms-input-placeholder,
.contact-form .form-group .form-control::-webkit-input-placeholder {
  color: var(--bodyColor);
}

.contact-form .form-group .form-control::-webkit-input-placeholder,
.contact-form .form-group .form-control:-ms-input-placeholder,
.contact-form .form-group .form-control::-ms-input-placeholder,
.contact-form .form-group .form-control::placeholder {
  color: var(--bodyColor);
}

.contact-form .form-group .form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.contact-form .form-group textarea.form-control {
  height: auto;
  border-radius: 0;
}

.contact-form .agree-label {
  margin-bottom: 15px;
  position: relative;
}

.contact-form .agree-label #chb1 {
  position: absolute;
  top: 7px;
  left: 0;
}

.contact-form .agree-label label {
  color: var(--titleColor);
  margin-left: 25px;
  font-size: 13px;
}

.contact-form .agree-label label a {
  color: var(--mainColor);
}

.contact-form .agree-label label a:hover {
  color: var(--titleColor);
}

.contact-form .agree-label label a.forget {
  position: absolute;
  right: 0;
}

.contact-form .with-errors {
  float: left;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 0;
  color: #f00;
  font-weight: 400;
  display: block;
}

.contact-form .text-danger {
  font-size: 18px;
  margin-top: 15px;
}

.contact-form .default-btn {
  border: 0;
  outline: none;
}

.contact-map iframe {
  display: block;
  width: 100%;
  height: 590px;
}

.user-img {
  position: relative;
  margin-bottom: 30px;
}

.user-img img {
  -webkit-animation: border-transform 10s linear infinite alternate forwards;
  animation: border-transform 10s linear infinite alternate forwards;
}

.user-all-form .contact-form {
  z-index: 333;
}

.user-all-form .contact-form .user-title {
  font-size: clamp(26px, 2vw, 30px);
  padding: 0 0 4px;
  position: relative;
  z-index: 1;
  margin-bottom: 0;
}

.blog-card {
  position: relative;
  z-index: 1;
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  margin-bottom: 30px;
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.blog-card a {
  display: block;
}

.blog-card .content {
  padding: 27px 25px 30px;
}

.blog-card .content ul {
  list-style-type: none;
  margin: 0 0 12px;
  padding: 0;
}

.blog-card .content ul li {
  display: inline-block;
  margin-right: 30px;
  position: relative;
  color: var(--titleColor);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.blog-card .content ul li i {
  font-size: 18px;
  color: var(--mainColor);
  position: relative;
  top: 4px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.blog-card .content ul li:hover {
  color: var(--mainColor);
}

.blog-card .content ul li:hover i {
  color: var(--titleColor);
}

.blog-card .content ul li:hover a {
  color: var(--mainColor);
}

.blog-card .content ul li a {
  display: inline-block;
  color: var(--bodyColor);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.blog-card .content ul li::before {
  content: '';
  position: absolute;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  top: 10px;
  right: -20px;
  width: 1px;
  height: 15px;
  background-color: var(--bodyColor);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.blog-card .content ul li:last-child li {
  margin-right: 0;
}

.blog-card .content ul li:last-child::before {
  display: none;
}

.blog-card .content h3 {
  margin-bottom: 13px;
}

.blog-card .content h3 a {
  color: var(--titleColor);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.blog-card .content p {
  margin-bottom: 10px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.blog-card .content .read-btn {
  color: var(--titleColor);
  margin-bottom: 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  font-weight: 600;
}

.blog-card .content .read-btn:hover {
  letter-spacing: 0.25px;
}

.blog-card:hover {
  -webkit-transform: translateX(-5px);
  transform: translateX(-5px);
}

.blog-card:hover .content h3 a {
  color: var(--mainColor);
}

.blog-card:hover .content .read-btn {
  color: var(--mainColor);
}

.blog-card-bg {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.blog-card-bg .content {
  padding: 17px 0 0;
}

.blog-details-content h1,
.blog-details-content h2,
.blog-details-content h3,
.blog-details-content h4,
.blog-details-content h5,
.blog-details-content h6 {
  margin-bottom: 15px;
}

.blog-details-content h1 {
  font-size: 32px;
}

.blog-details-content h2 {
  font-size: 29px;
}

.blog-details-content h3 {
  font-size: 26px;
}

.blog-details-content h4 {
  font-size: 23px;
}

.blog-details-content h5 {
  font-size: 20px;
}

.blog-details-content h6 {
  font-size: 17px;
}

.blog-details-content a {
  color: var(--goldenColor);
}

.blog-details-content a:hover {
  color: var(--titleColor);
  border-bottom: 1px solid var(--goldenColor);
}

.blog-details-content ul,
.blog-details-content ol {
  margin-bottom: 30px;
}

.blog-details-content ul li,
.blog-details-content ol li {
  margin-bottom: 10px;
}

.blog-details-content ul li:last-child,
.blog-details-content ol li:last-child {
  margin-bottom: 0;
}

.blog-details-content p {
  margin-bottom: 15px;
}

.blog-details-content .blog-preview-slider {
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.blog-details-content .blog-preview-slider .owl-nav {
  margin-top: 0;
  overflow: hidden;
}

.blog-details-content .blog-preview-slider .owl-nav .owl-prev {
  position: absolute;
  z-index: 5;
  top: 47%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 45px;
  height: 45px;
  line-height: 53px !important;
  background-color: var(--whiteColor) !important;
  color: var(--bodyColor) !important;
  border-radius: 50%;
  text-align: center;
  font-size: 18px !important;
}

.blog-details-content .blog-preview-slider .owl-nav .owl-prev:hover {
  color: var(--mainColor) !important;
}

.blog-details-content .blog-preview-slider .owl-nav .owl-next {
  position: absolute;
  z-index: 5;
  top: 47%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  width: 45px;
  height: 45px;
  line-height: 53px !important;
  background-color: var(--whiteColor) !important;
  color: var(--bodyColor) !important;
  border-radius: 50%;
  text-align: center;
  font-size: 18px !important;
}

.blog-details-content .blog-preview-slider .owl-nav .owl-next:hover {
  color: var(--mainColor) !important;
}

.blog-details-content .blog-preview-img {
  margin-bottom: 25px;
  position: relative;
}

.blog-details-content .blog-preview-img .date {
  position: absolute;
  bottom: -12px;
  left: 25px;
  display: inline-block;
  text-align: center;
  padding: 8px 14px;
  font-size: 15px;
  background-color: var(--goldenColor);
  border-radius: 0;
  color: var(--whiteColor);
  font-weight: 600;
}

.blog-details-content .blog-preview-img-bg {
  background-image: url(../images/blog/blog-large-3.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 170px 0;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 30px;
  -webkit-transition: 0.9s;
  transition: 0.9s;
  overflow: hidden;
}

.blog-details-content .blog-preview-img-bg .play-btn {
  display: inline-block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
}

.blog-details-content .blog-preview-img-bg .play-btn i {
  display: inline-block;
  width: 90px;
  height: 90px;
  line-height: 90px;
  background-color: var(--whiteColor);
  color: var(--mainColor);
  font-size: 30px;
  border-radius: 50px;
  margin: 0 auto;
  padding-left: 5px;
  padding-top: 3px;
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-align: center;
  z-index: 1;
}

.blog-details-content .blog-preview-img-bg .play-btn i::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  -webkit-animation: ripple 2s infinite ease-in-out;
  animation: ripple 2s infinite ease-in-out;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.blog-details-content .blog-preview-img-bg .play-btn:hover i {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.blog-details-content .blog-preview-img-bg .play-btn:hover i::after {
  background-color: var(--mainColor);
  opacity: 0.3;
}

.blog-details-content .tag-list {
  list-style-type: none;
  margin: 0 0 5px;
  padding: 0;
}

.blog-details-content .tag-list li {
  display: inline-block;
  margin-right: 30px;
  position: relative;
  color: var(--titleColor);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.blog-details-content .tag-list li i {
  font-size: 18px;
  color: var(--mainColor);
  position: relative;
  top: 2px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.blog-details-content .tag-list li:hover {
  color: var(--mainColor);
}

.blog-details-content .tag-list li:hover i {
  color: var(--titleColor);
}

.blog-details-content .tag-list li:hover a {
  color: var(--mainColor);
}

.blog-details-content .tag-list li a {
  display: inline-block;
  color: var(--bodyColor);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.blog-details-content .tag-list li::before {
  content: '';
  position: absolute;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  top: 10px;
  right: -20px;
  width: 1px;
  height: 15px;
  background-color: var(--bodyColor);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.blog-details-content .tag-list li:last-child li {
  margin-right: 0;
}

.blog-details-content .tag-list li:last-child::before {
  display: none;
}

.blog-details-content .blockquote {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  margin-top: 30px;
  background-color: #f8f8f8;
  padding: 40px 30px 35px 60px;
  text-align: center;
}

.blog-details-content .blockquote i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 30px;
  line-height: 1;
  font-size: 45px;
  color: var(--mainColor);
}

.blog-details-content .blockquote p {
  max-width: 620px;
  font-size: 18px;
  color: var(--titleColor);
  font-weight: 600;
  margin: 0 auto;
}

.blog-details-content .article-share {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 15px;
  background: #F7F7F7;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.blog-details-content .article-share .article-tag ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.blog-details-content .article-share .article-tag ul li {
  color: var(--titleColor);
  margin-right: 5px;
  display: inline-block;
  margin-bottom: 0;
}

.blog-details-content .article-share .article-tag ul li.title {
  color: var(--mainColor);
  position: relative;
  top: 3px;
}

.blog-details-content .article-share .article-tag ul li a {
  border: none;
  color: var(--bodyColor);
}

.blog-details-content .article-share .article-tag ul li a:hover {
  color: var(--mainColor);
}

.blog-details-content .article-share .article-social-icon {
  float: right;
}

.blog-details-content .article-share .article-social-icon .social-icon {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.blog-details-content .article-share .article-social-icon .social-icon li {
  font-size: 18px;
  color: var(--bodyColor);
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 0;
}

.blog-details-content .article-share .article-social-icon .social-icon li.title {
  font-weight: 500;
}

.blog-details-content .article-share .article-social-icon .social-icon li:last-child {
  margin-right: 0;
}

.blog-details-content .article-share .article-social-icon .social-icon li a i {
  color: var(--bodyColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
  top: 2px;
}

.blog-details-content .article-share .article-social-icon .social-icon li a:hover {
  border: none;
}

.blog-details-content .article-share .article-social-icon .social-icon li a:hover i {
  color: var(--mainColor);
}

.blog-details-content .comments-form {
  margin-bottom: 30px;
}

.blog-details-content .comments-form .contact-form {
  max-width: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.blog-details-content .comments-form .contact-form h4 {
  margin-bottom: 15px;
}

.blog-details-content .comments-form .contact-form p {
  margin-bottom: 25px;
}

.side-bar-widget {
  margin-bottom: 35px;
  background-color: #F5F5F5;
  padding: 35px 23px;
}

.side-bar-widget .title {
  font-size: 22px;
  color: var(--titleColor);
  margin-bottom: 25px;
  border-bottom: 1px solid var(--mainColor);
  padding-bottom: 15px;
}

.side-bar-widget .title-tag {
  margin-bottom: 20px;
}

.side-bar-widget .search-form {
  position: relative;
  margin: 0 auto;
  border-radius: 0;
  border: none;
  width: 100%;
  background-color: var(--whiteColor);
}

.side-bar-widget .search-form .form-control {
  background-color: var(--whiteColor);
  border-radius: 0;
  border: none;
  height: 50px;
  line-height: 50px;
  margin: 0;
  border: 0;
  padding: 0 15px;
  color: var(--bodyColor);
}

.side-bar-widget .search-form .form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
}

.side-bar-widget .search-form button {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  border-radius: 0;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  height: 50px;
  line-height: 53px;
  font-size: 22px;
  padding: 0 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.side-bar-widget .search-form button:hover {
  background: var(--titleColor);
}

.side-bar-widget .side-bar-categories ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.side-bar-widget .side-bar-categories ul li {
  position: relative;
  margin-bottom: 10px;
}

.side-bar-widget .side-bar-categories ul li:last-child {
  margin-bottom: 0;
}

.side-bar-widget .side-bar-categories ul li a {
  display: inline-block;
  color: var(--bodyColor);
  position: relative;
  z-index: 1;
  padding-left: 20px;
}

.side-bar-widget .side-bar-categories ul li a::before {
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 8px;
  height: 8px;
  background-color: var(--mainColor);
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.side-bar-widget .side-bar-categories ul li:hover a {
  color: var(--mainColor);
  letter-spacing: 0.25px;
}

.side-bar-widget .side-bar-categories ul li:hover a::before {
  background-color: var(--redColor);
}

.side-bar-widget .widget-popular-post {
  position: relative;
  overflow: hidden;
}

.side-bar-widget .widget-popular-post .item {
  overflow: hidden;
  margin-bottom: 12px;
}

.side-bar-widget .widget-popular-post .item:last-child {
  margin-bottom: 0;
}

.side-bar-widget .widget-popular-post .item .thumb {
  float: left;
  overflow: hidden;
  position: relative;
  margin-right: 15px;
}

.side-bar-widget .widget-popular-post .item .thumb .full-image {
  width: 85px;
  height: 85px;
  display: inline-block;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
  position: relative;
  background-color: var(--whiteColor);
}

.side-bar-widget .widget-popular-post .item .thumb .full-image.bg1 {
  background-image: url(../images/blog/blog-img1.jpg);
}

.side-bar-widget .widget-popular-post .item .thumb .full-image.bg2 {
  background-image: url(../images/blog/blog-img2.jpg);
}

.side-bar-widget .widget-popular-post .item .thumb .full-image.bg3 {
  background-image: url(../images/blog/blog-img3.jpg);
}

.side-bar-widget .widget-popular-post .item .info {
  overflow: hidden;
  padding: 4px 0;
}

.side-bar-widget .widget-popular-post .item .info p {
  color: var(--bodyColor);
  font-size: 14px;
  margin-bottom: 7px;
  font-weight: 600;
}

.side-bar-widget .widget-popular-post .item .info .title-text {
  margin-bottom: 0;
  line-height: 1.2;
  font-size: 18px;
  font-weight: 600;
  max-width: 300px;
}

.side-bar-widget .widget-popular-post .item .info .title-text a {
  display: inline-block;
  color: var(--titleColor);
}

.side-bar-widget .widget-popular-post .item .info .title-text a:hover {
  color: var(--mainColor);
}

.side-bar-widget .side-bar-widget-tag {
  list-style: none;
  margin: 0;
  padding: 0;
}

.side-bar-widget .side-bar-widget-tag li {
  display: inline-block;
  margin: 5px;
}

.side-bar-widget .side-bar-widget-tag li a {
  padding: 8px 23px;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  color: var(--bodyColor);
  font-size: 14px;
  background-color: var(--whiteColor);
  transition: 0.7s;
  font-weight: 500;
}

.side-bar-widget .side-bar-widget-tag li:hover a {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.author-profile {
  background-color: #F5F5F5;
  padding: 35px 30px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 35px;
}

.author-profile h3 {
  margin-bottom: 10px;
  font-size: 22px;
}

.author-profile img {
  border-radius: 50%;
  margin-bottom: 15px;
  max-width: 100px;
}

.author-profile p {
  margin-bottom: 17px;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}

.author-profile .profile-social-link {
  list-style: none;
  margin: 0;
  padding: 0;
}

.author-profile .profile-social-link li {
  display: inline-block;
  margin-right: 5px;
}

.author-profile .profile-social-link li a i {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 15px;
  text-align: center;
  color: var(--whiteColor);
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 50px;
  display: inline-block;
}

.author-profile .profile-social-link li a:hover i {
  background-color: var(--titleColor);
}

.categories-title {
  margin-bottom: 30px;
}

.categories-title h3 {
  font-size: 26px;
  color: var(--titleColor);
}

.categories-title h3 span {
  color: var(--mainColor);
  font-size: 22px;
}


.register-area {
  position: relative;
  z-index: 1;
}

.register-area::before {
  content: '';
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 50%;
  background-color: #F5F5F5;
}

.register-form {
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  border-radius: 10px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
}

.register-form .top-header {
  background-color: var(--mainColor);
  padding: 40px 35px 37px;
  border-radius: 10px 10px 0 0;
}

.register-form .top-header h3 {
  color: var(--whiteColor);
  font-size: 35px;
  margin-bottom: 5px;
}

.register-form .top-header span {
  color: var(--whiteColor);
  margin-bottom: 0;
}

.register-form .register-form-max {
  padding: 35px 35px 37px;
}

.register-form .form-group {
  margin-bottom: 20px;
  position: relative;
}

.register-form .form-group .form-control {
  height: 55px;
  color: var(--bodyColor);
  border: 1px solid #DDDDDD;
  background-color: var(--whiteColor);
  padding: 15px 25px;
  width: 100%;
  border-radius: 50px;
}

.register-form .form-group .form-control::-webkit-input-placeholder,
.register-form .form-group .form-control:-ms-input-placeholder,
.register-form .form-group .form-control::-ms-input-placeholder,
.register-form .form-group .form-control::-webkit-input-placeholder {
  color: var(--bodyColor);
}

.register-form .form-group .form-control::-webkit-input-placeholder,
.register-form .form-group .form-control:-ms-input-placeholder,
.register-form .form-group .form-control::-ms-input-placeholder,
.register-form .form-group .form-control::placeholder {
  color: var(--bodyColor);
}

.register-form .form-group .form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.register-form .default-btn {
  border: 0;
  outline: none;
  width: 100%;
}

.play-btn-area-two {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 247px 0;
  text-align: center;
  border-radius: 10px;
}

.play-btn-area-two .play-btn {
  display: inline-block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
}

.play-btn-area-two .play-btn i {
  display: inline-block;
  width: 90px;
  height: 90px;
  line-height: 95px;
  background-color: var(--whiteColor);
  color: var(--mainColor);
  font-size: 30px;
  border-radius: 50px;
  margin: 0 auto;
  padding-left: 5px;
  padding-top: 3px;
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-align: center;
  z-index: 1;
}

.play-btn-area-two .play-btn i::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  -webkit-animation: ripple 2s infinite ease-in-out;
  animation: ripple 2s infinite ease-in-out;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.play-btn-area-two .play-btn:hover i {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.play-btn-area-two .play-btn:hover i::after {
  background-color: var(--mainColor);
  opacity: 0.3;
}

.newsletter-area .newsletter-form {
  position: relative;
}

.newsletter-area .newsletter-form .form-control {
  background-color: var(--whiteColor);
  border-radius: 50px;
  height: 70px;
  line-height: 70px;
  margin: 0;
  border: none;
  padding: 0 25px;
  color: var(--bodyColor);
}

.newsletter-area .newsletter-form .form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--titleColor);
}

.newsletter-area .newsletter-form .subscribe-btn {
  position: absolute;
  display: inline-block;
  right: 10px;
  top: 10px;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  height: 50px;
  line-height: 52px;
  padding: 0 32px;
  border: 0;
  border-radius: 50px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-align: center;
}

.newsletter-area .newsletter-form .subscribe-btn:hover {
  background-color: var(--titleColor);
}

.newsletter-area .newsletter-form .validation-danger {
  font-size: 18px;
  margin-top: 5px;
  color: red;
}


.footer-contact-area {
  background-color: var(--mainColor);
}

.footer-contact-area .section-title h2 {
  color: var(--whiteColor);
}

.footer-contact-area .default-btn {
  background-color: var(--whiteColor);
  color: var(--mainColor);
}

.footer-contact-area .default-btn:hover {
  color: var(--whiteColor);
}

.footer-area {
  background-color: #004766;
}

.footer-widget {
  margin-bottom: 30px;
}

.footer-widget .footer-logo {
  margin-bottom: 20px;
}

.footer-widget h3 {
  margin-top: 0;
  font-size: 24px;
  margin-bottom: 30px;
  color: var(--whiteColor);
  display: block;
  position: relative;
  z-index: 1;
  padding-bottom: 10px;
}

.footer-widget h3::before {
  content: '';
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  width: 70px;
  height: 3px;
  background-color: var(--whiteColor);
  opacity: 0.2;
}

.footer-widget h3::after {
  content: '';
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  width: 35px;
  height: 3px;
  background-color: var(--mainColor);
}

.footer-widget p {
  margin-bottom: 25px;
  color: var(--whiteColor);
}

.footer-widget .social-link {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer-widget .social-link li {
  display: inline-block;
  margin-right: 10px;
}

.footer-widget .social-link li.social-title {
  font-size: 18px;
  color: var(--whiteColor);
}

.footer-widget .social-link li:last-child {
  margin-right: 0;
}

.footer-widget .social-link li a {
  display: inline-block;
}

.footer-widget .social-link li a i {
  font-size: 15px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 50px;
  color: var(--mainColor);
  background-color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: inline-block;
}

.footer-widget .social-link li a:hover i {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.footer-widget .footer-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-widget .footer-list li {
  display: block;
  margin-bottom: 15px;
  padding-left: 25px;
  position: relative;
}

.footer-widget .footer-list li::before {
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 8px;
  height: 8px;
  background-color: var(--whiteColor);
}

.footer-widget .footer-list li:last-child {
  margin-bottom: 0;
}

.footer-widget .footer-list li a {
  color: var(--whiteColor);
}

.footer-widget .footer-list li a:hover {
  color: var(--bodyColor);
  letter-spacing: 0.25px;
}

.footer-widget .footer-list li:hover::before {
  color: var(--whiteColor);
}

.footer-widget .footer-list li:hover a {
  color: var(--whiteColor);
  letter-spacing: 0.25px;
}

.footer-widget .footer-contact {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer-widget .footer-contact li {
  display: block;
  padding-left: 35px;
  position: relative;
  margin-bottom: 15px;
}

.footer-widget .footer-contact li:last-child {
  margin-bottom: 0;
}

.footer-widget .footer-contact li i {
  font-size: 18px;
  color: var(--whiteColor);
  position: absolute;
  top: 5px;
  left: 0;
  line-height: 1;
}

.footer-widget .footer-contact li .content h4 {
  font-size: var(--fontSize);
  color: var(--whiteColor);
  margin-bottom: 5px;
}

.footer-widget .footer-contact li .content span {
  color: var(--whiteColor);
  margin-bottom: 0;
}

.footer-widget .footer-contact li .content span a {
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.footer-widget .footer-contact li:hover .content span a {
  color: var(--mainColor);
}

.copyright-area {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 15px 0;
}

.copy-right-text p {
  color: var(--whiteColor);
  margin-bottom: 0;
}

.copy-right-text p b {
  color: var(--whiteColor);
  font-weight: 500;
}

.copy-right-text p a {
  color: var(--mainColor);
  font-weight: 500;
}

.copy-right-text p a:hover {
  color: var(--whiteColor);
}

.single-content {
  margin-bottom: 30px;
}

.single-content h1,
.single-content h2,
.single-content h3,
.single-content h4,
.single-content h5,
.single-content h6 {
  margin-bottom: 15px;
}

.single-content h1 {
  font-size: 32px;
}

.single-content h2 {
  font-size: 29px;
}

.single-content h3 {
  font-size: 26px;
}

.single-content h4 {
  font-size: 23px;
}

.single-content h5 {
  font-size: 20px;
}

.single-content h6 {
  font-size: 17px;
}

.single-content a {
  color: var(--mainColor);
}

.single-content a:hover {
  color: var(--titleColor);
  border-bottom: 1px solid var(--mainColor);
}

.single-content ul,
.single-content ol {
  margin-bottom: 30px;
}

.single-content ul li,
.single-content ol li {
  margin-bottom: 10px;
}

.single-content ul li:last-child,
.single-content ol li:last-child {
  margin-bottom: 0;
}

.single-content p {
  margin-bottom: 15px;
}

.single-content p:last-child {
  margin-bottom: 0;
}


.error-area .error-content {
  text-align: center;
  position: relative;
}

.error-area .error-content h1 {
  font-size: 260px;
  line-height: .8;
  font-weight: 700;
  color: var(--titleColor);
}

.error-area .error-content h1 span {
  color: var(--mainColor);
}

.error-area .error-content h3 {
  margin: 35px 0 0;
  position: relative;
  color: var(--titleColor);
  font-size: 35px;
}

.error-area .error-content p {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 18px;
  max-width: 520px;
  margin-left: auto;
  margin-right: auto;
}

.coming-soon-area .coming-soon-content {
  text-align: center;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  padding: 50px 30px;
  background-color: var(--titleColor);
  border: 3px solid var(--mainColor);
}

.coming-soon-area .coming-soon-content h1 {
  margin-bottom: 0;
  color: var(--whiteColor);
  font-size: 50px;
}

.coming-soon-area .coming-soon-content p {
  font-size: 16px;
  max-width: 600px;
  margin-top: 15px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  color: var(--whiteColor);
}

.coming-soon-area .coming-soon-content #timer {
  margin-top: 40px;
}

.coming-soon-area .coming-soon-content #timer div {
  display: inline-block;
  color: var(--whiteColor);
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 10px;
  font-size: 28px;
  font-weight: 600;
  padding: 4px;
  background-color: transparent;
  border-radius: 5px;
  border: 2px solid var(--whiteColor);
}

.coming-soon-area .coming-soon-content #timer div span {
  display: block;
  text-transform: capitalize;
  margin-top: -7px;
  font-size: 16px;
  font-weight: normal;
  color: var(--whiteColor);
}

.coming-soon-area .coming-soon-content #timer div:last-child {
  margin-right: 0;
}

.coming-soon-area .coming-soon-content #timer div:last-child::before {
  display: none;
}

.coming-soon-area .coming-soon-content #timer div:first-child {
  margin-left: 0;
}

.coming-soon-area .coming-soon-content #timer div::before {
  content: "";
  position: absolute;
  right: -50px;
  top: -10px;
  font-size: 70px;
  color: #ffffff;
}

.coming-soon-area .coming-soon-content .newsletter-form {
  position: relative;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}

.coming-soon-area .coming-soon-content .newsletter-form .input-newsletter {
  display: block;
  width: 100%;
  height: 60px;
  border: none;
  background-color: var(--whiteColor);
  padding-left: 15px;
  color: var(--whiteColor);
  outline: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 0;
  border: 1px solid #cccccc;
  color: #5d5d5d;
}

.coming-soon-area .coming-soon-content .newsletter-form .input-newsletter:focus {
  border-color: var(--mainColor);
}

.coming-soon-area .coming-soon-content .newsletter-form .default-btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 60px;
  padding: 0 30px;
  text-transform: uppercase;
  outline: 0;
  cursor: pointer;
}

.coming-soon-area .coming-soon-content .newsletter-form .default-btn::before {
  border-radius: 0;
  background-color: var(--titleColor);
}

.coming-soon-area .coming-soon-content .newsletter-form .default-btn:hover {
  color: #ffffff;
  background-color: #190f3c;
}

.coming-soon-area #validator-newsletter {
  text-align: left;
  color: #dc3545 !important;
}


.go-top {
  position: fixed;
  top: 50%;
  right: 3%;
  opacity: 0;
  cursor: pointer;
  text-decoration: none;
  color: var(--whiteColor);
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  background: var(--mainColor);
  border-radius: 0;
  width: 45px;
  height: 45px;
  line-height: 43px;
  z-index: 100;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.go-top i {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  vertical-align: middle;
}

.go-top:hover {
  background-color: var(--titleColor);
}

.go-top:hover i {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.go-top.active {
  top: 95%;
  -webkit-transform: translateY(-95%);
  transform: translateY(-95%);
  opacity: 1;
  visibility: visible;
}

.pagination-area {
  margin-top: 10px;
  margin-bottom: 30px;
}

.pagination-area .page-numbers {
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: var(--bodyColor);
  background-color: transparent;
  text-align: center;
  display: inline-block;
  position: relative;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 50px;
  border: 1px solid var(--mainColor);
}

.pagination-area .page-numbers:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.pagination-area .page-numbers i {
  position: relative;
  line-height: normal;
  top: 2px;
}

.pagination-area .page-numbers.current {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

#preloader-area {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 60px;
  width: 60px;
}

#preloader-area .spinner {
  position: absolute;
  z-index: 9999;
  width: 6px;
  height: 90px;
  margin-top: -45px;
  border-radius: 10px;
  background-color: var(--whiteColor);
  -webkit-animation: rotate40deg 0.8s infinite;
  animation: rotate40deg 0.8s infinite;
  animation-direction: alternate-reverse;
}

#preloader-area .spinner:nth-child(1) {
  margin-left: 0px;
}

#preloader-area .spinner:nth-child(2) {
  margin-left: -14px;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

#preloader-area .spinner:nth-child(3) {
  margin-left: -28px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

#preloader-area .spinner:nth-child(4) {
  margin-left: -42px;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

#preloader-area .spinner:nth-child(5) {
  margin-left: -56px;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

#preloader-area .spinner:nth-child(6) {
  margin-left: -70px;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

#preloader-area .spinner:nth-child(7) {
  margin-left: -84px;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

#preloader-area .spinner:nth-child(8) {
  margin-left: -98px;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

#preloader .preloader-section {
  position: fixed;
  top: 0;
  width: 51%;
  height: 100%;
  background-color: var(--mainColor);
  z-index: 999;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

#preloader .preloader-section.preloader-left {
  left: 0;
}

#preloader .preloader-section.preloader-right {
  right: 0;
}

.loaded #preloader-area {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.loaded #preloader .preloader-section.preloader-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all 0.8s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.8s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #preloader .preloader-section.preloader-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: all 0.8s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.8s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #preloader {
  visibility: hidden;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: all 0.3s 1s ease-out;
  transition: all 0.3s 1s ease-out;
}

@-webkit-keyframes rotate40deg {
  0% {
    height: 5px;
    margin-top: 0;
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg);
  }

  100% {
    height: 90px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes rotate40deg {
  0% {
    height: 5px;
    margin-top: 0;
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg);
  }

  100% {
    height: 90px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  75% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  75% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 0;
  }
}

@-webkit-keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes movebounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes movebounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes rotate-in {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }

  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }

  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

@keyframes rotate-in {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }

  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }

  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

@-webkit-keyframes rotated360 {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }

  100% {
    -webkit-transform: rotateZ(-360deg);
    transform: rotateZ(-360deg);
  }
}

@keyframes rotated360 {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }

  100% {
    -webkit-transform: rotateZ(-360deg);
    transform: rotateZ(-360deg);
  }
}

@-webkit-keyframes border-transform {

  0%,
  100% {
    border-radius: 60% 40% 56% 33% / 73% 82% 18% 27%;
  }

  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }

  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }

  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }

  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }

  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }

  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

@keyframes border-transform {

  0%,
  100% {
    border-radius: 60% 40% 56% 33% / 73% 82% 18% 27%;
  }

  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }

  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }

  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }

  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }

  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }

  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

.login-signup-bg {
  background: #F3F8FF;
}


.user-all-form.userlogin {
  background-color: var(--whiteColor);
  padding: 20px 35px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  border-radius: 0;
  display: flex;
  align-items: center;
}

#contactForms .form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
  opacity: 9;
  transform: scale(0.9) translateY(-1.8rem) translateX(1rem);
  background: #fff;
  left: -15px !important;
  top: 12px !important;
}

#contactForms .form-floating>label {
  top: 7px;
  left: 1px;
  padding: 5px 1rem;
  background: #fff;
  height: 32px;
  font-size: 14px;
}



.login_bottom {
  position: absolute;
  bottom: 0;
  z-index: 0;
}


.otp-input-fields {
  margin: auto;
  background-color: white;
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 0 0 20px;
}

.otp-input-fields input {
  height: 50px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #E7E7E7;
  text-align: center;
  outline: none;
  font-size: 16px;
  padding: auto;
  text-align: center;
  width: 13% !important;
}

.otp-input-fields div {
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.otp-input-fields input::-webkit-outer-spin-button,
.otp-input-fields input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-input-fields input[type=number] {
  -moz-appearance: textfield;
}

.otp-input-fields input:focus {
  border-width: 2px;
  border-color: #287a1a;
  font-size: 20px;
}










.iti__country {
  padding: 5px 10px;
  outline: none;
  font-size: 13px;
}

.iti__country-list {
  width: 250px;
}

.tabs_signup .nav-item .nav-link {
  background: #fff;
  color: #333;
  font-size: 15px;
  border-radius: 2px;
  font-weight: 600;
  padding: 7px 11px;
  border: solid 1px #0d4868;
  border-style: dashed; cursor: pointer;
}

.tabs_signup .nav-item .nav-link.active,
.tabs_signup .nav-item .nav-link:hover {
  background: #0d4868;
  border-color: #0d4868;
  color: #fff;
}



.want-to-Hire {
  width: 100%;
  background-image: linear-gradient(to right bottom, #e8ecff, #e4f1ff, #e4f5fe, #e8f9fb, #eefbf9);
  padding: 70px;
  text-align: center;
  display: grid;
  justify-content: center;
  gap: 70px;
  height: 100vh;
  align-items: center;
}


.user_login {
  background: #293060;
  display: flex;
  height: 100%;
  padding: 0 0;
  align-items: center;
}

.logo_login {
  height: 170px;
}

.user_login img {
  height: 100%;
  object-fit: cover;
}

.navbar-brand img {
  height: 80px;
  border: solid #fff;
}


.firstbanner img,
.firstbanner2 img {
  width: 350px;
}

.firstbanner {
  position: absolute;
  bottom: 0;
  right: 0;
}


.firstbanner2 {
  position: absolute;
  bottom: 0;
  left: 0;
}

.bg_nav-slid {
  background-repeat: no-repeat;
  background-size: cover;
}



.video_players {
  margin: 0 20px 10px 0px;
  overflow: hidden;
}

.video_players video {
  border-radius: 10px !important;

}

.video_players {
  margin: 0 20px 0 0;
  overflow: hidden;
}

.video_players video {
  border-radius: 20px !important;
  margin: 0 0 20px 0 !important;
  padding: 0px !important;
  overflow: hidden !important;
  height: 100%;
}



.career-se .featured-item-one img {
  height: 270px;
  width: 100%;
}


.featured-item-one.competsec img {
  height: 559px;
}



.career-se .featured-item-one,
.featured-item-one.competsec {
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.career-se .featured-item-one:hover,
.featured-item-one.competsec:hover {
  -webkit-transform: translateX(-5px);
  transform: translateX(-8px);
}

.hero-slider-area .carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 500px;
}
.video_home {
  position: absolute;
  top: 80px;
  z-index: -1;
  width: 100%;
  height: 620px;
}
.video_home:after {
  content: '';
  background: linear-gradient(#5f93a3b5, #055468);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.login__signup a {
  padding: 9px 0;
  width: 100px;
  text-align: center;
  border: solid 1px var(--mainColor);
}

.defaultsignin {
  color: #fff;
  border-color: #ffffff00 !important;
}

.navbar-area.ledu-area.is-sticky .defaultsignin {
  color: var(--mainColor);
  border-color: #ffffff00 !important;
}


.login__signup a:hover {
  color: var(--whiteColor);
  border-color: var(--titleColor) !important;
  background: var(--titleColor) !important;
}


.mobilelogin {
  position: absolute;
  top: -46px;
  z-index: 999;
  left: 90px;
}

.mobilelogin a {
  color: #000;
  padding: 8px 10px;
}

#heading {
  text-transform: uppercase;
  color: var(--mainColor);
  font-weight: normal
}

#msform {
  text-align: center;
  position: relative;
  margin-top: 20px
}

#msform fieldset {
  background: var(--whiteColor);
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
  position: relative
}

.form-card {
  text-align: left
}

#msform fieldset:not(:first-of-type) {
  display: none
}

#msform input,
#msform select,
#msform textarea {
  padding: 8px 15px 8px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 18px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  color: #000000;
  background-color: #fff;
  font-size: 16px;
  letter-spacing: 0;
  height: 45px;
  font-weight: 500;
}

#msform input:focus,
#msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid var(--mainColor);
  outline-width: 0
}

#msform .action-button {
  width: 100px;
  background: var(--mainColor);
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 0px 10px 5px;
  float: right
}

#msform .action-button:hover,
#msform .action-button:focus {
  background-color: var(--mainColor)
}

#msform .action-button-previous {
  width: 100px;
  background: #616161;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px 10px 0px;
  float: right
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
  background-color: #000000
}

.card {
  z-index: 0;
  border: none;
  position: relative
}

.fs-title {
  font-size: 25px;
  color: var(--mainColor);
  margin-bottom: 15px;
  font-weight: normal;
  text-align: left
}

.purple-text {
  color: var(--mainColor);
  font-weight: normal
}

.steps {
  font-size: 18px;
  color: gray;
  margin-bottom: 10px;
  font-weight: normal;
  text-align: right
}

.fieldlabels {
  color: gray;
  text-align: left
}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: var(--whiteColor)
}

#progressbar .active {
  color: var(--mainColor)
}

#progressbar li {
  list-style-type: none;
  font-size: 15px;
  width: 25%;
  float: left;
  position: relative;
  font-weight: 400
}

#progressbar #account:before {
  font-family: 'remixicon' !important;
  content: "\f10b"
}

#progressbar #personal:before {
  font-family: 'remixicon' !important;
  content: "\f007"
}

#progressbar #payment:before {
  font-family: 'remixicon' !important;
  content: "\f030"
}

#progressbar #confirm:before {
  font-family: 'remixicon' !important;
  content: "\f00c"
}

#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 25px;
  color: var(--whiteColor);
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px
}

#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: var(--mainColor)
}


.fit-image {
  width: 100%;
  object-fit: cover
}

.stepbg {
  background-image: url(http://localhost:3000/static/media/stepbg.5292b052480d0ca89cd4.png);
  background-size: cover;
  background-color: #f6f8fc;
  background-attachment: fixed;
  width: 100% !important;
  height: 100%;
  min-height:100vh;
}

.remove.bg-danger.btnclose {
  color: #fff;
  padding: 2px 20px 0;
  font-size: 24px;
}

.iti.iti--allow-dropdown.iti--separate-dial-code {
  width: 100%;
}

.sf-steps {
  box-shadow: 0 0px 7px 0px #00000026;
  background: #fff;
}


.joblisttitle {
  display: flex;
  align-items: center;
  padding: 16px 25px;
  border-bottom: solid 2px #eee;
  gap: 20px;
  cursor: pointer;
}

aside.list-icons h5 {
  margin: 0px;
  font-size: 16px;
  font-weight: 500;
}

.joblisttitle.sf-active aside.list-icons h5 {
  font-weight: 700;
  color: #003f5b;
}

.joblisttitle span {
  background: #dddddd;
  padding: 0;
  border-radius: 100px;
  color: #000000;
  width: 33px;
  height: 33px;
  font-size: 16px;
  line-height: 33px;
}

.joblisttitle.sf-active span {
  background: #003f5b;
  color: #fff;
}

.professional-sp {
  text-align: left;
}


ul.sf-content {
  padding: 20px 20px 60px;
}

.flexdr {
  width: 100%;
  box-shadow: 0 0px 7px 0px #00000026;
  background: #fff;
  padding-bottom: 30px;
}

.sf-steps-navigation {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px 20px;
  background: #dfdfdf;
  left: 0;
}



.navbar-category .category-list-dropdown .dropdown-menu.profile_dropdown {
  left: auto !important;
  right: 0;
  width: 100px !important;
}


.logoutbtn {
  background: var(--mainColor) !important;
  padding-top: 7px !important;
  color: var(--whiteColor) !important;
}




.course-sidebar ul {
  list-style-type: none;
  overflow: hidden;
  padding: 0;
}

ul.scourse_list .cside-label {
  margin: 0px;
  font-weight: 400;
}

ul.scourse_list .cside-value {
  margin: 0px;
}

.scourse_meta {
  margin-top: 30px;
  margin-bottom: 30px;
}

.scourse_meta .smeta {
  padding-right: 25px;
  margin-right: 25px;
  display: inline-block;
  position: relative;
}

.scourse_meta img {
  width: 70px;
  border-radius: 50%;
  margin-right: 15px;
  float: left;
}

.scourse_meta .smeta_text {
  display: inline-block;
}

.scourse_meta span {
  font-size: 16px;
}

.scourse_meta p {
  color: #222;
  font-size: 18px;
  font-weight: bold;
}

.scourse_meta .smeta::after {
  width: 1px;
  height: 52px;
  background-color: #C9C8C8;
  content: '';
  position: absolute;
  right: 0;
  top: 5px;
}



.course-sidebar h3 {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.course-sidebar ul {
  list-style-type: none;
  overflow: hidden;
}

.course-sidebar .scourse_list li {
  width: 100%;
  float: left;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.cside-label {
  float: left;
}

.cside-value {
  float: right;
  font-weight: 600;
  color: #222;
}


.cd_price {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  color: #222;
  margin-bottom: 25px;
  margin-top: 15px;
}


span.cside-label i {
  font-size: 22px;
  margin: -6px 10px 0 0;
  color: #003f5b;
  float: left;
}




ul.list-group.profilemenu li a {
  font-weight: 600;
  color: #999;
  padding: 14px 40px;
}

ul.list-group.profilemenu li a.active {
  color: var(--mainColor);
}

ul.list-group.profilemenu {
  text-align: left;
}

ul.list-group.profilemenu li {
  padding: 0;
  float: left;
}

.logoutbtn2 {
  background: var(--mainColor);
  width: 100%;
  color: var(--whiteColor) !important;
}

ul.list-group.profilemenu a:hover {
  color: #003f5b;
  transform: translateY(3px);
}

.form-group.info__profile p {
  margin: 0 0 3px;
}

.form-group.info__profile {
  margin: 15px 0 0;
}

.personal_user_info {
  text-align: left;
}


ul.list-group.profilemenu li a i {
  font-size: 18px;
  margin: -2px 0 0 0;
  float: left;
  margin-right: 16px;
}

.logoutbtn2 i {
  font-size: 18px;
  margin: -2px 0 0 0;
  float: left;
  color: var(--whiteColor) !important;
  margin-right: 16px;
}




.navbar-area.ledu-area.otherpages-menu .navbar-brand img {
  height: 80px !important;
}


.navbar-area.ledu-area.otherpages-menu .nav-item a {
  color: #333;
}


.navbar-area.ledu-area.otherpages-menu .defaultsignin {
  color: #333 !important;
  border-color: var(--mainColor) !important;
}

.navbar-area.ledu-area.otherpages-menu .defaultsignin:hover {
  background: #eee !important;
  color: #333 !important;
  border-color: #eee !important;
}



span.live_user {
  position: absolute;
  background: green;
  color: #fff;
  padding: 6px 20px;
  border-radius: 0 0 100px 0px;
  font-size: 14px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  text-align: center;
}

.menter_pro {
  width: 170px;
  overflow: hidden;
  border-radius: 100px 100px 100px 0px;
}


img.rounded_01top {
  border-radius: 100px 100px 100px 0px;
  margin: 0;
  height: 170px;
  width: 170px;
}


.businessmen {
  display: flex;
  gap: 20px;
  align-items: center;
}

.businessmen div i {
  float: left;
  margin: 0 8px 0 0;
  padding: 1px 9px;
  border-radius: 5px;
  border: solid 1px #d9d9d9;
  font-weight: 500;
}


.educationicon img {
  border: solid 1px #eee;
  padding: 10px;
  width: 70px;
  border-radius: 8px;
  height: 70px;
}

.skillstopic_education {
  display: flex;
  gap: 14px;
}

.skillstopic_education h6 {
  margin: 0px;
}


.skillstopic div {
  position: relative;
  box-sizing: border-box;
  height: 40px;
  color: var(--bodyColor);
  border: solid 1px #eee;
  border-radius: 64px;
  padding: 0 16px;
  font-size: 14px;
  line-height: 40px;
}

.skillstopic {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  height: max-content;
}

.skillstopic div:hover {
  background: #eee;
}

.topbatch {
  position: absolute;
  right: 0;
  top: 0;
  background: #fff0da;
  padding: 7px 20px;
  border-radius: 0;
  font-weight: bold;
  color: #6c320f;
}

.sticky2 {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #fff9f0;
  padding: 3rem 20px;
}




.is-sticky {
  position: fixed;
}

.is-sunk {
  position: sticky;
  top: 200px;
}

.right-content.job__qui {
  padding: 0;
  z-index: -1;
}



.right-content.job__qui img {
  height: 200px;
}

.right-content.job__qui {
  padding: 0;
  z-index: -1;
  top: -40px;
  right: 30px;
}

.applicantsuser {
  padding: 0 !important;
}

.applicantsuser img {
  position: relative !important;
  border-radius: 50%;
  top: 0 !important;
  left: 0 !important;
}

.left-content.applicantsuser .content {
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: solid 1px #ccc;
  padding: 7px 0px;
  margin: 0;
}

.left-content.applicantsuser {
  box-shadow: 0 0 9px 1px #00000026;
  padding: 17px 20px 0 !important;
  border-radius: 8px;
  bottom: 0;
  left: 20px;
}




.recruiterbgme {
  background: #0727380d;
}

.hiring__processection {
  border-radius: 20px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.hireone {
  margin: 15px 0 0;
}

.hireone img {
  border-radius: 10px;
}

.linkedbtn {
  display: flex;
  font-size: 16px;
  padding: 6px 20px 3px;
  font-weight: bold;
  color: #004766;
  border: solid 1px #004766;
  border-radius: 100px;
  width: 30%;
  text-align: center;
  margin: auto;
  margin-top: 20px;
}

.linkedbtn:hover{
  color: #fff !important; 
  background-color: #004766;
}

.linkedbtn i {
  font-size: 21px;
  margin: -5px 10px 0 0;
  float: left;
}

.linkedbtn h3:hover{
color: #fff;
}

.linkedbtn h3 {
  margin-left: 10px; /* Optional to space the text from the icon */
  transition: color 0.3s ease;
}

.search-close-icon {
  display: none !important;
  position: absolute;
  top: 0;
  left: 100% !important;
  padding-left: 0px;
  color: rgba(0, 0, 0, 0.54);
  transition: color .2s;
  cursor: pointer;
}



input.search-field {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  border: 0;
  border-radius: 5px;
  padding: 0 0 0 50px;
  width: 48px;
  background: #f7f7f7;
  border-radius: 100px;
  transition: background 0.2s, width .2s;
  height: 48px;
  line-height: 48px;
}

input.search-field:hover {
  background: #ebebeb;
}

.searchbox {
  position: relative;
}

input.search-field:focus {
  outline: none;
  width: 300px;
  color: #212121;
}

input.search-field:focus:hover {
  background: #f7f7f7;
}

.search-icon {
  position: absolute;
  top: 0;
  padding-left: 20px;
  color: rgba(0, 0, 0, 0.54);
}

.search-content {
  color: #303c42;
  width: 90%;
  margin: auto;
  height: auto;
  padding: 8px 10px;
}

.search-heading {
  color: #757575;
  display: block;
  font-size: 12px;
  line-height: 30px;
}

input.search-field~.search-popup {
  overflow: hidden;
  left: 0px;
  min-height: 50px;
  max-height: 700px;
  width: 200px;
  top: -450px;
  position: absolute;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  transition: width .2s, top .2s ease-in-out;
  border-radius: 2px;
  background-color: #fff;
  z-index: 999;
}

input.search-field:focus~.search-popup {
  width: 300px;
  top: 50px;
}

nav ul.popup-list a:hover {
  background-color: initial;
}

nav ul.popup-list li {
  float: none;
}

nav ul.popup-list li a {
  line-height: 30px;
  padding: 0;
  font-size: 12px;
  color: #212121 !important;
}

nav ul.popup-list li a:hover {
  background-color: #f7f7f7;
}

@media only screen and (max-width: 600px) {



  input.search-field {
    width: 1px;
    margin-right: 0px;
    padding: 8px 25px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  input.search-field~.search-popup {
    width: 1px;
    margin-right: 0px;
    padding: 8px 25px;
  }

  .search-icon {
    top: 0;
    padding-left: 15px;
    color: rgba(0, 0, 0, 0.54);
  }

  input.search-field:focus {
    margin-right: 0px;
    width: 100vw;
    padding: 8px 8px 8px 50px;
  }

  input.search-field:focus~.search-popup {
    width: 100vw;
  }

  .search-content {
    width: 100%;
    padding: 8px 8px;
  }

  nav .nav-wrapper {
    margin-right: 0px;
  }
}

form.browser-default.right label {
  position: absolute;
  top: 11px;
  left: 18px;
}



.desktop-nav .navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link {
  color: var(--mainColor);
  font-weight: 500;
  border-bottom: solid 2px #05648f !important;
}

.navbar-area.ledu-area.otherpages-menu {
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 999;
  top: 0px;
  box-shadow: 0 0 10px 0 #0000001f;
  padding: 0px;
}

.header_space {
  margin: 90px 0 0 0;
}




.form-check.checkiform .form-check-input {
  width: 20px !important;
  background: none !important;
  height: 20px !important;
  margin-right: 8px;
  border-radius: 100px !important;
  padding: 0px !important;
}

.form-check.checkiform .form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e") !important;
}


.form-check.checkiform .form-check-input:checked {
  background-color: #0d6efd !important;
  border-color: #0d6efd !important;
}


.form-groups.emailforgot input {
  border: none !important;
  padding: 0 !important;
  height: 29px !important;
  color: var(--mainColor) !important;
  font-weight: bold;
}

.form-groups.emailforgot {
  border: solid 1px #ccc;
  padding: 13px 20px 0;
  margin: 0 0 20px;
  border-radius: 10px;
}

.termcheck {
  width: 23px !important;
  float: left;
  height: 23px !important;
  margin: 0 !important;
}


.inner-banner.contact_bgus::before {
  background: linear-gradient(167deg, rgba(0, 63, 91, 1) 0%, rgb(0 63 91 / 37%) 35%, rgb(0 212 255 / 0%) 100%);
}





.videoview .courses-item .content {
  padding: 15px 0;
  position: relative;
}

.course-slider-two.videoview .courses-item {
  padding: 14px;
  border-radius: 6px;
}

.course-slider-two.videoview .courses-item a img {
  border-radius: 8px;
}

.videoviews {
  margin: 20px 0 0;
  box-shadow: 0 0 11px 0 #0000001a;
  padding: 0px;
  border: solid 1px #eee;
}

.videoviews h6 {
  padding: 0px 0 0;
  margin: 0;
  font-size: 18px;
}

.videoviews .course-list li {
  font-size: 14px;
}

.coursetab10 a img {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

.coursetab11 img {
  width: 50px;
  height: 50px;
}

table.table.videotab {
  vertical-align: middle;
}


ul.nav.tabs_signup.justify-content-start.gap-2 .nav-item {
  flex: 1 0 0;
  text-align: center;
}


.tab.courselfaq.courses-details-tab .tabs li {
  width: 190px;
  text-align: center;
}


.mentersvies0121 {
  display: grid;
  grid-template-columns: 196px 1fr;
  align-items: center;
}


.contact-info-card.contact_info {
  margin: 0 0 15px;
  padding: 20px 15px;
}

.formcontact .list-unstyled {
  font-size: 14px;
  margin-top: -14px;
}

.phonefloting label {
  top: -12px !important;
  z-index: 9;
  left: 70px !important;
  padding: 0 !important;
}



.recruiterhirelist img {
  width: 100px !important;
  border-radius: 20px;
}


form.banner-form.searchinst {
  width: 100% !important;
}

form.banner-form.searchinst input {
  background: #f1f1f1 !important;
  width: 75%;
}

.content.allinfo .tag-btn {
  font-size: 13px;
  padding: 2px 11px;
}

.content.allinfo h3 {
  margin-bottom: 8px;
}

.content.allinfo {
  text-align: center;
}

.recruiterhirelist img {
  width: 100%;
  margin: 0 auto;
}

.position-relative.companylogo01 img {
  width: 110px;
  background: #eee;
  padding: 15px;
  border-radius: 10px;
  margin: 0px;
}

.mentersvies0121.recruiter101s {
  grid-template-columns: 130px 1fr;
  align-items: center;
}





#newTag {
  font-size: 1rem;
  color: #000;
  display: block;
  width: 100%;
  height: 3rem;
  padding: 0 1rem;
  border: 0.125rem solid #CFD8DC;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  transition: 0.5s all;
}

#newTag:focus,
#newTag:active {
  outline: none;
  border: 0.125rem solid #81C784;
  box-shadow: 0 0 0.5rem rgba(67, 160, 71, 0.15);
}

ul#tagList {
  display: block;
  padding: 0;
  margin: 0;
}

ul#tagList::after {
  content: "";
  clear: both;
  display: table;
}

ul#tagList li {
  position: relative;
  list-style: none;
  float: left;
  font-size: 0.835rem;
  text-transform: capitalize;
  background-color: #e8f5f5;
  line-height: 1rem;
  padding: 0.5rem 2rem 0.5rem 1rem;
  border-radius: 1rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  word-break: break-all;
}

ul#tagList li>span.rmTag {
  position: absolute;
  margin: 0.25rem;
  top: 0;
  right: 0;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 1rem;
  color: #fff;
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-align: center;
  background-color: #333;
  cursor: pointer;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}

ul#tagList li>span.rmTag:hover {
  background-color: #ef9a9a;
}


.left_recruiterlist {
  display: grid;
  gap: 20px;
  grid-template-columns: 420px 1fr;
}


.searchlocation {
  background: #fff;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 114px;
  gap: 0;
  align-items: center;
  margin: 20px 0 16px;
  box-shadow: 0 0 19px 1px #00000014;
}

.searchlocation .iconfield {
  display: grid;
  float: left;
  width: 100%;
  gap: 10px;
  grid-template-columns: 40px 1fr;
  align-items: center;
  border-right: solid 1px #eee;
  padding: 0px 10px;
}

.icon_search {
  text-align: center;
}

.input-field input,
.input-field select {
  border: none;
  box-shadow: none;
  outline: none;
  padding: 15px 0;
  background-color: #fff;
  outline: none !important;
  box-shadow: none !important;
}


button.submit_btn {
  background: var(--mainColor);
  padding: 6px 20px;
  border-radius: 100px;
  color: var(--whiteColor);
}





.card_rec .live_user {
  position: absolute;
  background: green;
  color: #fff;
  padding: 2px 20px;
  border-radius: 0px 0 5px 5px;
  font-size: 11px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  text-align: center;
}



.card_rec .mentersvies0121 {
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
  gap: 20px;
}

.reliview .menter_pro {
  width: 100%;
  overflow: hidden;
  border-radius: 0px;
}


.recruiter_all_lists.nav-pills .nav-link.active,
.recruiter_all_lists.nav-pills .show>.nav-link {
  background-color: #eaf8ff;
  border: solid 1px #003f5b;
  position: relative;
}

.recruiter_all_lists.nav-pills .nav-link {
  border: solid 2px #fff;
  background-color: #fff;
  box-shadow: 0 0 19px 1px #00000014;
  margin-bottom: 15px;
}



.left_recruiterlist .reviewd {
  color: #444;
  font-size: 13px;
}

.perographsv {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 14px;
  line-height: 18px;
}


.recruiter_all_lists .nav-link.active:after {
  content: '';
  position: absolute;
  border-left: solid 10px var(--mainColor);
  width: 0;
  height: 60%;
  left: 0;
  border-radius: 0px 50px 50px 0px;
  top: 20%;
}

.comnam h3 {
  margin: 0px;
}


.card_rec .skillstopic div {
  height: 30px;
  border-radius: 64px;
  padding: 0 16px;
  font-size: 13px;
  line-height: 28px;
  background: #fff;
}

.card_rec .skillstopic {
  gap: 4px;
  margin-top: 10px;
}


.sticky-header {
  position: sticky;
  top: 115px;
  max-height: calc(100vh - 115px);
  overflow-y: auto;
  scrollbar-width: unset;
}

.input-field.ad121 {
  text-align: center;
}

.companylogos img {
  text-align: center;
  padding: 20px;
  background: #fff;
  border: solid 1px #ddd;
}

.sticky-header::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.sticky-header::-webkit-scrollbar-button {
  background: #ccc;
}

.sticky-header::-webkit-scrollbar-track-piece {
  background: #888;
}

.sticky-header::-webkit-scrollbar-thumb {
  background: #eee;
}

.widget-icon {
  color: var(--mainColor);
  font-size: 20px;
  background-color: var(--whiteColor);
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 3px;
  display: inline-block;
}


.card.widget-flat p {
  font-size: 14px;
}

.card.widget-flat h5 {
  font-size: 16px;
}


.searchlocation.resumesearch.m-0 {
  grid-template-columns: 1fr 100px;
  border-radius: 5px;
}



.applide_tabs li {
  background: #eee;
  flex: 1;
  padding: 5px 5px;
}

.nav-tabs.applide_tabs .nav-item.show .nav-link,
.nav-tabs.applide_tabs .nav-link.active {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.applide_tabs .nav-link {
  width: 100%;
  padding: 6px 0;
  color: #444;
  border-radius: 100px;
}

.candidate_job_icon {
  display: flex;
  gap: 10px;
  text-align: left;
}

.candidate_job_icon img {
  width: 50px;
  height: 50px;
  border-radius: 100px;
}

.developers_pos h6 {
  margin: 0px;
}


table.table-centered tr th {
  font-size: 14px;
  line-height: 16px;
}

table.table-centered tr td {
  font-size: 13px;
}

table.reusme-table tr th {
  font-size: 14px;
  line-height: 16px;
}

table.reusme-table tr td {
  font-size: 13px;
}


table.reusme-table {
  width: 1900px;
}

#profile-upload {
  background-image: url('');
  background-size: cover;
  background-position: center;
  height: 170px;
  width: 170px;
  border: 1px solid #bbb;
  position: relative;
  border-radius: 250px;
  overflow: hidden;
  margin: 0 auto;
}

#profile-upload:hover input.upload {
  display: block;
}

#profile-upload:hover .hvr-profile-img {
  display: inline-block;
}

#profile-upload .fa {
  margin: auto;
  position: absolute;
  bottom: 6px;
  left: 0;
  text-align: center;
  right: 0;
  opacity: 1;
  transition: opacity 1s linear;
  -webkit-transform: scale(.75);
  width: 40px;
  background: var(--mainColor);
  color: var(--whiteColor);
  border-radius: 100px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
}

#profile-upload input.upload {
  z-index: 1;
  left: 0;
  margin: 0;
  bottom: 0;
  top: 0;
  padding: 0;
  opacity: 0;
  outline: none;
  cursor: pointer;
  position: absolute;
  background: #ccc;
  width: 100%;
  display: none;
  height: 100%;
  border-radius: 100%;
}

#profile-upload .hvr-profile-img {
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  vertical-align: middle;
  position: relative;
  background: transparent;
}

#profile-upload:hover .fa:after {
  height: 100%;
}

.coachingtable tr th small {
  font-size: 10px;
  color: #747474;
  font-weight: normal;
  width: 100%;
  float: left;
}

.coachingtable tr {
  align-items: center;
  vertical-align: middle;
}

.upperCase {
  text-transform: uppercase;
}

.details .item {
  margin-top: 20px;
}




.leftPanel,
.leftPanel a {
  color: #bebebe;
  text-decoration: none;
}

.leftPanel h2 {
  color: white;
  font-size: 21px;
  text-align: left;
}

.leftPanel,
.leftPanel a {
  font-size: 0.38cm;
}

.projectName span,
.jobPosition span {
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

.smallText,
.smallText span,
.smallText p,
.smallText a {
  font-size: 0.35cm;
  text-align: left;
}

.leftPanel .smallText,
.leftPanel .smallText,
.leftPanel .smallText span,
.leftPanel .smallText p,
.smallText a {
  font-size: 0.45cm;
  color: #ccc;
}



page {
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
}

page[size="A4"] {
  width: 21cm;
  height: 29.7cm;
}

@page {
  size: 21cm 29.7cm;
  padding: 0;
  margin: 0mm;
  border: none;
  border-collapse: collapse;
}

.leftPanel {
  width: 27%;
  background-color: #484444;
  padding: 0.7cm;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rightPanel {
  width: 73%;
  padding: 0.7cm;
  text-align: left;
}

.leftPanel img {
  width: 4cm;
  height: 4cm;
  margin-bottom: 0.7cm;
  border-radius: 50%;
  border: 0.15cm solid white;
  object-fit: cover;
  object-position: 50% 50%;
}

.leftPanel .details {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.skill {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bottomLineSeparator {
  border-bottom: 0.05cm solid white;
}

.yearsOfExperience {
  /* width: 1.6cm; */
  /* display: flex; */
  flex-direction: row;
  justify-content: center;
}

.alignleft {
  text-align: left !important;
  width: 1cm;
}

.alignright {
  text-align: right !important;
  width: 0.6cm;
  margin-right: 0.1cm
}

.workExperience>ul {
  list-style-type: none;
  padding-left: 0;
}

.workExperience>ul>li {
  position: relative;
  margin: 0;
  padding-bottom: 0.5cm;
  padding-left: 0.5cm;
}

.workExperience>ul>li:before {
  background-color: #b8abab;
  width: 0.05cm;
  content: '';
  position: absolute;
  top: 0.1cm;
  bottom: -0.2cm;
  left: 0.05cm;
}

.workExperience>ul>li::after {
  content: '';
  position: absolute;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='none' fill='%23484444' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  left: -0.09cm;
  top: 0;
  width: 0.35cm;
  height: 0.35cm;
}

.jobPosition {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}



.lastParagrafNoMarginBottom {
  margin-bottom: 0;
}

.workExperience>ul>li ul {
  padding-left: 0.5cm;
  list-style-type: disc;
}


.form-check.radiobtnin0111 input {
  width: 16px !important;
  height: 16px !important;
  padding: 0px !important;
  border-radius: 100px !important;
}

.radiobtnin0111 .form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23000'/%3e%3c/svg%3e");
}









.ca-search-form-container {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.ca-search-flex-container {
  display: flex;
  align-items: center;
}

.ca-search-label {
  margin-right: 1rem;
  font-weight: 100;
  cursor: pointer;
}

.ca-search-input-container {
  display: flex;
}

.ca-search-input,
.ca-search-input:active,
.ca-search-input:visited {
  width: 25rem;
  border: none;
  outline: none;
  background-color: transparent;
  color: 333;
  font-size: inherit;
  font-family: inherit;
  position: absolute;
  z-index: -1;
}

.ca-replace-input-field,
.ca-search-input:active,
.ca-search-input:visited {
  background-color: #fff;
  z-index: 2;
  cursor: text;
  padding: 0px !important;
}

.ca-search-typewriter-container {
  width: 25rem;
  overflow: hidden;
}



#ca-typewriter-here {
  white-space: nowrap;
}




video#my-video {
  width: 100%;
}

.videoviews .course-list li i {
  position: relative;
  top: 2px;
}

.videoviews .course-list {
  gap: 15px;
}


table.table.videotab thead {
  background: #003f5b;
  color: #fff;
}


.niceCountryInputMenu {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 32px;
  align-items: center;
}


.btn-gradient-primary {
  background: -webkit-gradient(linear, left top, right top, from(#da8cff), to(#9a55ff));
  background: linear-gradient(to right, #da8cff, #9a55ff);
  border: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.btn-gradient-success {
  background: -webkit-gradient(linear, left top, right top, from(#84d9d2), to(#07cdae));
  background: linear-gradient(to right, #84d9d2, #07cdae);
  border: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.btn-gradient-danger {
  background: -webkit-gradient(linear, left top, right top, from(#ffbf96), to(#fe7096));
  background: linear-gradient(to right, #ffbf96, #fe7096);
  border: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.btn-gradient-info {
  background: -webkit-gradient(linear, left top, right top, from(#90caf9), color-stop(99%, #047edf));
  background: linear-gradient(to right, #90caf9, #047edf 99%);
  border: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.btn-gradient-light {
  background: -webkit-gradient(linear, left top, left bottom, from(#f4f4f4), to(#e4e4e9));
  background: linear-gradient(to bottom, #598539, #30970e);
  border: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.btn-gradient-dark {
  background: linear-gradient(89deg, #5e7188, #3e4b5b);
  border: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.btn-gradient-warning {
  background: -webkit-gradient(linear, left top, right top, from(#f6e384), to(#ffd500));
  background: linear-gradient(to right, #d9c045, #c76a0b);
  border: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.btn-gradient-121 {
  background: -webkit-gradient(linear, left top, right top, from(#f6e384), to(#ffd500));
  background: linear-gradient(to right, #387f9f, #004766);
  border: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.badge-gradient-success {
  background: -webkit-gradient(linear, left top, right top, from(#84d9d2), to(#07cdae));
  background: linear-gradient(to right, #84d9d2, #07cdae);
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}

.badge-gradient-info {
  background: -webkit-gradient(linear, left top, right top, from(#90caf9), color-stop(99%, #047edf));
  background: linear-gradient(to right, #90caf9, #047edf 99%);
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}

.badge-gradient-danger {
  background: -webkit-gradient(linear, left top, right top, from(#ffbf96), to(#fe7096));
  background: linear-gradient(to right, #ffbf96, #fe7096);
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}

.badge-gradient-warning {
  background: -webkit-gradient(linear, left top, right top, from(#f6e384), to(#ffd500));
  background: linear-gradient(to right, #bbab5e, #e7c102);
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}

.btn-gradient-primary:not(.btn-gradient-light) {
  color: #ffffff;
}

.card.widget-flat h3,
.card.widget-flat h5,
.card.widget-flat p,
.card.widget-flat .text-success {
  color: #fff !important;
}


.card.widget-flat {
  position: relative;
}

.bg-layerbg {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
}

.bg-layerbg img {
  width: 100%;
  height: 100%;
}

.allprocess {
  background: #ededed;
  padding: 20px 20px 30px 40px;
  box-shadow: 0 0 10px 0px #00000042;
  border-radius: 6px;
}

.hiring_process {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  margin-bottom: 30px;
}


.hiring_process .profileinterview {
  position: relative;
  overflow: hidden;
  background: #003f5b26;
}

.firsthire {
  margin-top: 20px;
}



.interviewround1 {
  position: absolute;
  top: 26px;
  left: -29px;
  background: #003f5b;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 100px;
  text-align: center;
  color: #fff;
  z-index: 999;
}

.firsthire:before {
  content: '';
  position: absolute;
  border-left: solid 2px #d6d6d6;
  top: 0;
  bottom: 0;
  left: -15px;
}

.firsthire {
  position: relative;
}


.header0101 ol li {
  font-size: 14px;
}

.header0101 p {
  font-size: 14px;
}

.headinghire {
  position: relative;
}

.headinghire:after {
  content: '';
  position: absolute;
  height: 3px;
  width: 40%;
  left: 50%;
  right: 0;
  background: #b9b9b9;
  bottom: -10px;
  border-radius: 100px;
  transform: translate(-50%, -50%) !important;
}


.recruiterbgme.jobbgre {
  background: #ebf2f1;
}


.left-content.kkkkkddsadf.applicantsuser img {
  max-width: 100% !important;
}


.right-content.kkkkkdd {
  display: inline-table;
  z-index: 11;
  top: 80px;
  right: -70px;
}

.right-content.kkkkkdd .content img {
  height: 50px;
}

.left-content.kkkkkddsadf.applicantsuser {
  padding: 0 !important;
  width: 220px;
}


.categoryjobs {
  border: 1px solid #ddd;
  padding: 22px 18px;
  display: flex;
  width: 100%;
  text-align: left;
  border-radius: 12px;
  margin-bottom: 15px;
  background-color: #ffffff;
}

.categoryjobs .image-left {
  min-width: 50px;
  padding-right: 10px;
}

.categoryjobs img {
  max-width: 100%;
}

.categoryjobs .text-info-right h4 {
  font-size: var(--jobbox-font-md);
  line-height: 26px;
  margin-bottom: 3px;
}

.font-xs {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18px !important;
}



.hiringviedessd {
  background-image: url(../../assets/images/icon/bg-left-hiring.svg),
    url(../../assets/images/icon/bg-right-hiring.svg),
    linear-gradient(to right, rgb(219 236 251), rgb(239 239 239));
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: bottom left,
    right,
    right;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  border-radius: 10px;
}


.categoryjobs.joblist010 .mentersvies0121 {
  grid-template-columns: 1fr;
  grid-gap: 15px;
}

.instructors-details-contents .sub-title {
  color: #4e4e4e;
  font-weight: 500;
}

.categoryjobs.joblist010 .companylogos img {
  padding: 10px;
  width: 80px;
  border-radius: 20px !important;
  background: #eee;
}

.categoryjobs.joblist010 .businessmen div {
  font-size: 14px;
}


.rightareee {
  background: #fff;
  color: var(--mainColor);
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 39px;
  border-radius: 100px;
  position: absolute;
  right: 0;
  bottom: 0px;
  border: solid 1px var(--mainColor);
}

.categoryjobs.joblist010 :hover .rightareee {
  background: var(--mainColor);
  color: var(--whiteColor);
  border: solid 1px var(--mainColor);
}



.banner-item {
  padding: 60px 20px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: #e6feff;
  border: solid 2px #1cf5fd4a;
}

.banner-thumb img {
  height: 190px;
}

.banner-item span {
  font-size: 13px;
  font-weight: 500;
  color: #000;
  background: #00000038;
  display: inline-block;
  height: 22px;
  line-height: 22px;
  padding: 0 14px;
  margin-bottom: 15px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  text-transform: capitalize;
}

.banner-title {
  font-size: 26px;
  margin-bottom: 30px;
}

.e-btn {
  height: 36px;
  line-height: 36px;
  padding: 0 20px;
  font-size: 14px;
  text-transform: capitalize;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border-radius: 5px;
}

.e-btn:hover {
  background-color: var(--titleColor);
  color: var(--whiteColor);
}


.banner-thumb {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
}

.banner-content {
  position: relative;
  z-index: 1;
}


.banner-item.trainer-ban {
  background: #e0e0e0;
  border-color: lightgrey;
  padding-top: 20px;
  padding-bottom: 100px;
}

.banner-item.trainer-ban .banner-thumb {
  position: absolute;
  top: 64%;
}



.banner-item.careerbg.trainer-ban {
  background: #E8EFFF;
  border-color: #93AED5;
}

.banner-item.menterbg.position-relative {
  background: #FAEBE9;
  border-color: #ff85766e;
}


.banner-item.companybg.trainer-ban {
  background: #FFF3D5;
  border-color: #FFCB3B;
  display: flex;
  height: 97%;
}

.banner-item.companybg.trainer-ban .banner-thumb {
  position: absolute;
  top: 70%;
}

.banner-item.companybg.trainer-ban .banner-thumb img {
  height: 100%;
}

ol.progress.hireprocessview[data-steps="2"] li {
  width: 49%;
}

ol.progress.hireprocessview[data-steps="3"] li {
  width: 33%;
}

ol.progress.hireprocessview[data-steps="4"] li {
  width: 24%;
}

ol.progress.hireprocessview[data-steps="5"] li {
  width: 19%;
}

ol.progress.hireprocessview[data-steps="6"] li {
  width: 16%;
}

ol.progress.hireprocessview[data-steps="7"] li {
  width: 14%;
}

ol.progress.hireprocessview[data-steps="8"] li {
  width: 12%;
}

ol.progress.hireprocessview[data-steps="9"] li {
  width: 11%;
}

.progress.hireprocessview {
  width: 100%;
  list-style: none;
  list-style-image: none;
  margin: 0 0 50px 0;
  padding: 0;
  position: relative;
  z-index: 11;
  overflow: visible;
}

.progress.hireprocessview li {
  float: left;
  text-align: center;
  position: relative;
}

.progress.hireprocessview .name {
  display: block;
  vertical-align: bottom;
  text-align: center;
  margin-bottom: 1em;
  color: black;
  opacity: 0.3;
}

.progress.hireprocessview .step {
  color: #fff;
  border: 2px solid #ddd;
  background-color: #c1c1c1;
  border-radius: 50%;
  line-height: 25px;
  width: 30px;
  height: 30px;
  display: inline-block;
  z-index: 0;
}

.progress.hireprocessview .step1 {
  color: #fff;
  border: 2px solid #ddd;
  background-color: #c1c1c1;
  border-radius: 50%;
  line-height: 25px;
  width: 30px;
  height: 30px;
  display: inline-block;
  z-index: 0;
}

.progress.hireprocessview .step span {
  opacity: 9;
}
.progress.hireprocessview .step1 span {
  opacity: 9;
}

.progress.hireprocessview .active .name,
.progress.hireprocessview .active .step span {
  opacity: 1;
}
.progress.hireprocessview .active .name,
.progress.hireprocessview .active .step1 span {
  opacity: 1;
}

.progress.hireprocessview .step:before {
  content: "";
  display: block;
  background-color: #d5d5d5;
  height: 0.4em;
  width: 50%;
  position: absolute;
  bottom: 0.6em;
  left: 0;
  z-index: -1;
  top: 12px;
}
.progress.hireprocessview .step1:before {
  content: "";
  display: block;
  background-color: #d5d5d5;
  height: 0.4em;
  width: 50%;
  position: absolute;
  bottom: 0.6em;
  left: 0;
  z-index: -1;
  top: 12px;
}

.progress.hireprocessview .step:after {
  content: "";
  display: block;
  background-color: #0070ff;
  height: 0.4em;
  width: 50%;
  position: absolute;
  bottom: 0.6em;
  right: 0;
  z-index: -1;
  top: 12px;
}
.progress.hireprocessview .step1:after {
  content: "";
  display: block;
  background-color: #d5d5d5;
  height: 0.4em;
  width: 50%;
  position: absolute;
  bottom: 0.6em;
  right: 0;
  z-index: -1;
  top: 12px;
}



.progress.hireprocessview .active .step,
.progress.hireprocessview .active .step:before {
  background-color: #0070ff;
}


.progress.hireprocessview .active .step {
  border: 3px solid #0070ff;
}

.progress.hireprocessview .active .headinghire h5 {
  color: #0070ff;
}

.progress.hireprocessview .done .step {
  color: #000 !important;
}


.reviewsreat .rate {
  float: left;
  width: 100%;
  display: ruby;
  flex-direction: column-reverse;
}

.reviewsreat .rate:not(:checked)>input {
  position: absolute;
  top: -9999px;
}

.reviewsreat .rate:not(:checked)>label {
  float: right;
  width: 25px;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 25px;
  color: #ccc;
}

.reviewsreat .rate:not(:checked)>label:before {
  content: '★ ';
}

.reviewsreat .rate>input:checked~label {
  color: #ffc700;
}

.reviewsreat .rate:not(:checked)>label:hover,
.reviewsreat .rate:not(:checked)>label:hover~label {
  color: #deb217;
}

.reviewsreat .rate>input:checked+label:hover,
.reviewsreat .rate>input:checked+label:hover~label,
.reviewsreat .rate>input:checked~label:hover,
.reviewsreat .rate>input:checked~label:hover~label,
.reviewsreat .rate>label:hover~input:checked~label {
  color: #c59b08;
}


.headinghire h5 {
  font-size: 16px;
}












.showmoreconteint .content {
  font-size: 1.1rem;
  line-height: 1.5rem;
}

.showmoreconteint .content h2 {
  margin: 0.5rem 0;
}

.showmoreconteint a {
  appearance: none;
  color: var(--mainColor);
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}

.showmoreconteint a:hover {
  background: rgba(39, 121, 189, 0.1);
}

.excerpt-hidden {
  height: 3.5rem;
  overflow: hidden;
  position: relative;
}

.excerpt-hidden:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 64px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 1) 100%);
}

.excerpt-visible {
  height: auto;
  overflow: auto;
}

div#slider_desc_toogler {
  margin: 10px 0 20px;
}



















span.password-toggle.eye {
  position: absolute;
  top: 15px;
  right: 25px;
}

.LinkedInIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.shift {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.PhoneInputInput {
  outline: none;
  border: none !important;
}

.PhoneInputCountry {
  margin-top: -10px !important;
}


.banner-img {
  opacity: 0;
  transform: translateY(-50%);
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
}

.banner-content {
  opacity: 0;
  transform: translateY(50%);
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
}

.animate-from-top {
  opacity: 1;
  transform: translateY(0);
}

.animate-from-bottom {
  opacity: 1;
  transform: translateY(0);
}


.stsearch-box input {
  border: none !important;
  outline: none; font-size: 14px;
}




ul.nav.tabs_signup.justify-content-start.gap-2 .nav-item {
  flex: 1 0 0;
  text-align: center;
}

.navbar-nav .nav-link.active {
  color: var(--mainColor);
  font-weight: 500;
  border-bottom: solid 2px #05648f !important;
}

.navbar-nav .nav-item {
  font-size: 20px;
  color: var(--whiteColor);
  position: relative;
  z-index: 1;
  font-weight: 500;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 15px;
  margin-right: 10px;

}

.accordion-button:not(.collapsed) {
  background-color: white !important;
}

.accordion-header:hover {
  border-bottom: 1px solid var(--mainColor);
}

.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  display: none;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.table.hiretable td {
  vertical-align: middle;
  font-size: 0.875rem;
  line-height: 1;
  white-space: nowrap;
  padding: 15px;
}

 .file-upload-contain {
  position: relative;
}

.file-upload-contain .file-input,
.file-upload-contain .file-preview {
  position: initial;
}

.file-upload-contain .file-drop-zone {
  border: 2px dashed var(--mainColor);
  transition: 0.3s;
  margin: 0;
  padding: 0;
  border-radius: 20px;
  background-color: #f1f8fe;
  min-height: auto;
}

.file-upload-contain .file-drop-zone.clickable:hover,
.file-upload-contain .file-drop-zone.clickable:focus,
.file-upload-contain .file-highlighted {
  border: 2px dashed var(--mainColor) !important;
  background-color: #dfedfc;
}

.upload-area i {
  color: var(--mainColor);
  font-size: 50px;
}

.upload-area p {
  margin-bottom: 20px;
  margin-top: 0;
  font-size: 16px;
  font-weight: 600;
  color: var(--mainColor);
}

.upload-area p b {
  color: var(--mainColor);
}

.upload-area button {
  padding: 8px 16px;
  min-width: 150px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background-color: var(--mainColor);
  border: 2px solid var(--mainColor);
  border-radius: 50px;
  transition: 0.3s;
}

.upload-area button:hover {
  background-color: var(--mainColor);
  box-shadow: 0px 4px 8px rgb(37 128 232 / 48%);
}

.file-preview {
  padding: 0;
  border: none;
  margin-bottom: 30px;
}

.file-preview .fileinput-remove {
  display: none;
}

.file-drop-zone-title {
  padding: 20px 10px;
  text-align: center;
}

.file-drop-zone .file-preview-thumbnails {
  cursor: pointer;
}

.file-preview-frame {
  cursor: default;
  display: flex;
  align-items: center;
  border: none;
  background-color: #2580e8;
  box-shadow: none;
  border-radius: 8px;
  width: 100%;
  padding: 15px;
  margin: 8px 0px;
}

.file-preview-frame:not(.file-preview-error):hover {
  border: none;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
}

.file-preview-frame .kv-file-content {
  min-width: 45px;
  min-height: 45px;
  width: 45px;
  height: 45px;
  border-radius: 4px;
  margin-right: 10px;
  background-color: #fff;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-preview-image {
  border-radius: 4px;
}

.file-preview-frame .file-footer-caption {
  padding-top: 0;
}

.file-preview-frame .file-footer-caption {
  text-align: left;
  margin-bottom: 0;
}

.file-detail {
  font-size: 14px;
  height: auto;
  width: 100%;
  line-height: initial;
}

.file-detail .file-caption-name {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 6px;
}

.file-detail .file-size {
  color: #f1f8fe;
  font-size: 12px;
}

.kv-zoom-cache {
  display: none;
}

.file-preview-frame .file-thumbnail-footer {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.file-preview-frame .file-drag-handle,
.file-preview-frame .file-upload-indicator {
  float: none;
}

.file-preview-frame .file-footer-buttons {
  float: none;
  display: flex;
  align-items: center;
}

.file-preview-status.text-center {
  display: none;
}

.kv-file-remove.file-remove {
  border: none;
  background-color: #ef2f2f;
  color: #fff;
  width: 25px;
  height: 25px;
  font-size: 12px;
  border-radius: 4px;
  margin: 0px 4px;
}

.file-drag-handle.file-drag {
  border: none;
  background-color: #fff;
  color: #2580e8;
  width: 25px;
  height: 25px;
  font-size: 12px;
  border-radius: 4px;
  margin: 0px 4px;
}

.kv-file-upload.file-upload {
  border: none;
  background-color: #48bd22;
  color: #fff;
  width: 25px;
  height: 25px;
  font-size: 12px;
  border-radius: 4px;
  margin: 0px 4px;
}

.file-thumb-loading {
  background: none !important;
}

.file-preview-frame.sortable-chosen {
  background-color: #64a5ef;
  border-color: #64a5ef;
  box-shadow: none !important;
}





.boxshhd .btn-light {
  color: #2cdd9b;
  background-color: #e5f7f0;
  border-color: #d8f7eb;
}

.boxshhd .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  color: #000;
}

img.rounded-circle {
  max-width: 50px;
  margin-right: 12px;
}

.boxshhd .osahan-post-header {
  font-size: 14px;
}

.boxshhd .rounded-circle.text-white {
  width: 74px;
  height: 50px;
  margin-right: 12px;
}

.picker .pc-select {
  position: relative;
  display: inline-block;
  min-width: 100%;
  max-width: 100%;
}


.picker .pc-select .pc-trigger {
  cursor: pointer;
  margin-right: 0;
  width: 100%;
  height: 45px;
  font-size: 14px;
  outline: none;
  box-shadow: none !important;
  line-height: 43px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 0px 15px;
}

.picker .pc-element,
.picker .pc-trigger {
  display: inline-block;
  color: #5e5e5e;
  position: relative;
  z-index: 10;
  border: 1px solid #c7c7c7;
  border-radius: 20px;
  word-wrap: break-word;
  cursor: default;
  background-color: #f3f3f3;
  margin-right: 7px;
  margin-bottom: 5px;
  padding: 0 30px 0 15px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
}

.picker .pc-element .pc-close,
.picker .pc-trigger .pc-close {
  position: absolute;
  top: 50%;
  right: 7px;
  margin-top: -13px;
  font-size: 16px;
  cursor: pointer;
  color: red;
}

.picker .pc-element .pc-close:after,
.picker .pc-trigger .pc-close:after {
  content: '\2716';
}

.picker .pc-select .pc-list {
  position: absolute;
  text-align: left;
  left: 0;
  top: calc(100% - 6px);
  width: 100%;
  border: 1px solid #666;
  z-index: 11;
  background-color: #fff;
}

.picker .pc-select .pc-list ul {
  margin: 0;
  padding: 0;
  max-height: 400px;
  overflow-y: auto;
}

.picker .pc-select .pc-list li {
  display: block;
  list-style: none;
  padding: 0 0 0 8px;
  cursor: pointer;
  color: #666;
  word-wrap: break-word;
}

a.addtambtn {
  background: white;
  padding: 10px;
  font-size: 14px;
  border: solid 1px #003f5b;
  border-radius: 7px;
  font-weight: 600;
  color: #003f5b;
}

a.addtambtn:hover {
  background: #003f5b;
  border: solid 1px #003f5b;
  color: #fff;
}

.tabs_signup .nav-item .nav-link {
  background: #ededed;
  border: solid 1px #ededed;
}

.file-upload-contain .file-input,
.file-upload-contain .file-preview {
  position: initial;
  width: 100%;
}

.react-tagsinput {
  padding-left: 5px;
  padding-top: 5px;
  border: none !important;
}

.react-tagsinput-tag {
  position: relative !important;
  list-style: none !important;
  float: left !important;
  font-size: 0.835rem !important;
  text-transform: capitalize !important;
  background-color: #e8f5f5 !important;
  line-height: 1rem !important;
  padding: 0.5rem 2rem 0.5rem 1rem !important;
  border-radius: 1rem !important;
  margin-right: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  word-break: break-all;
  border: none !important;
  color: var(--bodyColor) !important;
}

.react-tagsinput-remove {
  position: absolute !important;
  margin: 0.25rem !important;
  top: 0 !important;
  right: 0 !important;
  height: 1.5rem !important;
  width: 1.5rem !important;
  border-radius: 1rem !important;
  color: #fff !important;
  font-size: 1.25rem !important;
  line-height: 1.5rem !important;
  text-align: center !important;
  background-color: #333 !important;
  cursor: pointer !important;
  -webkit-transition: 0.3s all !important;
  transition: 0.3s all !important;
}

.aboutPageEditorContainer {
  height: 300px;
}

.rejectbtn {
  position: absolute;
  right: 22px;
  bottom: 28px;
  background: red;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
}

.rejectbtn1 {
  position: absolute;
  right: 150px;
  bottom: 28px;
  background: rgb(0, 102, 255);
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
}

.rejectbtn2 {
  position: absolute;
  right: 191px;
  bottom: 28px;
  background: rgb(19, 148, 36);
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
}

.datetime-timezone-input {
  display: flex;
  height: 40px;
}

.datetime-input {
  flex: 1;
  margin-right: 5px;
}

.timezone-select {
  height: 40px;
  flex: 1;
}

.accordion-item:last-of-type {
  border: none !important;
  width: 109% !important;
}
.accordion-header {
  z-index: 9999990999998;
  text-align: center !important;
  color: white;
  font-size: 1.2rem;
  outline: none !important;
}

.rate {
  unicode-bidi: bidi-override;
  direction: rtl;
  text-align: center;
}

.star {
  color: #ccc;
  cursor: pointer;
}

.star.filled {
  color: #ffd700;
}

.phoneinput .PhoneInput {
  /* width: 110px; */
  border: none !important;
}

.combined-input-container {
  display: flex;
  border: 1px solid rgb(197, 195, 195);
  height: 49px;
  border-radius: 7px;
}

.EditMobile input {
  border: none !important;
  margin-left: -29px;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  box-sizing: border-box;
}

.react-calendar {
  width: 100% !important;
  background: white;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  border-top: 1px solid #a0a096 !important;
}

.bg-light-gray {
  background-color: #f7f7f7;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}


.bg-sky.box-shadow {
  box-shadow: 0px 5px 0px 0px #00a2a7
}

.bg-orange.box-shadow {
  box-shadow: 0px 5px 0px 0px #af4305
}

.bg-green.box-shadow {
  box-shadow: 0px 5px 0px 0px #4ca520
}

.bg-yellow.box-shadow {
  box-shadow: 0px 5px 0px 0px #dcbf02
}

.bg-pink.box-shadow {
  box-shadow: 0px 5px 0px 0px #e82d8b
}

.bg-purple.box-shadow {
  box-shadow: 0px 5px 0px 0px #8343e8
}

.bg-lightred.box-shadow {
  box-shadow: 0px 5px 0px 0px #d84213
}


.bg-sky {
  background-color: #02c2c7
}

.bg-orange {
  background-color: #e95601
}

.bg-green {
  background-color: #5bbd2a
}

.bg-yellow {
  background-color: #f0d001
}

.bg-pink {
  background-color: #ff48a4
}

.bg-purple {
  background-color: #9d60ff
}

.bg-lightred {
  background-color: #ff5722
}

.padding-15px-lr {
  padding-left: 15px;
  padding-right: 15px;
}

.padding-5px-tb {
  padding-top: 5px;
  padding-bottom: 5px;
}

.margin-10px-bottom {
  margin-bottom: 10px;
}

.border-radius-5 {
  border-radius: 5px;
}

.margin-10px-top {
  margin-top: 10px;
}

.font-size14 {
  font-size: 14px;
}

.text-light-gray {
  color: #d6d5d5;
}

.font-size13 {
  font-size: 13px;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.table td,
.table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.active1 {
  background-color: #004766;
  color: white !important;
}

.bg_gradient {
  background: repeating-linear-gradient(45deg, #f3f3f3, transparent 100px);
}

ol.progress.hireprocessview.coursestrash {
  background: none;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

ol.progress.hireprocessview.coursestrash li {
  display: flex;
  gap: 20px;
  margin: 0 0 29px;
  width: 100%;
  align-items: center;
}

.progress.hireprocessview li {
  float: left;
  text-align: center;
  position: relative;
}

.right_fullscreen {
  position: absolute;
  z-index: 1;
  font-size: 20px;
  right: 10px;
  top: 10px;
}

.tabs_item.panel.panel-default.panel-fullscreen .courses-details-accordion {
  margin: 0;
}

.tabs_item.panel.panel-default .ri-fullscreen-exit-line {
  display: none;
}

.tabs_item.panel.panel-default.panel-fullscreen .ri-fullscreen-exit-line {
  display: block;
}

.tabs_item.panel.panel-default.panel-fullscreen .ri-fullscreen-line {
  display: none;
}


.tabs_item.panel.panel-default.panel-fullscreen .gallery-boxes .gal-descrip {
  height: calc(100vh - 0vh);
}

.tabs_item.panel.panel-default.panel-fullscreen .gal-tabs {
  text-align: center;
  background: linear-gradient(0deg, #2f2f2f70, transparent);
  float: left;
  width: 100%;
  padding: 12px 20px;
  position: absolute;
  bottom: 0;
}

.tabs_item.panel.panel-default.panel-fullscreen .courses-items video {
  height: calc(100vh - 20vh);
}

.tabs_item.panel.panel-default .gal-tabs {
  margin-top: 10px;
}

.tabs_item.panel-fullscreen {
  display: block;
  z-index: 9999;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
}


.headingnotes1 {
  color: darkslategray;
  font-weight: bold;
  font-size: 1.1em;
}

.heading-tow {
  color: rgb(7, 117, 117);
  font-weight: bold;
}


.printing:after {
  content: "|";
  color: #0f0;
  animation: blink 0.66s steps(3, start) infinite;
}

.gallery-boxes .gal-descrip {
  background: #f7f7f7;
  overflow: hidden;
  padding: 10px;
  height: calc(100vh - 14vh);
  margin: 0;
}

.gal-tabs {
  text-align: center;
}

.tabs_item.panel.panel-default .gal-tabs {
  margin-top: 10px;
}

.gal-tabs a.prv {
  float: left;
  background: #bdbdbd;
  padding: 6px 17px;
  border-radius: 5px;
  color: #fff;
}

.gal-tabs a.nxt {
  float: right;
  background: #003e59;
  padding: 6px 17px;
  border-radius: 5px;
  color: #fff;
}



.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 9999;
  overflow: auto;
}

.fullscreen .right_fullscreen {
  position: absolute;
  top: 10px;
  right: 10px;
}



.fixed-menu {
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
  z-index: 333;
  background: #fff;
  padding: 12px 0;
}

.card-body1 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.mb1-4 {
  width: calc(100% / 7);
  padding: 10px;
}

.h4 {
  text-align: center;
  margin-bottom: 10px;
}

.timeslotbook1 {
  margin-bottom: 10px;
}

.skillstopic .addtimes1>div {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.skillstopic .addtimes1>div.active1 {
  background-color: #28a745;
  color: #fff;
}

.text-end {
  text-align: right;
}

.pe-3 {
  padding-right: 1rem;
}

.pb-3 {
  padding-bottom: 1rem;

}

.instructors-details-contents1 {
  margin-top: 1rem;
}

.card-instrutors1 {
  padding: 1rem;
  border-radius: 4px;
}

.addtimebg1 {
  list-style: none;
  padding: 0;
}

.addtimebg li {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.addtimebg li .rmTag {
  margin-left: 5px;
  cursor: pointer;
}
.custom-star-rating {
  font-size: 10px; 
}


.custom-star-rating .star {
  font-size: 0.5rem; 
}
.step-number {
    color: white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.headinghire h5 {
    margin: 0;
}


@keyframes blink {
  to {
    visibility: hidden;
  }
}





svg.star-svg {
    width: 20px;
    height: 20px;
}



.customebtsns.accordion-header button, .customebtsns.accordion-header button:focus {
  background: #0d6efd !important;
  padding: 5px 15px;
  font-size: 14px;
  text-align: center;
  color: #fff;
  display: inline;
  outline: none !important;
  box-shadow: none !important;
}

.chart-container {
  width: 100%;
  height: 200px;
}
.Make_Payment{
  background: var(--mainColor);
    color: white;
}
#tagList{
  text-transform: none;
}
.textareaStyle{
 height:150px ;
 width: 100%;
 border-radius: 10px;
 border: 1px solid #d5d3d3;
 outline: none;
 padding: 10px;
}
.supportForm{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 150px !important;
}
.support_Form_Heading_Sections{
  text-align: center;
  align-items: center;
  padding: 5px;
  background: var(--mainColor);
}
.support_Form_Heading_Sections h3{
  color: white;
}

.NewsDetailsVideos {
  width: 800px;
  height: 650px;
  background-color: #edeff1;
  object-fit: cover !important;
}

.courses-items {
    position: relative;
    margin-top: 20px;
}

.video-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.video-controls .btn {
    width: 100px;
    margin: 0 10px;
    font-size: 16px;
}

.gal-tabs {
    margin-top: 20px;
    text-align: center;
}

.gal-tabs .prv,
.gal-tabs .nxt {
    margin: 0 15px;
    font-size: 18px;
    color: #007bff;
}

.gal-tabs .galcount {
    font-weight: bold;
}
.accordion-header-custom {
  margin-top: 10px ; 
  background-color: #f8f9fa; 
  border-radius: 4px; 
  cursor: pointer; 
}

.accordion-body-custom {
  background-color: #ffffff; 
}


h2.accordion-header-custom.accordion-header button {
  padding: 5px 15px;
}

.animated-content {
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

.animated-content.visible {
  opacity: 1;
}
/* .student-traning-notes-section{
  overflow-y: scroll;
  height: 90vh;
} */

.accordion-header-custom.disabled {
  pointer-events: none;
  opacity: 0.5;
}



.sub_he {
  font-size: 18px;
  padding: 10px 0;
  color: #111;
  border-bottom: solid 1px #dddddd; font-weight: 700;
}
.sub_he111 {
  padding: 10px 0;
  border-bottom: solid 1px #dddddd;
}
.sub_he111 .sub_he {
  border: none !important; padding-top: 0px;
}

h6.sub__title {
  color: #999;
  font-size: 14px;
  font-weight: 500;
}


.accordion-body-custom.accordion-body .sub_he:last-child {
  border: none;
}

.text-green {
    color: green;
}

.others-options.show.dropdown .rounded-pill {
  margin-right: 8px !important;
}
.others-options.dropdown .dropdown-toggle img {
  margin-right: 10px;
}

.others-options.show.dropdown .dropdown-menu.show {
  padding: 0 !important;
}
.others-options.show.dropdown .dropdown-item {
  padding: 10px 20px;
  border-bottom: solid 1px #eee;
}
.others-options.show.dropdown .dropdown-item i {
  margin-right: 10px;
}


.courses-area .courses-item img {
  height: 280px;
  width: 100%;
  object-fit: cover;
 
}
 .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  width: 53px !important;
  height: 50px !important;
  /* background-color: white; */
  /* border: var(--mainColor); */
  /* border: 0.1px solid var(--mainColor); */
  background-color: var(--mainColor) !important;
  border-radius: 50% !important;
  margin: -25px !important;
}


.slick-prev{
   font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  width: 53px !important;
  height: 50px !important;
  /* background-color: white; */
  /* border: var(--mainColor); */
  /* border: 0.1px solid var(--mainColor); */
  background-color: var(--mainColor) !important;
  border-radius: 50% !important;
  margin: -25px !important;
}

.content-container {
    display: block; /* Ensures the container behaves like a block */
    padding: 20px; /* Optional: Adds some padding around the content */
}

.company {
    display: block; /* Each div will take the full width */
    margin-bottom: 10px; /* Space below the company name */
}

.title {
    display: block; /* Makes the title a block */
    margin-bottom: 10px; /* Space below the title */
}

.button-container {
    display: block; /* Makes the button container a block */
}


.businessmen.footer_skills {
  display: inline-block;
  width: 100%;
}.businessmen.footer_skills div {
  width: 100%;
  margin: 10px 0 0;
  padding-right: 40px;
}

.categoryjobs.joblist010 {
  display: inline-block;
}

.scrollable-section {
  max-height: 10px; /* Adjust the height as needed */
  overflow-y: auto;  /* Enables vertical scrolling */
  padding: 10px;
  border: 1px solid #ddd; /* Optional: Adds a border for better visibility */
}



.form-floating>.form-control, .form-floating>.form-control-plaintext, .form-floating>.form-select {
  min-height: calc(3rem + calc(var(--bs-border-width) * 2)) !important;
}
  

  .supportFormcontainer .form-control {
    padding: 0px 20px !important;
    height: 45px !important;
}
.custom-textarea {
  width: 100%;   /* Adjust width */
  height: 200px;  /* Adjust height */
}
.PhoneInputCountrySelect[disabled], .PhoneInputCountrySelect[readonly] {
  cursor: default;
  display: none;
}

.gal-descrip {
  max-height: 100vh; /* Keep the height within the viewport */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 20px; /* Optional padding for aesthetics */
  scroll-behavior: smooth; /* Smooth scrolling */
}

.student-traning-notes-section {
  /* Additional styles as needed */
}


div#zoid-paypal-buttons-uid_2c80edda5f_mte6mdu6mzc {
  position: absolute;
  left: 0;
  background: #fff;
}


button.Make_Payment.btn.default-btn:before {
  width: 120%;
}

div#paypal-button-container {
  position: absolute;
  top: 0;
  background: #fff;
  width: 100%;
  left: 0px;
  padding-left: 24px;
  padding-top: 15px;
  padding-bottom: 0px;
}
div#paypal-button-container .paypal-buttons {
  padding-bottom: 400px !important;
}
.view1212.accordion-item {
  width: 100% !important;
  padding: 0 15px 15px;
}

.payment_models #paypal-button-container {
  position: relative;
  padding: 13px 0;
}
.payment_models #paypal-button-container .paypal-buttons {
  padding-bottom: 0 !important;
}

.hiring_process.new_hiring {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 100px;
}
.react-tagsinput-input {
    background: transparent;
    border: 0;
    color: #777;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 6px;
    margin-top: 1px;
    outline: none;
    padding: 5px;
    width: 100% !important;
}


.student_banner {
  height: auto !important;
}

.bg_gradient.student_coaching h6 {
  font-size: 14px;
}

.student_coaching .instructors-details-contents {
  overflow-y: scroll;
  height: calc(74vh - 0px);
}

.student_coaching .instructors-details-contents::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

::-webkit-scrollbar-button {
  background: #ccc
}
::-webkit-scrollbar-track-piece {
  background: #888
}
::-webkit-scrollbar-thumb {
  background: #eee
}