@media only screen and (max-width:767px) {
    .sf-steps-content.sf-steps-center {
        scroll-snap-type: x mandatory;
        overflow-y: auto;
        display: flex;
        align-items: center;
    }
    .hiringviedessd {
        padding: 20px;
        gap: 14px;
        background-size: 0;
        background: #eee;
    }
    .header_space {
        margin: 70px 0 0 0;
    }
    .tabs_1201 li {
        width: auto !important;
    }
    .joblisttitle {
        align-items: center;
        padding: 16px 15px;
        scroll-snap-align: center;
        justify-content: center;
        height: 90px;
        min-width: 260px;
    }
    .tabs_signup .nav-item .nav-link {
        font-size: 13px;
        padding: 6px 4px 4px;
    }

    :root {
        --fontSize: 15px
    }

    body {
        font-size: var(--fontSize)
    }

    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px
    }

    .pt-100 {
        padding-top: 50px
    }

    .ptb-70 {
        padding-top: 50px;
        padding-bottom: 20px
    }

    .pb-100 {
        padding-bottom: 50px !important
    }

    .pt-70 {
        padding-top: 50px
    }

    .pb-70 {
        padding-bottom: 20px
    }

    .pt-45 {
        padding-top: 30px
    }

    .pb-10 {
        padding-bottom: 0
    }

    .pl-20 {
        padding-left: 0
    }

    .pr-20 {
        padding-right: 0
    }

    .mt-20 {
        margin-top: 0
    }

    .mt-rs-20 {
        margin-bottom: 20px
    }

    .mb-45 {
        margin-bottom: 30px
    }

    .ml-20 {
        margin-left: 0
    }

    .mr-20 {
        margin-right: 0
    }

    .default-btn {
        padding: 10px 24px;
        font-size: 14px
    }

    .section-title span {
        font-size: 14px
    }

    .section-title h2 {
        font-size: 28px
    }

    .section-title p {
        padding-top: 10px
    }

    .ps-2 {
        padding-left: 0 !important
    }

    .ps-3 {
        padding-left: 0 !important
    }

    .ps-4 {
        padding-left: 0 !important
    }

    .ps-5 {
        padding-left: 0 !important
    }

    .ps-0 {
        padding-left: 15px !important
    }

    .pe-0 {
        padding-right: 15px !important
    }

    /* .p-0 {
        padding-left: 15px !important;
        padding-right: 15px !important
    } */

    h3 {
        font-size: 21px
    }

    .text-end {
        text-align: left !important
    }

    .header-left {
        float: none;
        text-align: center
    }

    .header-left ul li {
        padding-left: 25px;
        margin-right: 15px;
        margin-bottom: 10px
    }

    .header-left ul li i {
        font-size: 17px
    }

    .header-right {
        float: none;
        text-align: center
    }

    .header-right .header-language {
        margin-right: 15px
    }

    .banner-area {
        padding-top: 50px;
        overflow: hidden
    }

    .banner-area-ptb {
        padding-top: 50px;
        padding-bottom: 0
    }

    .banner-shape {
        display: none
    }

    .banner-content {
        margin-bottom: 30px
    }

    .banner-content span {
        font-size: 14px;
        margin-bottom: 10px
    }

    .banner-content h1 {
        font-size: 30px;
        margin-bottom: 15px
    }

    .banner-content p {
        margin-bottom: 20px
    }

    .banner-content .banner-form-area {
        margin-bottom: 20px
    }

    .banner-content .banner-form-area .banner-form {
        width: 100%
    }

    .banner-content .banner-form-area .banner-form .form-control {
        height: 50px;
        line-height: 50px;
        padding: 0 15px;
        max-width: 100%;
        width: 100%
    }

    .banner-content .banner-form-area .banner-form .form-control:focus {
        outline: none;
        -webkit-box-shadow: none;
        box-shadow: none
    }

    .banner-content .banner-form-area .banner-form .default-btn {
        margin-top: 15px;
        position: inherit;
        left: 0;
        height: 50px
    }

    .banner-content .banner-counter h3 {
        font-size: 20px
    }

    .banner-img {
        margin-left: auto;
        margin-right: auto
    }

    .banner-img .bg-shape {
        top: 0;
        left: 0;
        right: 0
    }

    .banner-img .bg-shape img {
        max-width: 100%
    }

    .banner-img .top-content {
        top: 20px;
        left: auto;
        right: 0;
        padding: 8px
    }

    .banner-img .top-content i {
        width: 30px;
        height: 30px;
        line-height: 35px;
        font-size: 14px;
        margin-right: 5px
    }

    .banner-img .top-content .content h3 {
        font-size: 13px
    }

    .banner-img .top-content .content p {
        font-size: 11px
    }

    .banner-img .right-content {
        top: 140px;
        right: auto;
        left: 0;
        padding: 8px
    }

    .banner-img .right-content i {
        width: 30px;
        height: 30px;
        line-height: 35px;
        font-size: 14px;
        margin-right: 5px
    }

    .banner-img .right-content .content h3 {
        font-size: 13px
    }

    .banner-img .right-content .content p {
        font-size: 11px
    }

    .banner-img .left-content {
        display: none;
    }
    .banner-img .left-content img {
        top: 8px;
        left: 8px;
        border-radius: 50%;
        max-width: 35px
    }

    .banner-img .left-content .content {
        margin-bottom: 5px
    }

    .banner-img .left-content .content h3 {
        font-size: 14px
    }

    .banner-img .left-content .content p {
        font-size: 12px
    }

    .banner-img .left-content .join-btn {
        padding: 5px 26px;
        font-size: 11px
    }

    .banner-img .banner-img-shape {
        display: none
    }

    .banner-img-two {
        float: none;
        margin-right: auto;
        margin-left: auto
    }

    .banner-img-two img {
        max-width: 100%
    }

    .banner-img-two .bg-shape {
        position: absolute;
        z-index: -1;
        top: 25px;
        left: 0;
        right: 0;
        text-align: center
    }

    .banner-img-two .bg-shape img {
        max-width: 100%
    }

    .hero-slider-area {
        padding: 50px 0
    }

    .hero-slider-area .hero-shape {
        display: none
    }

    .hero-slider .owl-nav .owl-prev {
        position: inherit;
        top: 0;
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
        left: 0;
        opacity: 1;
        font-size: 16px !important;
        width: 30px;
        height: 30px;
        line-height: 30px !important
    }

    .hero-slider .owl-nav .owl-next {
        position: inherit;
        top: 0;
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
        right: 0;
        opacity: 1;
        font-size: 16px !important;
        width: 30px;
        height: 30px;
        line-height: 30px !important
    }

    .hero-content {
        margin-bottom: 30px
    }

    .hero-content .top-title {
        font-size: 14px;
        margin-bottom: 10px
    }

    .hero-content h1 {
        font-size: 30px;
        margin-bottom: 15px
    }

    .hero-content p {
        margin-bottom: 20px
    }

    .hero-content .banner-btn .default-btn {
        margin-right: 10px;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex
    }

    .hero-content .banner-btn .play-btn i {
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        padding-left: 3px
    }

    .hero-content .banner-btn .play-btn .title-text {
        margin-left: 10px
    }

    .hero-img {
        margin-bottom: 30px
    }

    .hero-img img {
        max-width: 94%
    }

    .hero-img .hero-bg-shape img {
        max-width: 100%
    }

    .hero-img .hero-img-content {
        padding: 8px
    }

    .hero-img .hero-img-content i {
        width: 30px;
        height: 30px;
        line-height: 35px;
        font-size: 14px;
        margin-right: 5px
    }

    .hero-img .hero-img-content .content h3 {
        font-size: 13px
    }

    .hero-img .hero-img-content .content p {
        font-size: 11px
    }

    .hero-img .top-content {
        left: 0
    }

    .hero-img .right-content {
        bottom: 140px
    }

    .inner-banner .inner-title {
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .inner-banner {
        height: 170px;
    }
    .inner-banner .inner-title h3 {
        font-size: 25px;
        margin-bottom: 10px
    }
    .scourse_meta .smeta {
        padding-right: 8px;
        margin-right: 8px;
    }

    .scourse_meta img {
        width: 40px;
        margin-right: 10px;
        height: 40px;
    }
    .scourse_meta p {
        font-size: 14px;
    }
    .scourse_meta span {
        font-size: 12px;
    }
    .inner-banner .inner-title ul li {
        font-size: 16px
    }

    .inner-banner .inner-title ul li::before {
        top: 3px
    }

    .categories-area {
        padding-bottom: 50px
    }

    .categories-area .owl-nav .owl-prev {
        position: inherit;
        top: 0;
        right: 0;
        font-size: 14px !important;
        text-align: center;
        width: 32px;
        height: 32px;
        line-height: 32px !important
    }

    .categories-area .owl-nav .owl-next {
        position: inherit;
        top: 0;
        right: 0;
        font-size: 14px !important;
        text-align: center;
        width: 32px;
        height: 32px;
        line-height: 32px !important
    }

    .categories-item .content {
        padding: 30px 20px
    }

    .categories-item .content a i {
        width: 50px;
        height: 50px;
        line-height: 55px;
        font-size: 18px;
        margin-right: 15px
    }

    .counter-max {
        padding: 50px 20px 20px
    }

    .counter-content {
        padding-left: 60px
    }

    .counter-content i {
        font-size: 38px
    }

    .counter-content i::after {
        width: 27px;
        height: 25px
    }

    .counter-content h3 {
        font-size: 24px
    }

    .counter-card {
        padding: 25px 20px 25px 80px
    }

    .counter-card i {
        top: 25px;
        left: 20px;
        font-size: 40px
    }

    .counter-card i::after {
        bottom: 0;
        right: -7px;
        width: 27px;
        height: 25px
    }

    .counter-card h3 {
        font-size: 24px
    }

    .featured-area-mt {
        padding-top: 50px;
        margin-top: 0
    }

    .featured-card {
        padding: 30px 20px
    }

    .featured-card a i {
        width: 50px;
        height: 50px;
        line-height: 55px;
        font-size: 18px
    }

    .featured-card a h3 {
        margin-bottom: 0;
        -webkit-transition: .9s;
        transition: .9s
    }

    .featured-item {
        padding: 25px 20px 25px 80px
    }

    .featured-item i {
        top: 25px;
        left: 20px;
        width: 45px;
        height: 45px;
        line-height: 50px;
        font-size: 18px
    }

    .featured-item-two {
        padding: 30px 20px
    }

    .featured-item-two a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .featured-item-two a i {
        padding-top: 5px;
        padding-left: 5px;
        font-size: 35px;
        margin-right: 15px
    }

    .featured-item-two a i::after {
        top: -5px;
        left: -5px;
        width: 30px;
        height: 30px
    }

    .courses-area-rs {
        padding-bottom: 50px
    }

    .courses-item .content {
        padding: 16px 16px;
        position: relative
    }

    .courses-item .content .course-instructors {
        right: 20px;
        top: -30px
    }

    .courses-item .content .course-instructors img {
        max-width: 60px
    }

    .courses-item .content .price-text {
        top: 25px;
        right: 20px;
        font-size: 18px
    }

    .courses-item .content h3 {
        margin-top: 15px;
        margin-bottom: 12px
    }

    .courses-item .content .course-list li {
        margin-right: 20px
    }

    .courses-item .content .course-list li:last-child {
        margin-right: 0
    }

    .courses-item .content .bottom-content .user-area h3 {
        font-size: 15px
    }

    .courses-item .content .bottom-content .rating i {
        font-size: 16px
    }

    .courses-item .content .bottom-content .rating2 {
        font-size: 14px
    }

    .courses-item .content .bottom-content .rating2 i {
        font-size: 14px
    }

    .courses-item .content .bottom-content .bottom-price {
        font-size: 17px
    }

    .courses-area .owl-nav .owl-prev {
        position: inherit;
        top: 0;
        right: 0;
        font-size: 14px !important;
        text-align: center;
        width: 32px;
        height: 32px;
        line-height: 32px !important
    }

    .courses-area .owl-nav .owl-next {
        position: inherit;
        top: 0;
        right: 0;
        font-size: 14px !important;
        text-align: center;
        width: 32px;
        height: 32px;
        line-height: 32px !important
    }

    .courses-details-tab .tabs li {
        margin-right: 3px;
        padding: 7px 8px;
        margin-bottom: 10px;
        font-size: 14px;
    }
    .courses-details-tab .tabs {
        margin: 0 0 5px;
    }
    .courses-details-tab .tabs li:last-child {
        margin-bottom: 0
    }
    .accordion-item:last-of-type {
        width: 100% !important;
    }
    .courses-details-tab-content h1 {
        font-size: 28px
    }

    .courses-details-tab-content h2 {
        font-size: 26px
    }

    .courses-details-tab-content h3 {
        font-size: 23px
    }

    .courses-details-tab-content h4 {
        font-size: 21px
    }

    .courses-details-tab-content h5 {
        font-size: 20px
    }

    .courses-details-tab-content .courses-details-accordion .accordion .accordion-content .accordion-content-list {
        display: block
    }

    .courses-details-tab-content .courses-details-accordion .accordion .accordion-content .accordion-content-list .accordion-content-left {
        margin-bottom: 10px
    }

    .courses-details-tab-content .courses-details-instructor .course-list li {
        margin-right: 12px
    }

    .courses-details-tab-content .courses-details-review-form .review-title .btn-right {
        position: inherit;
        right: 0;
        bottom: 0
    }

    .courses-details-tab-content .courses-details-review-form .review-comments {
        margin-top: 25px
    }

    .courses-details-tab-content .courses-details-review-form .review-comments .review-item {
        padding-right: 0
    }

    .courses-details-tab-content .courses-details-review-form .review-comments .review-item .content {
        padding: 0
    }

    .courses-details-tab-content .courses-details-review-form .review-comments .review-item .content img {
        position: inherit;
        margin-bottom: 15px
    }

    .courses-details-tab-content .courses-details-review-form .review-comments .review-item .review-report-link {
        margin-top: 8px;
        position: inherit;
        top: 0
    }

    .courses-details-sidebar {
        margin-top: 0
    }

    .product-topper .product-title {
        margin-bottom: 20px
    }

    .play-btn-area {
        padding: 100px 0
    }

    .play-btn-area .play-btn i {
        width: 60px;
        height: 60px;
        line-height: 62px;
        font-size: 20px
    }

    .play-btn-area-two {
        padding: 100px 0;
        margin-bottom: 30px
    }

    .play-btn-area-two .play-btn i {
        width: 60px;
        height: 60px;
        line-height: 62px;
        font-size: 20px
    }

    .register-form .top-header {
        padding: 30px 25px 27px;
        border-radius: 10px 10px 0 0
    }

    .register-form .top-header h3 {
        font-size: 26px
    }

    .register-form .register-form-max {
        padding: 30px 25px 27px
    }

    .register-form .form-group {
        margin-bottom: 15px
    }

    .register-form .form-group .form-control {
        height: 45px
    }

    .register-form .default-btn {
        border: 0;
        outline: none;
        width: 100%
    }

    .event-item {
        display: block
    }

    .event-item .event-img {
        margin-right: 0;
        max-width: 100%;
        margin-bottom: 15px
    }

    .event-sidebar {
        padding: 30px 15px
    }

    .event-popular-post .item .thumb {
        margin-right: 10px
    }

    .event-popular-post .item .info {
        padding: 10px 0
    }

    .event-popular-post .item .info .title-text {
        font-size: 18px
    }

    .enrolled-area {
        padding-top: 50px
    }

    .enrolled-content .enrolled-list li {
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 45px;
        font-size: 16px;
        margin-bottom: 15px
    }

    .enrolled-content .enrolled-list li i {
        width: 30px;
        height: 30px;
        line-height: 35px
    }

    .enrolled-img {
        margin-top: 0;
        margin-left: auto;
        margin-right: auto
    }

    .enrolled-img .bg-shape {
        top: 15%;
        left: 0;
        right: 0
    }

    .enrolled-img .enrolled-img-content {
        padding: 8px
    }

    .enrolled-img .enrolled-img-content i {
        width: 30px;
        height: 30px;
        line-height: 35px;
        font-size: 14px;
        margin-right: 5px
    }

    .enrolled-img .enrolled-img-content .content h3 {
        font-size: 13px
    }

    .enrolled-img .enrolled-img-content .content p {
        font-size: 11px
    }

    .enrolled-img .top-content {
        left: 0
    }

    .enrolled-img .left-content {
        left: 0
    }

    .enrolled-img .enrolled-img-shape {
        display: none
    }

    .enrolled-img-three img {
        max-width: 100%;
        border-radius: 10px
    }

    .testimonials-card {
        padding: 25px 20px
    }

    .testimonials-card .content {
        padding-left: 72px;
        margin-bottom: 20px
    }

    .testimonials-card .content img {
        width: 60px !important
    }

    .testimonials-card p {
        margin-bottom: 12px
    }

    .testimonials-card .quote {
        top: 30px;
        right: 20px;
        font-size: 30px
    }

    .testimonials-card-two {
        padding: 25px 20px
    }

    .testimonials-card-two .content {
        padding-left: 72px
    }

    .testimonials-card-two .content img {
        width: 60px !important
    }

    .testimonials-card-two .quote {
        bottom: 15px;
        right: 20px;
        font-size: 30px
    }

    .instructors-area .owl-nav .owl-prev {
        position: inherit;
        top: 0;
        right: 0;
        font-size: 14px !important;
        text-align: center;
        width: 32px;
        height: 32px;
        line-height: 32px !important
    }

    .instructors-area .owl-nav .owl-next {
        position: inherit;
        top: 0;
        right: 0;
        font-size: 14px !important;
        text-align: center;
        width: 32px;
        height: 32px;
        line-height: 32px !important
    }

    .instructors-area-rs {
        padding-bottom: 50px
    }

    .instructors-card .content {
        padding: 20px 18px
    }

    .instructors-card .content .instructors-social {
        right: 65px;
        bottom: 35px
    }

    .instructors-card .content .instructors-social li.share-btn i {
        width: 35px;
        height: 35px;
        line-height: 35px;
        margin: 0 8px;
        font-size: 18px
    }

    .instructors-card .content .instructors-social li a i {
        display: inline-block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 14px
    }

    .instructors-card .content .instructors-social:hover li:nth-child(2) {
        bottom: 45px
    }

    .instructors-card .content .instructors-social:hover li:nth-child(3) {
        bottom: 85px
    }

    .instructors-card .content .instructors-social:hover li:nth-child(4) {
        bottom: 125px
    }

    .instructors-card .content .instructors-social:hover li:nth-child(5) {
        bottom: 165px
    }

    .instructors-card .content h3 {
        margin-bottom: 5px
    }

    .instructors-card .content h3 a {
        color: var(--titleColor);
        -webkit-transition: var(--transition);
        transition: var(--transition)
    }

    .instructors-card .content span {
        color: var(--bodyColor)
    }



    .contact-info-card i {
        top: 20px;
        left: 15px;
        width: 45px;
        height: 45px;
        line-height: 45px;
        font-size: 18px
    }

    .contact-info-card h3 {
        margin-bottom: 5px;
        -webkit-transition: var(--transition);
        transition: var(--transition)
    }

    .contact-info-card p {
        font-size: 14px
    }

    .contact-form {
        padding: 30px 20px 27px
    }

    .contact-form .agree-label label a.forget {
        position: inherit
    }

    .blog-card .content {
        padding: 27px 20px 30px
    }

    .blog-details-content h1 {
        font-size: 28px
    }

    .blog-details-content h2 {
        font-size: 26px
    }

    .blog-details-content h3 {
        font-size: 23px
    }

    .blog-details-content h4 {
        font-size: 21px
    }

    .blog-details-content h5 {
        font-size: 20px
    }

    .blog-details-content .blog-preview-img-bg {
        padding: 70px 0
    }

    .blog-details-content .blog-preview-img-bg .play-btn i {
        width: 60px;
        height: 60px;
        line-height: 62px;
        font-size: 18px
    }

    .blog-details-content .blockquote {
        padding: 30px 20px 25px
    }

    .blog-details-content .blockquote i {
        position: inherit;
        top: 0;
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
        left: 0;
        line-height: 1;
        font-size: 35px
    }

    .blog-details-content .blockquote p {
        max-width: 100%;
        font-size: 16px
    }

    .blog-details-content .article-share {
        padding: 20px;
        margin-top: 0
    }

    .blog-details-content .article-share .article-tag {
        margin-bottom: 10px
    }

    .blog-details-content .article-share .article-social-icon {
        float: none
    }

    .blog-details-content .comments-form .contact-form {
        padding: 24px
    }

    .blog-details-content .comments-form .contact-form h4 {
        margin-bottom: 15px
    }

    .side-bar-widget {
        padding: 25px 15px
    }

    .cart-wraps-area .cart-table {
        text-align: center
    }

    .cart-wraps-area .cart-table table thead tr th {
        padding: 10px 40px;
        white-space: nowrap;
        font-size: 16px
    }

    .cart-wraps-area .cart-table table thead tr th:first-child {
        text-align: left;
        padding-left: 0
    }

    .cart-wraps-area .cart-table table thead tr td {
        white-space: nowrap
    }

    .cart-wraps-area .cart-table table thead tr td:first-child {
        text-align: left;
        padding-left: 0
    }

    .cart-wraps-area .cart-buttons {
        text-align: center
    }

    .cart-wraps-area .cart-buttons .continue-shopping-box {
        margin-bottom: 20px
    }

    .cart-wraps-area .cart-buttons .default-btn {
        display: block;
        text-align: center;
        width: 100%
    }

    .cart-totals {
        padding: 30px 20px;
        margin-left: 0
    }

    .faq-accordion .accordion .accordion-item {
        margin-bottom: 15px
    }

    .faq-accordion .accordion .accordion-title {
        padding: 5px 0 5px 45px
    }

    .faq-accordion .accordion .accordion-title i {
        width: 35px;
        height: 35px;
        line-height: 35px
    }

    .faq-accordion .accordion .accordion-content {
        margin-top: 5px
    }

    .pricing-card {
        padding: 30px 20px
    }

    .pricing-card .top-content {
        padding-bottom: 10px;
        margin-bottom: 20px
    }

    .pricing-card .top-content h3 {
        font-size: 24px;
        margin-bottom: 10px
    }

    .pricing-card .top-content h2 {
        font-size: 35px
    }

    .pricing-card .top-content h2 sub {
        font-size: 16px
    }

    .pricing-card ul {
        margin: 0 0 20px
    }

    .pricing-card ul li {
        margin-bottom: 7px
    }

    .pricing-card ul li i {
        margin-right: 7px;
        font-size: 16px
    }

    .newsletter-area .newsletter-form .form-control {
        height: 50px;
        line-height: 50px;
        padding: 0 15px
    }

    .newsletter-area .newsletter-form .subscribe-btn {
        margin-top: 15px;
        position: inherit;
        right: 0;
        top: 0
    }

    .coming-soon-area .coming-soon-content {
        padding-top: 30px;
        padding-bottom: 20px;
        padding-left: 15px;
        padding-right: 15px
    }

    .coming-soon-area .coming-soon-content h1 {
        font-size: 30px;
        line-height: 1.1;
        margin-top: 0
    }

    .coming-soon-area .coming-soon-content p {
        font-size: 14px;
        margin-top: 15px
    }

    .coming-soon-area .coming-soon-content #timer {
        margin-top: 30px
    }

    .coming-soon-area .coming-soon-content #timer div {
        font-size: 30px;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 15px
    }

    .coming-soon-area .coming-soon-content #timer div span {
        font-size: 14px;
        margin-top: -5px
    }

    .coming-soon-area .coming-soon-content #timer div::before {
        display: none
    }

    .coming-soon-area .coming-soon-content .newsletter-form {
        margin-top: 20px;
        margin-bottom: 20px
    }

    .coming-soon-area .coming-soon-content .newsletter-form .input-newsletter {
        height: 55px;
        padding-left: 13px;
        font-size: 15px
    }

    .coming-soon-area .coming-soon-content .newsletter-form .default-btn {
        position: relative;
        font-size: 14px;
        margin-top: 15px;
        width: 100%;
        height: 50px
    }

    .footer-contact-area .section-title {
        margin-bottom: 20px
    }

    .footer-widget .footer-logo {
        margin-bottom: 15px
    }

    .footer-widget h3 {
        font-size: 21px;
        margin-bottom: 20px
    }

    .footer-widget p {
        margin-bottom: 18px
    }

    .footer-widget .social-link li {
        margin-right: 5px
    }

    .footer-widget .social-link li.social-title {
        font-size: 16px
    }

    .footer-widget .footer-list li {
        margin-bottom: 10px
    }

    .footer-widget .footer-contact li {
        padding-left: 30px;
        margin-bottom: 10px
    }

    .footer-widget .footer-contact li i {
        font-size: 17px
    }
    .user-all-form.userlogin {
        padding: 0;
    }
    .video_home video {
        height: 740px;
    }
    .navbar-area.ledu-area.otherpages-menu .navbar-brand img {
        height: 60px !important;
    }
    .login__signup a {
        padding: 6px 15px;
        font-size: 14px;
        width: fit-content;
    }

    

    .banner-item.companybg.trainer-ban .banner-thumb img {
        height: 190px;
    }
    #responsive-navbar-nav {
        position: absolute;
        background: #ebebeb;
        width: 100%;
        top: 70px;
        height: 100vh;
    }
}

@media only screen and (max-width:576px) {

    .user-all-form.userlogin {
        padding: 0;
    }
    .video_home video {
        height: 740px;
    }
    .career-se .banner-item {
        padding: 20px 20px !important;
    }
    .career-se .banner-item .banner-content {
        margin: 0px !important;
    }
    .banner-item.trainer-ban {
        padding-bottom: 20px;
    }
    .career-se .banner-item.position-relative h3 br {
        display: none;
    }
    .leftPanel {
        width: 100%;
    }
    .rightPanel {
        width: 100%;
    }
    .rightPanel h4 {
        font-size: 18px;
    }
    .workExperience .btn {
        font-size: 12px;
        padding: 3px 7px;
        border-radius: 4px;
    }
    .jobPosition h3 {
        font-size: 16px;
    }
    .projectName.bolded h6 {
        font-size: 14px;
    }
    .leftPanel h2 {
        font-size: 16px;
    }
    .smallText .skill span, .smallText p {
        font-size: 14px !important;
    }

    .navbar-nav .dropdown-menu {
        position: absolute;
    }
  

    .leftPanel .details .btn {
        font-size: 12px;
        padding: 3px 7px;
        border-radius: 4px;
    }

    button.submit_btn {
        width: 100%;
    }
    .searchlocation {
        grid-template-columns: 1fr;
        padding: 10px 10px;
    }
    .iconfield .input-field input {
        padding: 0px;
    }
    .searchlocation .iconfield {
        grid-template-columns: 30px 1fr;
        border-right: none;
        padding: 0px 0 12px;
    }
    .searchlocation .iconfield:first-child {
        border-bottom: solid 1px #eee;
        margin: 0 0 14px;
    }
    #msform input, #msform select, #msform textarea {
        font-size: 14px;
    }
    .projectName span, .jobPosition span {
        font-size: 14px;
    }
    .workExperience p{
        font-size: 14px;
    }
    .courses-details-into table {
        width: 100% !important;
    }
}

@media only screen and (min-width:577px) and (max-width:767px) {
    .featured-card {
        padding: 20px 12px
    }

    .contact-form .agree-label label a.forget {
        position: absolute
    }
    .user-all-form.userlogin {
        padding: 0;
    }
    .video_home video {
        height: 740px;
    }
    .leftPanel {
        width: 100%;
    }
    .rightPanel {
        width: 100%;
    }
    .rightPanel h4 {
        font-size: 18px;
    }
    .workExperience .btn {
        font-size: 12px;
        padding: 3px 7px;
        border-radius: 4px;
    }
    .jobPosition h3 {
        font-size: 16px;
    }
    
    .projectName.bolded h6 {
        font-size: 14px;
    }
    .leftPanel h2 {
        font-size: 16px;
    }
    .smallText .skill span, .smallText p {
        font-size: 14px !important;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .container-fluid {
        width: 720px;
        margin-left: auto;
        margin-right: auto
    }
    #responsive-navbar-nav {
        position: absolute;
        background: #ebebeb;
        width: 100%;
        top: 70px;
        height: 100vh;
    }

    :root {
        --fontSize: 15px
    }

    body {
        font-size: var(--fontSize)
    }

    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px
    }

    .pt-100 {
        padding-top: 50px
    }

    .ptb-70 {
        padding-top: 50px;
        padding-bottom: 20px
    }

    .pb-100 {
        padding-bottom: 50px !important
    }

    .pt-70 {
        padding-top: 50px
    }

    .pb-70 {
        padding-bottom: 20px
    }

    .pt-45 {
        padding-top: 30px
    }

    .pb-10 {
        padding-bottom: 0
    }

    .pl-20 {
        padding-left: 0
    }

    .pr-20 {
        padding-right: 0
    }

    .mt-20 {
        margin-top: 0
    }

    .mb-45 {
        margin-bottom: 30px
    }

    .ml-20 {
        margin-left: 0
    }

    .mr-20 {
        margin-right: 0
    }

    .default-btn {
        padding: 10px 24px;
        font-size: 14px
    }

    .section-title span {
        font-size: 14px
    }

    .section-title h2 {
        font-size: 28px
    }

    .section-title p {
        padding-top: 10px
    }

    .ps-2 {
        padding-left: 0 !important
    }

    .ps-3 {
        padding-left: 0 !important
    }

    .ps-4 {
        padding-left: 0 !important
    }

    .ps-5 {
        padding-left: 0 !important
    }

    .ps-0 {
        padding-left: 15px !important
    }

    .pe-0 {
        padding-right: 15px !important
    }

    /* .p-0 {
        padding-left: 15px !important;
        padding-right: 15px !important
    } */

    h3 {
        font-size: 21px
    }

    .banner-area {
        padding-top: 50px;
        overflow: hidden
    }

    .banner-area-ptb {
        padding-top: 50px;
        padding-bottom: 0
    }

    .banner-shape {
        display: none
    }

    .banner-content {
        margin-bottom: 30px
    }

    .banner-content span {
        font-size: 14px;
        margin-bottom: 10px
    }

    .banner-content h1 {
        font-size: 30px;
        margin-bottom: 15px
    }

    .banner-content p {
        margin-bottom: 20px
    }

    .banner-content .banner-form-area {
        margin-bottom: 20px
    }

    .banner-content .banner-form-area .banner-form {
        width: 100%
    }

    .banner-content .banner-form-area .banner-form .form-control {
        height: 50px;
        line-height: 50px;
        padding: 0 15px;
        max-width: 100%;
        width: 100%
    }

    .banner-content .banner-form-area .banner-form .form-control:focus {
        outline: none;
        -webkit-box-shadow: none;
        box-shadow: none
    }

    .banner-content .banner-form-area .banner-form .default-btn {
        height: 50px
    }

    .banner-content .banner-counter h3 {
        font-size: 20px
    }

    .banner-img {
        margin-left: auto;
        margin-right: auto
    }

    .banner-img-two {
        float: none;
        margin-right: auto;
        margin-left: auto
    }

    .banner-img-two img {
        max-width: 100%
    }

    .banner-img-two .bg-shape {
        position: absolute;
        z-index: -1;
        top: 25px;
        left: 0;
        right: 0;
        text-align: center
    }

    .banner-img-two .bg-shape img {
        max-width: 100%
    }

    .hero-slider-area {
        padding: 50px 0
    }

    .hero-slider-area .hero-shape {
        display: none
    }

    .hero-slider .owl-nav .owl-prev {
        position: inherit;
        top: 0;
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
        left: 0;
        opacity: 1;
        font-size: 14px !important;
        width: 30px;
        height: 30px;
        line-height: 30px !important
    }

    .hero-slider .owl-nav .owl-prev i {
        top: 1px
    }

    .hero-slider .owl-nav .owl-next {
        position: inherit;
        top: 0;
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
        right: 0;
        opacity: 1;
        font-size: 14px !important;
        width: 30px;
        height: 30px;
        line-height: 30px !important
    }

    .hero-slider .owl-nav .owl-next i {
        top: 1px
    }

    .hero-content {
        margin-bottom: 30px
    }

    .hero-content .top-title {
        font-size: 14px;
        margin-bottom: 10px
    }

    .hero-content h1 {
        font-size: 30px;
        margin-bottom: 15px
    }

    .hero-content p {
        margin-bottom: 20px
    }

    .hero-content .banner-btn .default-btn {
        margin-right: 10px;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex
    }

    .hero-content .banner-btn .play-btn i {
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        padding-left: 3px
    }

    .hero-content .banner-btn .play-btn .title-text {
        margin-left: 10px
    }

    .hero-img {
        margin-bottom: 30px
    }

    .hero-img img {
        max-width: 94%
    }

    .hero-img .hero-bg-shape img {
        max-width: 100%
    }

    .hero-img .hero-img-content {
        padding: 8px
    }

    .hero-img .hero-img-content i {
        width: 30px;
        height: 30px;
        line-height: 35px;
        font-size: 14px;
        margin-right: 5px
    }

    .hero-img .hero-img-content .content h3 {
        font-size: 13px
    }

    .hero-img .hero-img-content .content p {
        font-size: 11px
    }

    .hero-img .top-content {
        left: 0
    }

    .hero-img .right-content {
        bottom: 140px
    }

    .inner-banner .inner-title {
        padding-top: 70px;
        padding-bottom: 70px
    }

    .inner-banner .inner-title h3 {
        font-size: 30px;
        margin-bottom: 10px
    }

    .inner-banner .inner-title ul li {
        font-size: 16px
    }

    .inner-banner .inner-title ul li::before {
        top: 3px
    }

    .categories-area {
        padding-bottom: 20px
    }

    .categories-area .owl-nav .owl-prev {
        top: -70px;
        right: 40px;
        font-size: 14px !important;
        text-align: center;
        width: 32px;
        height: 32px;
        line-height: 32px !important
    }

    .categories-area .owl-nav .owl-next {
        top: -70px;
        font-size: 14px !important;
        text-align: center;
        width: 32px;
        height: 32px;
        line-height: 32px !important
    }

    .categories-item .content {
        padding: 30px 20px
    }

    .categories-item .content a i {
        width: 50px;
        height: 50px;
        line-height: 55px;
        font-size: 18px;
        margin-right: 15px
    }

    .counter-max {
        padding: 50px 10px 20px 20px
    }

    .counter-content {
        padding-left: 50px
    }

    .counter-content i {
        font-size: 35px
    }

    .counter-content i::after {
        width: 27px;
        height: 25px;
        right: -5px
    }

    .counter-content h3 {
        font-size: 22px
    }

    .counter-content p {
        font-size: 12px
    }

    .counter-card {
        padding: 25px 20px 25px 80px
    }

    .counter-card i {
        top: 25px;
        left: 20px;
        font-size: 40px
    }

    .counter-card i::after {
        bottom: 0;
        right: -7px;
        width: 27px;
        height: 25px
    }

    .counter-card h3 {
        font-size: 24px
    }

    .featured-area-mt {
        margin-top: 0;
        padding-top: 50px
    }

    .featured-card {
        padding: 20px 10px
    }

    .featured-card a i {
        width: 45px;
        height: 45px;
        line-height: 50px;
        font-size: 18px;
        margin-right: 15px
    }

    .featured-card a h3 {
        margin-bottom: 0;
        -webkit-transition: .9s;
        transition: .9s;
        font-size: 18px
    }

    .featured-item {
        padding: 25px 20px 25px 80px
    }

    .featured-item i {
        top: 25px;
        left: 20px;
        width: 45px;
        height: 45px;
        line-height: 50px;
        font-size: 18px
    }

    .featured-item-two {
        padding: 30px 20px
    }

    .featured-item-two a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .featured-item-two a i {
        padding-top: 5px;
        padding-left: 5px;
        font-size: 35px;
        margin-right: 15px
    }

    .featured-item-two a i::after {
        top: -5px;
        left: -5px;
        width: 30px;
        height: 30px
    }

    .courses-area .owl-nav .owl-prev {
        top: -70px;
        right: 40px;
        font-size: 14px !important;
        text-align: center;
        width: 32px;
        height: 32px;
        line-height: 32px !important
    }

    .courses-area .owl-nav .owl-next {
        top: -70px;
        font-size: 14px !important;
        text-align: center;
        width: 32px;
        height: 32px;
        line-height: 32px !important
    }

    .courses-item .content {
        padding: 25px 20px;
        position: relative
    }

    .courses-item .content .course-instructors {
        right: 20px;
        top: -40px
    }

    .courses-item .content .price-text {
        top: 25px;
        right: 20px;
        font-size: 18px
    }

    .courses-item .content h3 {
        margin-top: 15px;
        margin-bottom: 12px;
        font-size: 20px
    }

    .courses-item .content .course-list li {
        margin-right: 20px
    }

    .courses-item .content .course-list li:last-child {
        margin-right: 0
    }

    .courses-item .content .bottom-content .user-area h3 {
        font-size: 15px
    }

    .courses-item .content .bottom-content .rating i {
        font-size: 16px
    }

    .courses-item .content .bottom-content .rating2 i {
        font-size: 16px
    }

    .courses-item .content .bottom-content .bottom-price {
        font-size: 18px
    }

    .courses-details-tab-content h1 {
        font-size: 28px
    }

    .courses-details-tab-content h2 {
        font-size: 26px
    }

    .courses-details-tab-content h3 {
        font-size: 23px
    }

    .courses-details-tab-content h4 {
        font-size: 21px
    }

    .courses-details-tab-content h5 {
        font-size: 20px
    }

    .courses-details-sidebar {
        margin-top: 0
    }

    .pricing-card {
        padding: 30px 20px
    }

    .pricing-card .top-content {
        padding-bottom: 10px;
        margin-bottom: 20px
    }

    .pricing-card .top-content h3 {
        font-size: 24px;
        margin-bottom: 10px
    }

    .pricing-card .top-content h2 {
        font-size: 35px
    }

    .pricing-card .top-content h2 sub {
        font-size: 16px
    }

    .pricing-card ul {
        margin: 0 0 20px
    }

    .pricing-card ul li {
        margin-bottom: 7px
    }

    .pricing-card ul li i {
        margin-right: 7px;
        font-size: 16px
    }

    .play-btn-area {
        padding: 100px 0
    }

    .play-btn-area .play-btn i {
        width: 60px;
        height: 60px;
        line-height: 62px;
        font-size: 20px
    }

    .play-btn-area-two {
        padding: 100px 0;
        margin-bottom: 30px
    }

    .play-btn-area-two .play-btn i {
        width: 60px;
        height: 60px;
        line-height: 62px;
        font-size: 20px
    }

    .register-form .top-header {
        padding: 30px 25px 27px;
        border-radius: 10px 10px 0 0
    }

    .register-form .top-header h3 {
        font-size: 26px
    }

    .register-form .register-form-max {
        padding: 30px 25px 27px
    }

    .register-form .form-group {
        margin-bottom: 15px
    }

    .register-form .form-group .form-control {
        height: 45px
    }

    .register-form .default-btn {
        border: 0;
        outline: none;
        width: 100%
    }

    .enrolled-area {
        padding-top: 50px
    }

    .enrolled-content .enrolled-list li {
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 45px;
        font-size: 16px;
        margin-bottom: 15px
    }

    .enrolled-content .enrolled-list li i {
        width: 30px;
        height: 30px;
        line-height: 35px
    }

    .enrolled-img {
        margin-top: 0;
        margin-left: auto;
        margin-right: auto
    }

    .enrolled-img .bg-shape {
        top: 15%;
        left: 0;
        right: 0
    }

    .enrolled-img .enrolled-img-content {
        padding: 10px
    }

    .enrolled-img .enrolled-img-content i {
        width: 40px;
        height: 40px;
        line-height: 45px;
        font-size: 18px;
        margin-right: 10px
    }

    .enrolled-img .enrolled-img-content .content h3 {
        font-size: 15px
    }

    .enrolled-img .enrolled-img-content .content p {
        font-size: 13px
    }

    .enrolled-img .top-content {
        left: 0
    }

    .enrolled-img .left-content {
        left: 0
    }

    .enrolled-img .enrolled-img-shape {
        display: none
    }

    .enrolled-img-two {
        text-align: center
    }

    .enrolled-img-three img {
        max-width: 100%
    }

    .testimonials-card {
        padding: 25px 20px
    }

    .testimonials-card .content {
        padding-left: 72px;
        margin-bottom: 20px
    }

    .testimonials-card .content img {
        width: 60px !important
    }

    .testimonials-card p {
        margin-bottom: 12px
    }

    .testimonials-card .quote {
        top: 30px;
        right: 20px;
        font-size: 30px
    }

    .testimonials-card-two {
        padding: 25px 20px
    }

    .testimonials-card-two .content {
        padding-left: 72px
    }

    .testimonials-card-two .content img {
        width: 60px !important
    }

    .testimonials-card-two .quote {
        bottom: 15px;
        right: 20px;
        font-size: 30px
    }

    .instructors-area .owl-nav .owl-prev {
        top: -70px;
        right: 40px;
        font-size: 14px !important;
        text-align: center;
        width: 32px;
        height: 32px;
        line-height: 32px !important
    }

    .instructors-area .owl-nav .owl-next {
        top: -70px;
        font-size: 14px !important;
        text-align: center;
        width: 32px;
        height: 32px;
        line-height: 32px !important
    }

    .instructors-card .content {
        padding: 20px 18px
    }

    .instructors-card .content .instructors-social {
        right: 65px;
        bottom: 35px
    }

    .instructors-card .content .instructors-social li.share-btn i {
        width: 35px;
        height: 35px;
        line-height: 35px;
        margin: 0 8px;
        font-size: 18px
    }

    .instructors-card .content .instructors-social li a i {
        display: inline-block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 14px
    }

    .instructors-card .content .instructors-social:hover li:nth-child(2) {
        bottom: 45px
    }

    .instructors-card .content .instructors-social:hover li:nth-child(3) {
        bottom: 85px
    }

    .instructors-card .content .instructors-social:hover li:nth-child(4) {
        bottom: 125px
    }

    .instructors-card .content .instructors-social:hover li:nth-child(5) {
        bottom: 165px
    }

    .instructors-card .content h3 {
        margin-bottom: 5px
    }

    .instructors-card .content h3 a {
        color: var(--titleColor);
        -webkit-transition: var(--transition);
        transition: var(--transition)
    }

    .instructors-card .content span {
        color: var(--bodyColor)
    }

    .blog-card .content {
        padding: 27px 20px 30px
    }

    .blog-details-content h1 {
        font-size: 28px
    }

    .blog-details-content h2 {
        font-size: 26px
    }

    .blog-details-content h3 {
        font-size: 23px
    }

    .blog-details-content h4 {
        font-size: 21px
    }

    .blog-details-content h5 {
        font-size: 20px
    }

    .blog-details-content .blog-preview-img-bg {
        padding: 100px 0
    }

    .blog-details-content .blog-preview-img-bg .play-btn i {
        width: 60px;
        height: 60px;
        line-height: 62px;
        font-size: 18px
    }

    .blog-details-content .blockquote {
        padding: 30px 20px 25px
    }

    .blog-details-content .blockquote i {
        line-height: 1;
        font-size: 35px
    }

    .blog-details-content .blockquote p {
        max-width: 100%;
        font-size: 16px
    }

    .newsletter-area .section-title {
        margin-bottom: 20px
    }

    .coming-soon-area .coming-soon-content {
        padding-top: 30px;
        padding-bottom: 50px;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 0;
        -webkit-animation: none;
        animation: none
    }

    .coming-soon-area .coming-soon-content h1 {
        font-size: 45px;
        line-height: 1.1;
        margin-top: 0
    }

    .coming-soon-area .coming-soon-content p {
        font-size: 16px;
        margin-top: 15px
    }

    .footer-widget .footer-logo {
        margin-bottom: 15px
    }

    .footer-widget h3 {
        font-size: 21px;
        margin-bottom: 20px
    }

    .footer-widget p {
        margin-bottom: 18px
    }

    .footer-widget .social-link li {
        margin-right: 5px
    }

    .footer-widget .social-link li.social-title {
        font-size: 16px
    }

    .footer-widget .footer-list li {
        margin-bottom: 10px
    }

    .footer-widget .footer-contact li {
        padding-left: 30px;
        margin-bottom: 10px
    }

    .footer-widget .footer-contact li i {
        font-size: 17px
    }


    .banner-thumb img {
        width: 170px;
        height: auto;
    }
    .banner-item.trainer-ban {
        padding-bottom: 70px;
    }
    .banner-item {
        padding: 25px 20px;
    }
    .banner-item.trainer-ban .banner-thumb {
        top: 59%;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .banner-thumb img {
        height: 140px;
    }
    .banner-item.trainer-ban .banner-thumb {
        top: 72%;
    }
    .section-title h2 {
        font-size: 30px;
    }

    input.search-field:focus ~ .search-popup {
        width: 250px;
      }

      input.search-field:focus {
        outline: none;
        width: 250px;
        color: #212121;
      }
    .desktop-nav .navbar .navbar-nav {
        margin-left: auto;
        margin: 0 !important;
    }
    .desktop-nav .navbar .navbar-nav .nav-item a {
        margin-right: 10px;
        margin-left: 10px;
        font-size: 15px
    }

    .desktop-nav .navbar .navbar-nav .nav-item .dropdown-toggle {
        padding-right: 15px
    }

    .others-options .optional-item .default-btn {
        padding: 10px 22px
    }

    .navbar-category {
        display: none
    }

    .nav-widget-form {
        margin-left: 20px
    }

    .nav-widget-form .search-form {
        width: 175px
    }

    .nav-widget-form .search-form .form-control {
        padding: 0 15px;
        font-size: 15px
    }

    .nav-widget-form .search-form button {
        padding: 0 10px
    }

    .banner-content h1 {
        font-size: 40px
    }

    .banner-content .banner-form-area .banner-form {
        width: 496px
    }

    .banner-img {
        margin-left: 50px
    }

    .banner-img .bg-shape {
        top: 0
    }

    .banner-img .bg-shape img {
        max-width: 100%
    }

    .banner-img .top-content {
        padding: 10px
    }

    .banner-img .top-content i {
        width: 35px;
        height: 35px;
        line-height: 40px;
        font-size: 16px;
        margin-right: 10px
    }

    .banner-img .top-content .content h3 {
        font-size: 15px
    }

    .banner-img .top-content .content p {
        font-size: 13px
    }

    .banner-img .right-content {
        padding: 10px
    }

    .banner-img .right-content i {
        width: 35px;
        height: 35px;
        line-height: 40px;
        font-size: 16px;
        margin-right: 10px
    }

    .banner-img .right-content .content h3 {
        font-size: 15px
    }

    .banner-img .right-content .content p {
        font-size: 13px
    }

    .banner-img .left-content {
        padding: 10px 10px 10px 55px
    }

    .banner-img .left-content img {
        top: 10px;
        left: 10px;
        max-width: 42px
    }

    .banner-img .left-content .content {
        margin-bottom: 5px
    }

    .banner-img .left-content .content h3 {
        font-size: 16px
    }

    .banner-img .left-content .content p {
        font-size: 13px
    }

    .banner-img .left-content .join-btn {
        padding: 5px 26px;
        font-size: 12px
    }

    .banner-img .banner-img-shape .shape1 {
        top: 0
    }

    .banner-img .banner-img-shape .shape1 img {
        max-width: 265px
    }

    .banner-img-two {
        margin-right: 0
    }

    .banner-img-two img {
        max-width: 490px
    }

    .banner-img-two .bg-shape img {
        max-width: 100%
    }

    .hero-content h1 {
        font-size: 50px
    }

    .hero-img img {
        max-width: 94%
    }

    .hero-img .hero-bg-shape img {
        max-width: 100%
    }

    .counter-max {
        padding: 100px 20px
    }

    .counter-content h3 {
        font-size: 26px
    }

    .counter-content p {
        font-size: 14px
    }

    .counter-card {
        padding: 25px 20px 25px 72px
    }

    .counter-card i {
        top: 25px;
        left: 20px;
        font-size: 40px
    }

    .counter-card i::after {
        bottom: 0;
        right: -7px;
        width: 27px;
        height: 25px
    }

    .counter-card h3 {
        font-size: 28px
    }

    .counter-card p {
        font-size: 14px
    }

    .contact-info-card {
        padding: 30px 20px
    }

    .contact-info-card i {
        top: 0;
        left: 0;
        position: inherit;
        margin-bottom: 10px
    }

    .featured-card {
        padding: 20px 10px
    }

    .featured-card a i {
        width: 45px;
        height: 45px;
        line-height: 50px;
        font-size: 18px;
        margin-right: 15px
    }

    .featured-card a h3 {
        margin-bottom: 0;
        -webkit-transition: .9s;
        transition: .9s;
        font-size: 18px
    }

    .featured-item-two {
        padding: 25px 15px
    }

    .featured-item-two a i {
        padding-top: 5px;
        padding-left: 5px;
        font-size: 32px;
        margin-right: 10px
    }

    .featured-item-two a i::after {
        top: -5px;
        left: -5px;
        width: 30px;
        height: 30px
    }

    .featured-item-two a h3 {
        font-size: 18px
    }

    .event-sidebar {
        padding: 30px 15px
    }

    .event-popular-post .item .thumb {
        margin-right: 10px
    }

    .event-popular-post .item .thumb .full-image {
        width: 70px
    }

    .event-popular-post .item .info {
        padding: 0
    }

    .event-popular-post .item .info .title-text {
        font-size: 17px
    }

    .event-popular-post .item .info p {
        font-size: 13px
    }

    .courses-item .content {
        padding: 30px 20px
    }

    .enrolled-content .enrolled-list li {
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 45px;
        font-size: 16px;
        margin-bottom: 15px
    }

    .enrolled-content .enrolled-list li i {
        width: 30px;
        height: 30px;
        line-height: 35px
    }

    .enrolled-img-three img {
        max-width: 100%
    }

    .register-form .top-header {
        padding: 40px 27px 37px
    }

    .instructors-item-bg .instructors-img .instructors-social li {
        margin-bottom: 3px
    }

    .instructors-item-bg .instructors-img .instructors-social li a i {
        width: 25px;
        height: 25px;
        line-height: 25px;
        font-size: 12px
    }

    .instructors-item-bg .content {
        padding: 20px 10px
    }

    .instructors-item-bg .content h3 {
        font-size: 18px
    }

    .instructors-item-bg .content span {
        font-size: 15px
    }

    .blog-card .content {
        padding: 27px 17px 30px
    }

    .blog-details-content .blockquote {
        padding: 40px 30px 35px 80px
    }

    .blog-details-content .blockquote i {
        line-height: 1;
        font-size: 35px
    }

    .blog-details-content .blockquote p {
        font-size: 17px
    }

    .pricing-card {
        padding: 40px 30px
    }

    .footer-widget .social-link li {
        margin-right: 7px
    }
}

@media only screen and (min-width:1200px) and (max-width:1299px) {
    .section-title h2 {
        font-size: 30px;
    }
    .desktop-nav .navbar .navbar-nav {
        margin-right: 30px
    }

    .desktop-nav .navbar .navbar-nav .nav-item a {
        margin-right: 12px;
        margin-left: 12px
    }

    .desktop-nav .navbar .navbar-nav .nav-item .dropdown-toggle {
        padding-right: 15px
    }

    .nav-widget-form .search-form {
        width: 220px
    }

    .nav-widget-form .search-form .form-control {
        padding: 0 15px;
        font-size: 15px
    }

    .nav-widget-form .search-form button {
        padding: 0 10px
    }

    .banner-content h1 {
        font-size: 52px
    }

    .banner-img {
        margin-left: 95px
    }

    .banner-img .bg-shape img {
        max-width: 410px
    }

    .banner-img-two {
        margin-right: 0
    }

    .banner-img-two img {
        max-width: 520px
    }

    .banner-img-two .bg-shape img {
        max-width: 100%
    }

    .hero-img img {
        max-width: 94%
    }

    .hero-img .hero-bg-shape {
        left: -1px
    }

    .hero-img .hero-bg-shape img {
        max-width: 100%
    }

    .event-sidebar {
        padding: 30px 15px
    }

    .event-popular-post .item .thumb {
        margin-right: 12px
    }

    .event-popular-post .item .thumb .full-image {
        width: 70px
    }

    .featured-card {
        padding: 20px 10px
    }

    .featured-item {
        padding: 30px 30px 30px 100px
    }

    .featured-item i {
        top: 30px;
        left: 30px
    }

    .counter-max {
        padding: 100px 20px 100px 30px
    }

    .counter-card {
        padding: 25px 20px 25px 72px
    }

    .counter-card i {
        top: 25px;
        left: 20px;
        font-size: 40px
    }

    .counter-card i::after {
        bottom: 0;
        right: -7px;
        width: 27px;
        height: 25px
    }

    .counter-card h3 {
        font-size: 28px
    }

    .counter-card p {
        font-size: 14px
    }

    .courses-item .content {
        padding: 20px 20px
    }

    .courses-item .content h3 {
        font-size: 21px
    }

    .enrolled-content .enrolled-list li {
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 45px;
        font-size: 16px;
        margin-bottom: 15px
    }

    .enrolled-content .enrolled-list li i {
        width: 30px;
        height: 30px;
        line-height: 35px
    }

    .enrolled-img-three img {
        max-width: 100%;
        border-radius: 10px
    }

    .instructors-card .content .instructors-social {
        right: 70px
    }

    .testimonials-card-two {
        padding: 25px 20px
    }

    .testimonials-card-two .content {
        padding-left: 72px
    }

    .testimonials-card-two .content img {
        width: 60px !important
    }

    .testimonials-card-two .quote {
        bottom: 15px;
        right: 20px;
        font-size: 30px
    }

    .event-item {
        padding: 25px 15px
    }

    .blog-card .content {
        padding: 27px 20px 30px
    }

    .blog-card .content h3 {
        font-size: 21px
    }
}

/* @media only screen and (min-width:1300px) {
    .container {
        max-width: 1170px !important
    }
} */

@media only screen and (min-width:1300px) and (max-width:1350px) {
    .container-fluid {
        max-width: 1320px !important;
        margin-left: auto !important;
        margin-right: auto !important
    }

    .banner-content h1 {
        font-size: 58px
    }
}

@media only screen and (min-width:1350px) and (max-width:1469px) {
    .container-fluid {
        max-width: 1200px !important;
        margin-left: auto !important;
        margin-right: auto !important
    }
    input.search-field:focus {
        width: 280px;
      }
      input.search-field:focus ~ .search-popup {
        width: 280px;
      }
}

@media only screen and (min-width:1470px) and (max-width:1799px) {
    .container-fluid {
        max-width: 1460px !important;
        margin-left: auto !important;
        margin-right: auto !important
    }
}

@media only screen and (min-width:1800px) {
    .container-fluid {
        max-width: 1640px !important;
        margin-left: auto !important;
        margin-right: auto !important
    }

    .nav-widget-form {
        margin-left: 40px
    }

    .navbar-category {
        margin-left: 30px
    }

    .banner-content h1 {
        font-size: 70px
    }

    .banner-img-two img {
        max-width: 100%
    }

    .banner-img-two .bg-shape img {
        max-width: 100%
    }

    .hero-slider {
        position: relative;
        overflow: hidden
    }

    .hero-slider .owl-nav {
        margin-top: 0;
        overflow: hidden
    }

    .hero-slider .owl-nav .owl-prev {
        left: 40px;
        opacity: 1
    }

    .hero-slider .owl-nav .owl-next {
        right: 40px;
        opacity: 1
    }

    .hero-slider:hover .owl-nav .owl-prev {
        left: 60px
    }

    .hero-slider:hover .owl-nav .owl-next {
        right: 60px
    }

    .hero-img img {
        max-width: 664px
    }

    .hero-img .hero-bg-shape img {
        max-width: 702px
    }

    .courses-area-two .owl-nav .owl-prev {
        left: -100px;
        opacity: 1
    }

    .courses-area-two .owl-nav .owl-next {
        right: -100px;
        opacity: 1
    }

    .courses-area-two:hover .owl-nav .owl-prev {
        left: -90px
    }

    .courses-area-two:hover .owl-nav .owl-next {
        right: -90px
    }

    .testimonials-area .owl-nav .owl-prev {
        left: -100px;
        opacity: 1
    }

    .testimonials-area .owl-nav .owl-next {
        right: -100px;
        opacity: 1
    }

    .testimonials-area:hover .owl-nav .owl-prev {
        left: -80px
    }

    .testimonials-area:hover .owl-nav .owl-next {
        right: -80px;
        opacity: 1
    }
}

@media only screen and (max-width:991px) {
    .navbar-area {
        padding: 12px
    }

    .ledu-area {
        background-color: var(--whiteColor)
    }

    .mobile-responsive-nav {
        display: block
    }

    .mobile-responsive-nav .mobile-responsive-menu {
        position: relative
    }

    .mobile-responsive-nav .mobile-responsive-menu.mean-container .mean-nav {
        margin-top: 65px;
        background-color: #fff
    }
 
    a.meanmenu-reveal.meanclose {
        top: 17px !important;
    }
    .mobile-responsive-nav .mobile-responsive-menu.mean-container .mean-nav ul {
        font-size: 14px;
        background: #fff;
        border: none !important
    }

    .mobile-responsive-nav .mobile-responsive-menu.mean-container .mean-nav ul li a {
        color: #000;
        border-top-color: #dbeefd;
        text-transform: capitalize
    }

    .mobile-responsive-nav .mobile-responsive-menu.mean-container .mean-nav ul li a.dropdown-toggle::after {
        display: none
    }

    .mobile-responsive-nav .mobile-responsive-menu.mean-container .mean-nav ul li a i {
        display: none
    }

    .mobile-responsive-nav .mobile-responsive-menu.mean-container .mean-nav ul li a.mean-expand {
        width: 50%;
        height: 28px;
        text-align: right;
        padding: 11px !important;
        background: 0 0 !important;
        border-left: none !important;
        border-bottom: none !important
    }

    .mobile-responsive-nav .mobile-responsive-menu.mean-container .mean-nav ul li a.active {
        color: var(--mainColor)
    }

    .mobile-responsive-nav .mobile-responsive-menu.mean-container .mean-nav ul li li a {
        font-size: 15px
    }

    .mobile-responsive-nav .mobile-responsive-menu.mean-container .navbar-nav {
        height: 100vh !important;
        overflow-y: scroll;
        -webkit-box-shadow: 0 7px 13px 0 rgba(0, 0, 0, .1);
        box-shadow: 0 7px 13px 0 rgba(0, 0, 0, .1);
    }

    .mobile-responsive-nav .mobile-responsive-menu.mean-container .navbar-nav::-webkit-scrollbar {
        width: 7px
    }

    .mobile-responsive-nav .mobile-responsive-menu.mean-container .navbar-nav::-webkit-scrollbar-track {
        background: #f1f1f1
    }

    .mobile-responsive-nav .mobile-responsive-menu.mean-container .navbar-nav::-webkit-scrollbar-thumb {
        background: #888
    }

    .mobile-responsive-nav .mobile-responsive-menu.mean-container .navbar-nav::-webkit-scrollbar-thumb:hover {
        background: #555
    }

    .mobile-responsive-nav .mean-container a.meanmenu-reveal {
        top: 7px;
        padding: 0;
        width: 26px;
        height: 30px;
        padding-top: 6px;
        color: var(--mainColor);
    }

    .mobile-responsive-nav .mean-container a.meanmenu-reveal span {
        background: var(--mainColor);
        height: 4px;
        margin-top: 2px;
        border-radius: 0;
        position: relative;
        top: 8px
    }

    .mobile-responsive-nav .mean-container .mean-bar {
        position: absolute;
        top: -10px;
        z-index: 999;
        padding: 0;
        background-color: var(--whiteColor)
    }

    .mobile-responsive-nav .others-options {
        display: none !important
    }

    .mobile-responsive-nav .logo {
        position: relative;
        width: 120px;
        z-index: 999
    }

    .mobile-responsive-nav .logo .logo-two {
        display: none
    }

    .side-nav-responsive {
        display: block
    }

    .desktop-nav {
        display: none
    }

    .event-details-content h1 {
        font-size: 28px
    }

    .event-details-content h2 {
        font-size: 26px
    }

    .event-details-content h3 {
        font-size: 23px
    }

    .event-details-content h4 {
        font-size: 21px
    }

    .event-details-content h5 {
        font-size: 20px
    }

    .single-content h1 {
        font-size: 28px
    }

    .single-content h2 {
        font-size: 26px
    }

    .single-content h3 {
        font-size: 23px
    }

    .single-content h4 {
        font-size: 21px
    }

    .single-content h5 {
        font-size: 20px
    }

    .error-area .error-content h1 {
        font-size: 110px
    }

    .error-area .error-content h3 {
        margin-top: 20px;
        font-size: 26px
    }

    .error-area .error-content p {
        font-size: 15px;
        margin-top: 10px;
        padding: 0 15px
    }

    .go-top {
        font-size: 16px;
        width: 35px;
        height: 35px;
        line-height: 33px
    }
}